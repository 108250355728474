import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  
import {ExcelRenderer} from 'react-excel-renderer';

class ProductsBulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: [],
            pincodes: [],
            rows: [],
            cols: [],
            categoryName: 'Select',
            customerCount: ''
        };
    }

    componentDidMount() {

      let customerCount = 0
      let comp = this

      firebase.database().ref().child("CustomerId").once("value", function(snapshot){
        customerCount = snapshot.val()

        comp.setState({
          customerCount: customerCount
        })
      })
    }

    imageUpload = event =>{

        let fileObj = event.target.files[0];
      
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else{
          this.setState({
            cols: resp.cols,
            rows: resp.rows
          });
        }
      })       
      }

      submit(e){
        e.preventDefault();
        let data = this.state

        let categoryPushId = []
        let categoryName = []

        let sizePushId = []
        let sizeName = []
      
        var myTab = document.getElementById('excelTable');
      
        if(myTab.rows.length === 0){
          alert("Upload Excel Sheet");
          return;
        }

          firebase.database().ref().child("Masters").child("Category")
          .once('value',(snapshot) => {
            if(snapshot.exists()) {
              const newData = []
              snapshot.forEach(function(doc) {
                categoryName.push(doc.val().CategoryName)
                categoryPushId.push(doc.val().PushId)
              })
            }
        

            firebase.database().ref().child("Masters").child("Size")
            .once('value',(snapshot) => {
              if(snapshot.exists()) {
                const newData = []
                snapshot.forEach(function(doc) {
                  sizeName.push(doc.val().SizeName)
                  sizePushId.push(doc.val().PushId)
                })
              }

        // setTimeout(function() {


        let trigger = 0

        let previousProductName = ""
        let previousPushId = ""
      
        for (let i = 0; i < myTab.rows.length; i++) {
          var objCells = myTab.rows.item(i).cells;

          console.log("Previus Product Name", previousProductName)
          console.log("Previus Product PushId", previousPushId)


            if(categoryName.indexOf(objCells.item(2).innerHTML) === -1) {
                alert("Group Name is Invalid. Please Enter Correct Group With Case Matching: " + objCells.item(2).innerHTML + " Product Id: " + objCells.item(0).innerHTML)
                trigger = 1
                break;
            }

            if(sizeName.indexOf(objCells.item(3).innerHTML) === -1) {
                alert("Size Name is Invalid. Please Enter Correct Size With Case Matching: " + objCells.item(3).innerHTML + " Product Id: " + objCells.item(0).innerHTML)
                trigger = 1
                break;
            }

            if(previousProductName == objCells.item(1).innerHTML) {
                var firebaseref = firebase.database().ref().child("Products").child(previousPushId)
                firebaseref.child("CustomerProductId").set(objCells.item(0).innerHTML)
                firebaseref.child("Name").set(objCells.item(1).innerHTML)
                firebaseref.child("CategoryPushId").set(categoryPushId[categoryName.indexOf(objCells.item(2).innerHTML)])
                firebaseref.child("Category").set(objCells.item(2).innerHTML)
                firebaseref.child("Image").set("")
                firebaseref.child("Status").set("Active")
                    
                var firebaseref1 = firebaseref.child("Sizes").push();
                firebaseref1.child("PushId").set(firebaseref1.getKey());
                firebaseref1.child("SizePushId").set(sizePushId[sizeName.indexOf(objCells.item(3).innerHTML)])
                firebaseref1.child("Size").set(objCells.item(3).innerHTML)
                firebaseref1.child("MRPPrice").set(objCells.item(4).innerHTML);
                firebaseref1.child("CODPrice").set(objCells.item(5).innerHTML);
                firebaseref1.child("CreditPrice").set(objCells.item(6).innerHTML);
                firebaseref1.child("Stock").set(parseInt(objCells.item(7).innerHTML));
                firebaseref1.child("Status").set("Active");

            } else {
                var firebaseref = firebase.database().ref().child("Products").push()
                firebaseref.child("CustomerProductId").set(objCells.item(0).innerHTML)
                firebaseref.child("Name").set(objCells.item(1).innerHTML)
                firebaseref.child("CategoryPushId").set(categoryPushId[categoryName.indexOf(objCells.item(2).innerHTML)])
                firebaseref.child("Category").set(objCells.item(2).innerHTML)
                firebaseref.child("Image").set("")
                firebaseref.child("PushId").set(firebaseref.getKey())
                firebaseref.child("Status").set("Active")
                    
                var firebaseref1 = firebaseref.child("Sizes").push();
                firebaseref1.child("PushId").set(firebaseref1.getKey());
                firebaseref1.child("SizePushId").set(sizePushId[sizeName.indexOf(objCells.item(3).innerHTML)])
                firebaseref1.child("Size").set(objCells.item(3).innerHTML)
                firebaseref1.child("MRPPrice").set(objCells.item(4).innerHTML);
                firebaseref1.child("CODPrice").set(objCells.item(5).innerHTML);
                firebaseref1.child("CreditPrice").set(objCells.item(6).innerHTML);
                firebaseref1.child("Stock").set(parseInt(objCells.item(7).innerHTML));
                firebaseref1.child("Status").set("Active");

                previousProductName = objCells.item(1).innerHTML
                previousPushId = firebaseref.getKey()
            }
        }

        if(trigger === 0) {
          alert('Products Data Added Sucessfully!');
        }
      
        data.rows = []
        data.cols = []
        document.getElementById("productImage").value = ""
    })
})
}

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Masters" breadcrumbItem="Inventory Bulk Upload" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                         <Form id = "create-form1">
                                             <Row>
                                                    <Col md = {{size: 4}}>
                                               {/* <h4>Inventoy Bulk Uploads</h4> <br /> */}

                                                      <ReactHTMLTableToExcel  
                                                          className="btn btn-primary"  
                                                          table="dataTable"  
                                                          filename= "InventoryReport"
                                                          sheet="Sheet"  
                                                          buttonText="Download Inventory Report Format" />  
                                                          </Col>
                                                          </Row>
                                                      </Form>

                                                    <Table responsive hover id = "dataTable" style = {{display: "none"}}>
                                                        <thead>
                                                        <tr>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Group</th>
                                                            <th>Size</th>
                                                            <th>MRP Price</th>
                                                            <th>COD Price</th>
                                                            <th>Credit Price</th>
                                                            <th>Stock</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </Table>
                                                    <br/>

                                                        <Form>
                                                            <FormGroup row>
                                                            <Col sm={6}>
                                                            <FormGroup>
                                                                <Label for="exampleAddressGrid">Upload Sheet
                                                                <span style = {{color: "Red"}}>*</span>
                                                                </Label>
                                                                <Input
                                                                type="file"
                                                                name="productImage"
                                                                id="productImage"
                                                                onChange = {this.imageUpload}
                                                                />
                                                            </FormGroup>
                                                            </Col>

                                                            <Table responsive hover style = {{display: "none"}}>
                                                        <thead>
                                                        <tr>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Group</th>
                                                            <th>Size</th>
                                                            <th>MRP Price</th>
                                                            <th>COD Price</th>
                                                            <th>Credit Price</th>
                                                            <th>Stock</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id = "excelTable">
                                                        {this.state.rows.slice(1, this.state.rows.length).map((rowData, index) => (
                                                        <tr key = {index}>
                                                            <td>{rowData[0]}</td>
                                                            <td>{rowData[1]}</td>
                                                            <td>{rowData[2]}</td>
                                                            <td>{rowData[3]}</td>
                                                            <td>{rowData[4]}</td>
                                                            <td>{rowData[5]}</td>
                                                            <td>{rowData[6]}</td>
                                                            <td>{rowData[7]}</td>
                                                        </tr>
                                                        ))}
                                                        </tbody>
                                                    </Table>
                                                            </FormGroup>
                                        
                                                        <Button type = "submit" color="primary" onClick={this.submit.bind(this)}>
                                                        Upload
                                                        </Button>
                                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProductsBulkUpload;
