import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Label ,Container, Input, Form, Table, NavLink, Button, Modal, ModalHeader, ModalBody,
NavItem, TabContent, TabPane, CardText} from "reactstrap";

import Nav from "reactstrap/lib/Nav";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import classnames from "classnames";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      startDate: '',
      endDate: '',
      customerId: [],
      customerIds: [],
      customerNames: [],
      selectedMulti: [],
      custs: [],


      deliveryDate: '',
      products: [],
      productPushId: 'Select',
      sizes: [],
      sizePushId: 'Select',
      codPrice: '',
      creditPrice: '',
      mrpPrice: '',
      stock: '',
      quantity: '1',
      cart: [{}],

      custAddress: '',
      custName: '',
      custNumber: '',
      deliveryCharges: 0,
      discount: 0,
      flat: '',
      pincode: '',
      coord: '',
      grandTotal: 0,
      subtotal: 0,


      pendingOrders: [],
      acceptedOrders: [],
      dispatchedOrders: [],
      deliveredOrders: [],
      activeTabJustify: "1",
      itemOpen: false,
      cart1: [],
      deliveryPartners: [],
      deliveryNumber: '',
      deliveryName: '',
      topIndex: '-1',



      date: '',
      chequeNo: '',
      totalAmount: '0'
    };
  }

  componentDidMount() {
    let comp = this

    firebase.database().ref().child("Retailer")
    // .orderByChild("CustomerType").equalTo("Creditor")
    .once('value',(snapshot) => {
        if(snapshot.exists()) {
          const customerIds = []
          const customerNames = []
          let newData = []
          snapshot.forEach(function(doc) {
              customerIds.push(doc.val().UserId)
              customerNames.push(doc.val().RetailerName)
              const dict = {
                options: [ {
                label: doc.val().RetailerName,
                value: doc.val().UserId
                }]
            }
            newData.push(dict)
          })
          comp.setState({
            customerNames: customerNames,
            customerIds: customerIds,
            custs: newData
          })
        }
    })
  }

  handleMulti = customerId => {
    this.setState({ customerId });

    let data = this.state
    let comp = this

    console.log(customerId.value)

firebase.database().ref().child("Retailer").child(customerId.value)
.once("value", function(snapshot) {
    let address = ""
    let coord = ""
    let flat = ""
    comp.setState({
      custName: snapshot.val().RetailerName,
      custNumber: snapshot.val().MobileNumber,
      pincode: snapshot.val().Area,
      creditDays: snapshot.val().CreditDays,
      outstandingBalance: snapshot.val().OutStanding,
      dueDate: snapshot.val().CreditDays
    })

    snapshot.child("Address").forEach(function(snap) {
        address = snap.val().Address
        coord = snap.val().Coord
        flat = snap.val().Flat
    })

    comp.setState({
        custAddress: address,
        coord: coord,
        flat: flat
    })
});

    // firebase.database().ref().child("Purchase")
    // .orderByChild("UserId").equalTo(customerId.value)
    // .on('value', function(snapshot) {
    //     if(snapshot.exists()) {
    //       const newData = []
    //       snapshot.forEach(function(doc) {
    //         if(doc.val().PaymentStatus === "Pending") {
    //           newData.push(doc.val())
    //         }
    //       })
    //       comp.setState({
    //         firebaseData: newData
    //       })
    //     } else {
    //       comp.setState({
    //         firebaseData: []
    //       })
    //     }
    // })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }


  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUprCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    var createdUser = localStorage.getItem("name")
    if(createdUser == null) {
        createdUser = sessionStorage.getItem("name")
    }

    if(data.customerId.length === 0) {
        alert("Select Retailer");
        document.getElementById("customerId").focus();
        return;
    }

    // if(data.custName === '') {
    //     alert("Click on the Search Icon to fetch user details")
    //     return
    // }

    if(data.date === '') {
      alert("Select Date");
      document.getElementById("date").focus();
      return;
  }

    if(data.chequeNo === '') {
      alert("Enter Cheque Number / Voucher Numbe");
      document.getElementById("chequeNo").focus();
      return
    }


    if(data.totalAmount === '0' || data.totalAmount === '') {
      alert("Enter Amount");
      document.getElementById("totalAmount").focus();
      return
    }

    // let pushId = []


    // var myTab = document.getElementById("dataTable");
    // var checkBoxes = myTab.getElementsByTagName("input");
    // for(let i=0; i< checkBoxes.length; i++) {
    //   if (checkBoxes[i].checked) {
    //     var row = checkBoxes[i].parentNode.parentNode;
    //     pushId.push(row.cells[4].innerHTML)
    //   }
    // }

    // if(pushId.length === 0) {
    //       alert("Select Payments from the table to process the transactions");
    //       return;
    // }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = yyyy + '-' + mm + '-' + dd;

    let balance = Number(data.outstandingBalance) + Number(data.totalAmount)

    var firebaseref1 = firebase.database().ref().child("Receipts").push();
    firebaseref1.child("RetailerName").set(data.customerId.label)
    firebaseref1.child("RetailerUserId").set(data.customerId.value)
    firebaseref1.child("Date").set(data.date)
    firebaseref1.child("TransactionId").set(data.chequeNo)
    firebaseref1.child("Amount").set(data.totalAmount)
    firebaseref1.child("RetailerBalance").set(balance);
    firebaseref1.child("PushId").set(firebaseref1.getKey());

    var firebaseref=firebase.database().ref().child("Retailer").child(data.customerId.value).child("Transactions").push();
    firebaseref.child("Amount").set(data.totalAmount);
    firebaseref.child("Date").set(data.date);
    firebaseref.child("Generated").set("Online");
    firebaseref.child("PushId").set(firebaseref.getKey());
    firebaseref.child("Status").set(String("Approved"));
    firebaseref.child("TransactionId").set(data.chequeNo);
    firebaseref.child("TransactionName").set("Receipt Creation");
    firebaseref.child("TransactionType").set("Cr");
    firebaseref.child("UserBalance").set(balance);
    firebaseref.child("UserId").set(data.customerId.value);
    firebaseref.child("RetailerName").set(data.customerId.label);

    firebase.database().ref().child("Retailer").child(data.customerId.value).child("OutStanding").set(balance);

      alert("Retailer Receipt Created Successfully")

    //   for(let i=0; i< checkBoxes.length; i++) {
    //     if (checkBoxes[i].checked) {
    //       var row = checkBoxes[i].parentNode.parentNode;
    //       firebase.database().ref().child('Purchase').child(row.cells[4].innerHTML).child("PaymentStatus").set("Approved")
    //   }
    // }

    // var grid = document.getElementById("dataTable");
          
    // var checkBoxes = grid.getElementsByTagName("input");
    // for (var i = 0; i < checkBoxes.length; i++) {
    //   if (checkBoxes[i].type == 'checkbox') {
    //       checkBoxes[i].checked = false;
    //   }
    // }

      comp.setState({
          customerId: '',
        deliveryDate: '',
        productPushId: 'Select',
        sizePushId: 'Select',
        codPrice: '',
        creditPrice: '',
        mrpPrice: '',
        stock: '',
        quantity: '1',
        cart: [{}],
  
        custAddress: '',
        custName: '',
        custNumber: '',
        deliveryCharges: 0,
        discount: 0,
        flat: '',
        pincode: '',
        coord: '',
        subtotal: 0,
        grandTotal: 0,

        date: '',
        chequeNo: '',
        totalAmount: '0',
        firebaseData: []
      })
}

checkBoxOnChange() {
  let comp = this
  var grid = document.getElementById("dataTable");

  var checkBoxes = grid.getElementsByTagName("input");
  let totalAmount = 0

  for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i].checked) {
          var row = checkBoxes[i].parentNode.parentNode;
          totalAmount = (parseFloat(totalAmount) + parseFloat(row.cells[2].innerHTML)).toFixed(2)
      }
  }

  comp.setState({
    totalAmount: totalAmount
  })
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Entry" breadcrumbItem="Receipt" />
                    <Row id = "case-list">
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                      <Col md = "4"> 
                                                <FormGroup className="select2-container">
                                                      <label className="control-label">Retailer Name</label>
                                                      <Select
                                                        value = {this.state.customerId}
                                                        // isMulti={true}
                                                        onChange={this.handleMulti}
                                                        options={this.state.custs}
                                                        classNamePrefix="select1-selection"
                                                        id = "customerId"
                                                        placeholder = "Select Retailer"
                                                      />
                                                  </FormGroup>
                                                </Col>
                                        </Row>
                                        <br />

                                        <h6>Retailer Name: {this.state.custName}</h6> <br />

                                        <Row>   

                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Date<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="date"
                                                          placeholder="Date"
                                                          type="date"
                                                          className="form-control"
                                                          id="date"
                                                          value = {this.state.date}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 4}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">Cheque Number / Voucher Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="chequeNo"
                                                          placeholder="Cheque Number / Voucher Number"
                                                          type="text"
                                                          className="form-control"
                                                          id="chequeNo"
                                                          value = {this.state.chequeNo}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>

                                                <Row>
                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Amount<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="totalAmount"
                                                          placeholder="Total Amount"
                                                          type="number"
                                                          className="form-control"
                                                          id="totalAmount"
                                                          value = {this.state.totalAmount}
                                                          // disabled
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                            <br /><br />

                                            {/* <h6><b>Total Amount: </b>{this.state.totalAmount}</h6> */}

                                            {/* <Row>
                                                <Col md = "12">

                                                <div className="table-responsive" data-pattern="priority-columns">
                                                                <Table className="table mb-0 table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                        <th></th>
                                                                        <th>Item Details</th>
                                                                        <th>Amount</th>
                                                                        <th>Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id = "dataTable">
                                                                    {this.state.firebaseData.map((rowData, index) => (
                                                                        <tr key = {index}>
                                                                          <td><input type = "checkbox" onChange = {this.checkBoxOnChange.bind(this)} ></input><span className="vs-checkbox"><span className="vs-checkbox--check"></span></span></td>
                                                                          <td>{rowData.ItemDetails}</td>
                                                                          <td>{rowData.Total}</td>
                                                                          <td>{rowData.OrderDate}</td>
                                                                          <td style = {{display: "none"}}>{rowData.Pushid}</td>
                                                                    </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                        </div>
                                                        </Col>

                                                </Row> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Receipt;