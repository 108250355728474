import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


class Size extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizeName: '',
      pushId: '',
      firebaseData: [],
      success_msg: false,
      update_msg: false,
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Masters").child("Size")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                PushId: doc.val().PushId,
                SizeName: doc.val().SizeName
            }
            newData.push(dict)
        })
        this.setState({
          firebaseData: newData
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.sizeName === '') {
      alert("Enter Size Name");
      document.getElementById("sizeName").focus();
      return;
  }

    var firebaseref = firebase.database().ref().child("Masters").child("Size").push()
    firebaseref.child("PushId").set(firebaseref.getKey())
    firebaseref.child("SizeName").set(data.sizeName)

    comp.setState({
      success_msg: true
    })
    data.sizeName = ""
    document.getElementById("create-form").reset()
}

updateRow(e) {
  e.preventDefault();

  let data = this.state

  let comp = this

  if(data.sizeName === '') {
      alert("Enter Size Name");
      document.getElementById("sizeName").focus();
      return;
  }

  var firebaseref = firebase.database().ref().child("Masters").child("Size").child(data.pushId)
  firebaseref.child("SizeName").set(data.sizeName)

  comp.setState({
    update_msg: true
  })
  data.sizeName = ""
  document.getElementById("create-form").reset()
  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"
} 

editRow(key) {
  let data = this.state
  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  data.sizeName = key.SizeName
  data.pushId = key.PushId

  document.getElementById("sizeName").value = key.SizeName
}

delete(key) {
  if (window.confirm('Are you sure you want to delete the size?')) {
      firebase.database().ref().child("Masters").child('Size').child(key.PushId).remove()
      alert("Successfully Deleted!")
    } else {}
}

myFunction() {
  var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
  var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
  input = document.getElementById("search");
  filter = input.value.toUpperCase();
  table = document.getElementById("dataTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
  td1 = tr[i].getElementsByTagName("td")[1];
  td2 = tr[i].getElementsByTagName("td")[2];
  // td9 = tr[i].getElementsByTagName("td")[9];

  if (td1) {
    txtValue1 = td1.textContent || td1.innerText;
    txtValue2 = td2.textContent || td2.innerText;
  
   var main = txtValue1+ txtValue2;
     if (main.toUpperCase().indexOf(filter) > -1) {
      tr[i].style.display = "";
    } else {
      tr[i].style.display = "none";
    }
  }       
}
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Create Size" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Size Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="sizeName"
                                                          placeholder="Size Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="sizeName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                            <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                            <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                   Size Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  Size Updated Successfully!
                                                </SweetAlert>
                                              ) : null}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                    <Row>
                                            <Col md = "6">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                        </Row>
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Size Name</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.SizeName}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Size;