import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, NavLink, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from "classnames";
import { faArrowLeft, faInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import Input from "reactstrap/lib/Input";

class PurchaseReturn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingOrders: [],
            acceptedOrders: [],
            dispatchedOrders: [],
            deliveredOrders: [],
            activeTabJustify: "1",
            itemOpen: false,
            cart: [],
            deliveryPartners: [],
            deliveryNumber: '',
            deliveryName: '',
            topIndex: '-1'
        };
        this.togglemodal.bind(this);
        this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
    }

    toggleCustomJustified(tab) {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab
			});
		}
    }
    
    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount() {
        let comp = this

        firebase.database().ref().child("Masters").child("DeliveryPartner")
        .once("value", function(snapshot) {
            if(snapshot.exists()) {
                let del = []
                snapshot.forEach(function(snap) {
                    del.push(snap.val())
                })
                comp.setState({
                    deliveryPartners: del
                })
            }
        })

        firebase.database().ref().child("Purchase")
        .orderByChild("Status").equalTo("1")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let newData1 = []
                var status = ""
                snapshot.forEach(function(snapshot) {
                  const lock = {
                      Address: snapshot.val().Address,
                      CName: snapshot.val().CName,
                      Flat: snapshot.val().Flat,
                      DeliveryCharges: snapshot.val().DeliveryCharges,
                      Discount: snapshot.val().Discount,
                      Number: snapshot.val().Number,
                      OrderDate: snapshot.val().OrderDate,
                      OrderDateTime: snapshot.val().OrderDateTime,
                      OrderNo: snapshot.val().OrderNo,
                      OrderType: snapshot.val().OrderType,
                      Packing: snapshot.val().Packing,
                      Payment: snapshot.val().Payment,
                      PaymentId: snapshot.val().PaymentId,
                      Status: snapshot.val().Status,
                      PushId: snapshot.val().Pushid,
                      Qty: snapshot.val().Qty,
                      SubTotal: snapshot.val().SubTotal,
                      Total: snapshot.val().Total,
                      UserId: snapshot.val().UserId
                  }
                  newData1.push(lock)
                })
                comp.setState({
                    pendingOrders: newData1
                })
            } else {
                comp.setState({
                    pendingOrders: []
                })
            }
        })
    }

    viewModal2(key) {

        document.getElementById("table").style.display = "none"
        document.getElementById("detail").style.display = "block"

        sessionStorage.setItem("orderPushId", key.PushId)
        let comp = this

        comp.setState({
            customerName: key.CName,
            customerNumber: key.Number,
            customerAddress: key.Address
        })

      
        firebase.database().ref().child("Purchase").child(key.PushId).child("Cart")
        .once("value", function(snapshot) {
          var carts = [];
          snapshot.forEach(function(doc) {
            const lock = {
              Image: doc.val().Image,
              Name: doc.val().Name,
              Price: doc.val().Price,
              Qty: doc.val().Qty,
              Total: doc.val().Total,
              Category: doc.val().Category,
              Units: doc.val().Units,
              Type: doc.val().Type,
              PushId: doc.val().PushId,
              ProductId: doc.val().ProductId
            }
            carts.push(lock)
          });
          comp.setState({
            cart: carts
          })
      });
      }
      
      closeModal2() {
        this.setState({
            itemOpen: false
        })
      }

      acceptOrder(key) {
        if (window.confirm('Are you sure you want to accept the order?')) {
            firebase.database().ref().child('Purchase').child(key.PushId).child("Status").set("2")
            alert("Order Accepted Successfully!")
          } else {}
    }

    assignDeliveryAgent(key, index) {
        let data = this.state

        // console.log(data.topIndex)
        // console.log(index)

        if(index !== data.topIndex) {
            alert("Select Delivery Agent")
            return
        }

        var ref = firebase.database().ref().child('Purchase').child(key.PushId)
        ref.child("DeliveryPartnerNumber").set(data.deliveryNumber)
        ref.child("DeliveryPartnerName").set(data.deliveryName)
        ref.child("DeliveryPartnerStatus").set(data.deliveryNumber)

        alert("Delivery Partner Assigned Successfully!")
    }

    deliveryOnChange(e,index) {
        this.setState({
            [e.target.name]: e.target.value,
            topIndex: index
        })
      
        firebase.database().ref().child("Masters").child("DeliveryPartner").child(e.target.value).child("DeliveryName")
        .once("value",(snapshot) => {
            this.setState({
              deliveryName:snapshot.val()
          })
        })
      }

      quantityOnChange(evt, key, index) {
        const value = evt.target.value;
        console.log(value)

        var row = document.getElementById('cartTable').rows[parseInt(index)].cells;


    
      if(value !== '') {
        let total = (parseFloat(value) * parseFloat(key.Price)).toFixed(2)
        console.log(total)
    
        row[parseInt(7)].innerHTML= value;
        row[parseInt(9)].innerHTML= total
      }
    }

    updatePrice(e) {
        let orderPushId = sessionStorage.getItem("orderPushId")

        var myTab = document.getElementById("cartTable");

        console.log("sswh")

        var subtotal= 0
        var quantity = 0
        var itemDetails = ""
      
          for (let i = 0; i < myTab.rows.length; i++) {
              var objCells = myTab.rows.item(i).cells;
              subtotal = (parseFloat(subtotal) + (parseFloat(objCells.item(9).innerHTML))).toFixed(2)
              quantity = parseInt(quantity + (parseInt(objCells.item(7).innerHTML)))
              itemDetails = itemDetails + objCells.item(3).innerHTML + ":" + objCells.item(7).innerHTML + ","
          }
    
          function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [year, month, day].join('-');
        }
    
          var date = new Date()
          var todayDate = formatDate(date)
        
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
          "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
          var orderDateTime = ("0" + date.getDate()).slice(-2) + ','+ monthNames[date.getMonth()] + ' ' +date.getFullYear() + ' '
            + date.getHours() + ':' + date.getMinutes()
        
          var epchTime = Date.parse(date)
        

      
        firebase.database().ref().child("Purchase").child(orderPushId)
        .once("value", function(snapshot) {
          if(snapshot.exists()) {       
              
          var total = ((parseFloat(subtotal) + parseFloat(snapshot.val().DeliveryCharges)) - (parseFloat(snapshot.val().Discount))).toFixed(2)
            
    
        
              var firebaseref=firebase.database().ref().child("Purchase").push();
              firebaseref.child("Pushid").set(firebaseref.getKey());
              firebaseref.child("Address").set(snapshot.val().Address);
              firebaseref.child("CName").set(snapshot.val().CName);
              firebaseref.child("DeliveryCharges").set(snapshot.val().DeliveryCharges);
              firebaseref.child("DeliveryPrice").set("");
              firebaseref.child("Discount").set(snapshot.val().Discount);
              firebaseref.child("Discount1").set(snapshot.val().Discount);
              firebaseref.child("Flat").set(snapshot.val().Flat);
              firebaseref.child("ItemDetails").set(String(itemDetails));
              firebaseref.child("LocationCoordinates").set(snapshot.val().LocationCoordinates);
              firebaseref.child("Number").set(snapshot.val().Number);
              firebaseref.child("OrderDate").set(String(todayDate));
              firebaseref.child("OrderDateTime").set(String(orderDateTime));
              firebaseref.child("OrderNo").set(String(epchTime));
              firebaseref.child("OrderType").set("Normal");
              firebaseref.child("Packing").set("0.00");
              firebaseref.child("Payment").set("CREDIT");
              firebaseref.child("PaymentId").set("CREDIT");
              firebaseref.child("PaymentStatus").set("Pending");
              firebaseref.child("Pincode").set(snapshot.val().Pincode);
              firebaseref.child("Qty").set(String(quantity));
              firebaseref.child("Status").set("1");
              firebaseref.child("SubTotal").set(String(subtotal));
              firebaseref.child("Taxes").set("0.00");
              firebaseref.child("Total").set(String(total));
              firebaseref.child("UserId").set(String(snapshot.val().UserId));
        
              for (let i = 0; i < myTab.rows.length; i++) {
                var objCells = myTab.rows.item(i).cells;
                    var firebaseref1 = firebaseref.child("Cart").child(objCells.item(0).innerHTML);
                    firebaseref1.child("Category").set(objCells.item(4).innerHTML);
                    firebaseref1.child("Image").set(objCells.item(1).innerHTML);
                    firebaseref1.child("Name").set(objCells.item(3).innerHTML);
                    firebaseref1.child("Price").set(objCells.item(8).innerHTML);
                    firebaseref1.child("ProductId").set(objCells.item(10).innerHTML);
                    firebaseref1.child("PushId").set(objCells.item(0).innerHTML);
                    firebaseref1.child("Qty").set(objCells.item(7).innerHTML);
                    firebaseref1.child("Total").set(String(parseFloat(objCells.item(9).innerHTML).toFixed(2)));
                    firebaseref1.child("Units").set(objCells.item(5).innerHTML);    
              }

              var salesfirebaseref=firebase.database().ref().child("PurchaseReturn").push();
              salesfirebaseref.child("Pushid").set(salesfirebaseref.getKey());
              salesfirebaseref.child("Address").set(snapshot.val().Address);
              salesfirebaseref.child("CName").set(snapshot.val().CName);
              salesfirebaseref.child("DeliveryCharges").set(snapshot.val().DeliveryCharges);
              salesfirebaseref.child("DeliveryPrice").set("");
              salesfirebaseref.child("Discount").set(snapshot.val().Discount);
              salesfirebaseref.child("Discount1").set(snapshot.val().Discount);
              salesfirebaseref.child("Flat").set(snapshot.val().Flat);
              salesfirebaseref.child("ItemDetails").set(String(itemDetails));
              salesfirebaseref.child("LocationCoordinates").set(snapshot.val().LocationCoordinates);
              salesfirebaseref.child("Number").set(snapshot.val().Number);
              salesfirebaseref.child("OrderDate").set(String(todayDate));
              salesfirebaseref.child("OrderDateTime").set(String(orderDateTime));
              salesfirebaseref.child("OrderNo").set(String(epchTime));
              salesfirebaseref.child("OrderType").set("Normal");
              salesfirebaseref.child("Packing").set("0.00");
              salesfirebaseref.child("Payment").set("CREDIT");
              salesfirebaseref.child("PaymentId").set("CREDIT");
              salesfirebaseref.child("PaymentStatus").set("Pending");
              salesfirebaseref.child("Pincode").set(snapshot.val().Pincode);
              salesfirebaseref.child("Qty").set(String(quantity));
              salesfirebaseref.child("Status").set("1");
              salesfirebaseref.child("SubTotal").set(String(subtotal));
              salesfirebaseref.child("Taxes").set("0.00");
              salesfirebaseref.child("Total").set(String(total));
              salesfirebaseref.child("UserId").set(String(snapshot.val().UserId));
        
              for (let i = 0; i < myTab.rows.length; i++) {
                var objCells = myTab.rows.item(i).cells;
                    var salesfirebaseref1 = salesfirebaseref.child("Cart").child(objCells.item(0).innerHTML);
                    salesfirebaseref1.child("Category").set(objCells.item(4).innerHTML);
                    salesfirebaseref1.child("Image").set(objCells.item(1).innerHTML);
                    salesfirebaseref1.child("Name").set(objCells.item(3).innerHTML);
                    salesfirebaseref1.child("Price").set(objCells.item(8).innerHTML);
                    salesfirebaseref1.child("ProductId").set(objCells.item(10).innerHTML);
                    salesfirebaseref1.child("PushId").set(objCells.item(0).innerHTML);
                    salesfirebaseref1.child("Qty").set(objCells.item(7).innerHTML);
                    salesfirebaseref1.child("Total").set(String(parseFloat(objCells.item(9).innerHTML).toFixed(2)));
                    salesfirebaseref1.child("Units").set(objCells.item(5).innerHTML);    
              }
        
      
          alert("Updated Successfully")
      
          }
        })

        document.getElementById("table").style.display = "block"
        document.getElementById("detail").style.display = "none"
      }

      back() {
        document.getElementById("table").style.display = "block"
        document.getElementById("detail").style.display = "none" 
      }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Purchase" breadcrumbItem="Purchase Return" />

                        <Row>
                            <Col lg={12}>
                                <Card id = "table">
                                    <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "1"
													})}
													onClick={() => {
														this.toggleCustomJustified("1");
													}}
												>
													<span className="d-none d-sm-block">Pending Orders</span>
												</NavLink>
											</NavItem>
										</Nav>

										<TabContent activeTab={this.state.activeTabJustify}>
                                        <TabPane tabId="1" className="p-3">
												<Row>
													<Col sm="12">
														<CardText>
                                                        <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Customer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Delivery Address</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.pendingOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.viewModal2.bind(this, rowData)} type="submit">Edit</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Address}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                          								</CardText>
													</Col>
												</Row>
											</TabPane>
										</TabContent>

                                        {/* <Modal isOpen={this.state.itemOpen} toggle={this.closeModal2.bind(this)}>
                                            <ModalHeader toggle={this.closeModal2.bind(this)}><h3>Items</h3></ModalHeader>
                                                <ModalBody>
                                                <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                            <tr>
                                                                    <th>Image</th>
                                                                    <th>Name</th>
                                                                    <th>Category</th>
                                                                    <th>Units</th>
                                                                    <th>Qty</th>
                                                                    <th>Price</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.cart.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td><img src= {rowData.Image} alt = "Item" className="img-responsive inline-block" width="50" height="50" /></td>
                                                                        <td>{rowData.Name}</td>
                                                                        <td>{rowData.Category}</td>
                                                                        <td>{rowData.Units}</td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Price}</td>
                                                                        <td>{rowData.Total}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                                                </ModalBody>
                                            </Modal> */}

                                    </CardBody>
                                </Card>

                                <Card id = "detail" style = {{display: "none"}}>
                                    <CardBody>
                                    <FontAwesomeIcon id = "mainBack" style = {{color :"black", fontSize: "25px", cursor: "pointer"}} onClick={this.back.bind(this)} icon={faArrowLeft}/>  <br/><br />
                                        <h6>Customer Name: {this.state.customerName}</h6>
                                        <h6>Customer Number: {this.state.customerNumber}</h6>
                                        <h6>Address: {this.state.customerAddress}</h6>
                                    <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                            <tr>
                                                                    <th>Image</th>
                                                                    <th>Name</th>
                                                                    <th>Category</th>
                                                                    <th>Units</th>
                                                                    <th>Qty</th>
                                                                    <th>Price</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody id = "cartTable">
                                                                {this.state.cart.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td style = {{display: "none"}}>{rowData.PushId}</td>
                                                                        <td style = {{display: "none"}}>{rowData.Image}</td>
                                                                        <td><img src= {rowData.Image} alt = "Item" className="img-responsive inline-block" width="50" height="50" /></td>
                                                                        <td>{rowData.Name}</td>
                                                                        <td>{rowData.Category}</td>
                                                                        <td>{rowData.Units}</td>
                                                                        <td><textarea type = "text" rows = {1} cols = {30} id = "quantity" name = "quantity" defaultValue = {rowData.Qty} onChange={(event) => this.quantityOnChange(event, rowData, index)}></textarea></td>
                                                                        <td style = {{display: "none"}}>{rowData.Qty}</td>
                                                                        <td>{rowData.Price}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td style = {{display: "none"}}>{rowData.ProductId}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                                                    <br />

                                                    <Button color="primary" onClick = {this.updatePrice.bind(this)}>Update</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {/* <Table responsive hover>
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Units</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {this.state.cart.map((itemData, index) => (
                                  <tr key = {index}>
                                    <td>{itemData.Name}</td>
                                    <td>{itemData.Units}</td>
                                    <td><textarea type = "text" rows = {1} cols = {30} id = "quantity" name = "quantity" defaultValue = {itemData.Quantity} onChange={(event) => this.quantityOnChange(event, itemData, index)}></textarea></td>
                                    <td>{itemData.Price}</td>
                                    <td>{itemData.Total}</td>
                                  </tr>
                                  ))}
                              </tbody>
                            </Table> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PurchaseReturn;
