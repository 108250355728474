import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Label ,Container, Input, Form, Table, NavLink, Button, Modal, ModalHeader, ModalBody,
NavItem, TabContent, TabPane, CardText} from "reactstrap";

import Nav from "reactstrap/lib/Nav";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import classnames from "classnames";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class CreateVendorInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      startDate: '',
      endDate: '',
      customerId: '',
      customerIds: [],
      customerNames: [],


      deliveryDate: '',
      products: [],
      productPushId: 'Select',
      sizes: [],
      sizePushId: 'Select',
      codPrice: '',
      creditPrice: '',
      mrpPrice: '',
      stock: '',
      quantity: '1',
      cart: [{}],

      custAddress: '',
      custName: '',
      custNumber: '',
      deliveryCharges: 0,
      discount: 0,
      flat: '',
      pincode: '',
      coord: '',
      grandTotal: 0,
      subtotal: 0,
      lessCashDiscount: 0,
      lessSpecialDiscount: 0,
      lessDiscountAmount: 0,
      finalTotal: 0,
      gst: 0,


      pendingOrders: [],
      acceptedOrders: [],
      dispatchedOrders: [],
      deliveredOrders: [],
      activeTabJustify: "1",
      itemOpen: false,
      cart1: [],
      deliveryPartners: [],
      deliveryNumber: '',
      deliveryName: '',
      topIndex: '-1',

      creditDays: '',
      outstandingBalance: '',
      dueDate: '',
      salesMan: 'Select',
      salesManName: '',
      gstNo: '',
      area: ''
    };
    this.togglemodal.bind(this);
    this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
  }

  viewModal2(key) {
    this.setState({
        itemOpen: true,
    })
    let comp = this

  
    firebase.database().ref().child("Purchase").child(key.PushId).child("Cart")
    .once("value", function(snapshot) {
      var carts = [];
      snapshot.forEach(function(doc) {
        const lock = {
          Image: doc.val().Image,
          Name: doc.val().Name,
          Price: doc.val().Price,
          Qty: doc.val().Qty,
          Total: doc.val().Total,
          Category: doc.val().Category,
          Units: doc.val().Units,
          Type: doc.val().Type,
          PushId: doc.val().PushId,
          CustomerProductId: doc.val().CustomerProductId,
        }
        carts.push(lock)
      });
      comp.setState({
        cart1: carts
      })
  });
  }

  acceptOrder(key) {
    if (window.confirm('Are you sure you want to accept the purchase?')) {
        firebase.database().ref().child('Purchase').child(key.PushId).child("Status").set("2")
        alert("Order Accepted Successfully!")
      } else {}
}

salesManOnChange(e,index) {
  this.setState({
      [e.target.name]: e.target.value,
  })

  firebase.database().ref().child("Masters").child("SalesAgent").child(e.target.value).child("SalesName")
  .once("value",(snapshot) => {
      this.setState({
        salesManName:snapshot.val()
    })
  })
}

assignDeliveryAgent(key, index) {
    let data = this.state

    // console.log(data.topIndex)
    // console.log(index)

    if(index !== data.topIndex) {
        alert("Select Delivery Agent")
        return
    }

    var ref = firebase.database().ref().child('Purchase').child(key.PushId)
    ref.child("DeliveryPartnerNumber").set(data.deliveryNumber)
    ref.child("DeliveryPartnerName").set(data.deliveryName)
    ref.child("DeliveryPartnerStatus").set(data.deliveryNumber)
    ref.child("Status").set("4")

    alert("Sales Agent Assigned Successfully!")
}

salesOnChange(e,index) {
    this.setState({
        [e.target.name]: e.target.value,
        topIndex: index
    })
  
    firebase.database().ref().child("Masters").child("SalesAgent").child(e.target.value).child("SalesName")
    .once("value",(snapshot) => {
        this.setState({
          deliveryName:snapshot.val()
      })
    })
  }

  toggleCustomJustified(tab) {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab
			});
		}
    }
    
    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

  componentDidMount() {
    let comp = this

    firebase.database().ref().child("Customer")
    .orderByChild("CustomerType").equalTo("Creditor")
    .once('value',(snapshot) => {
        if(snapshot.exists()) {
          const customerIds = []
          const customerNames = []
          let newData = []
          snapshot.forEach(function(doc) {
              customerIds.push(doc.val().UserId)
              customerNames.push(doc.val().CustomerName)
              const dict = {
                options: [ {
                label: doc.val().CustomerName,
                value: doc.val().UserId
                }]
            }
            newData.push(dict)
          })
          comp.setState({
            customerNames: customerNames,
            customerIds: customerIds,
            custs: newData
          })
        }
    })

    firebase.database().ref().child("Products")
    .once('value',(snapshot) => {
        if(snapshot.exists()) {
          const products = []
          snapshot.forEach(function(doc) {
            if(doc.val().CustomerProductId !== undefined) {
            const dict = {
                options: [ {
                label: doc.val().CustomerProductId + "-" + doc.val().Name,
                value: doc.val().PushId
                }]
            }
              products.push(dict)
          }
          })
          comp.setState({
            products: products,
          })
        }
    })

    firebase.database().ref().child("Purchase")
    .orderByChild("Status").equalTo("1")
    .on("value", function(snapshot) {
        if(snapshot.exists()) {
            let newData1 = []
            var status = ""
            snapshot.forEach(function(snapshot) {
              const lock = {
                  Address: snapshot.val().Address,
                  CName: snapshot.val().CName,
                  Flat: snapshot.val().Flat,
                  DeliveryCharges: snapshot.val().DeliveryCharges,
                  Discount: snapshot.val().Discount,
                  Number: snapshot.val().Number,
                  OrderDate: snapshot.val().OrderDate,
                  OrderDateTime: snapshot.val().OrderDateTime,
                  OrderNo: snapshot.val().OrderNo,
                  OrderType: snapshot.val().OrderType,
                  Packing: snapshot.val().Packing,
                  Payment: snapshot.val().Payment,
                  PaymentId: snapshot.val().PaymentId,
                  Status: snapshot.val().Status,
                  PushId: snapshot.val().Pushid,
                  Qty: snapshot.val().Qty,
                  SubTotal: snapshot.val().SubTotal,
                  Total: snapshot.val().Total,
                  UserId: snapshot.val().UserId
              }
              newData1.push(lock)
            })
            comp.setState({
                pendingOrders: newData1
            })
        } else {
            comp.setState({
                pendingOrders: []
            })
        }
    })

    firebase.database().ref().child("Purchase")
    .orderByChild("Status").equalTo("2")
    .on("value", function(snapshot) {
        if(snapshot.exists()) {
            let newData2 = []
            var status = ""
            snapshot.forEach(function(snapshot) {
              const lock = {
                Address: snapshot.val().Address,
                CName: snapshot.val().CName,
                Flat: snapshot.val().Flat,
                DeliveryCharges: snapshot.val().DeliveryCharges,
                Discount: snapshot.val().Discount,
                Number: snapshot.val().Number,
                OrderDate: snapshot.val().OrderDate,
                OrderDateTime: snapshot.val().OrderDateTime,
                OrderNo: snapshot.val().OrderNo,
                OrderType: snapshot.val().OrderType,
                Packing: snapshot.val().Packing,
                Payment: snapshot.val().Payment,
                PaymentId: snapshot.val().PaymentId,
                Status: snapshot.val().Status,
                PushId: snapshot.val().Pushid,
                Qty: snapshot.val().Qty,
                SubTotal: snapshot.val().SubTotal,
                Total: snapshot.val().Total,
                UserId: snapshot.val().UserId,
                DeliveryPartnerName: snapshot.val().DeliveryPartnerName,
                DeliveryPartnerNumber: snapshot.val().DeliveryPartnerNumber,
              }
              newData2.push(lock)
            })
            comp.setState({
                acceptedOrders: newData2
            })
        } else {
            comp.setState({
                acceptedOrders: []
            })
        }
    })

    firebase.database().ref().child("Masters").child("SalesAgent")
    .once('value', (snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
          if(doc.val().MobileNumber !== undefined) {
            const dict = {
              SalesName: doc.val().SalesName,
              MobileNumber: doc.val().MobileNumber,
              Pincodes: doc.val().Pincodes,
              Email: doc.val().Email,
              Address: doc.val().Address,
              Password: doc.val().Password
            }
            newData.push(dict)
          }
        })
        this.setState({
          deliveryPartners: newData
        })
      }
    })
  }

  closeModal2() {
    this.setState({
        itemOpen: false
    })
  }

  handleMulti = customerId => {
    this.setState({ customerId });

    let data = this.state
    let comp = this

    console.log(customerId.value)

firebase.database().ref().child("Customer").child(customerId.value)
.once("value", function(snapshot) {
    let address = ""
    let coord = ""
    let flat = ""
    comp.setState({
      custName: snapshot.val().CustomerName,
      custNumber: snapshot.val().MobileNumber,
      pincode: snapshot.val().Pincode,
      creditDays: snapshot.val().CreditDays,
      outstandingBalance: snapshot.val().OutStanding,
      dueDate: snapshot.val().CreditDays,
      custAddress: snapshot.val().VendorAddress,
      coord: coord,
      flat: flat
    })

    // snapshot.child("Address").forEach(function(snap) {
    //     address = snap.val().Address
    //     coord = snap.val().Coord
    //     flat = snap.val().Flat
    // })

    // comp.setState({
    //     custAddress: address,
    //     coord: coord,
    //     flat: flat
    // })
});
}


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  deliveryOnChange(event) {
    let data = this.state
    
    if(event.target.value !== '') {
      var total = ((parseFloat(data.subtotal) + parseFloat(event.target.value)) - (parseFloat(data.discount))).toFixed(2)

      this.setState({
          deliveryCharges: event.target.value,
          grandTotal: total
      });

    } else {
      var total = ((parseFloat(data.subtotal) + parseFloat(0)) - (parseFloat(data.discount))).toFixed(2)

      this.setState({
          deliveryCharges: 0,
          grandTotal: total
      });
    }
  }

  discountOnChange(event) {
    let data = this.state

    if(event.target.value !== '') {
      var total = ((parseFloat(data.subtotal) + parseFloat(data.deliveryCharges)) - (parseFloat(event.target.value))).toFixed(2)

      this.setState({
          discount: event.target.value,
          grandTotal: total
      });
    } else {
      var total = ((parseFloat(data.subtotal) + parseFloat(data.deliveryCharges)) - (parseFloat(0))).toFixed(2)

      this.setState({
          discount: 0,
          grandTotal: total
      });
    }
  }

  lessCashDiscountOnChange(event) {
    let data = this.state

    if(event.target.value !== '') {
      var total = ((parseFloat(data.subtotal)) - ((parseFloat(event.target.value) / 100) * parseFloat(data.subtotal)) - ((parseFloat(data.lessSpecialDiscount) / 100) * parseFloat(data.subtotal)) - (parseFloat(data.lessDiscountAmount))).toFixed(2)
      var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

      this.setState({
          lessCashDiscount: event.target.value,
          grandTotal: total,
          finalTotal: finalTotal
      });
    } else {
      var total = ((parseFloat(data.subtotal)) - (parseFloat(0)) - ((parseFloat(data.lessSpecialDiscount) / 100) * parseFloat(data.subtotal)) - (parseFloat(data.lessDiscountAmount))).toFixed(2)
      var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

      this.setState({
          lessCashDiscount: 0,
          grandTotal: total,
          finalTotal: finalTotal
      });
    }
  }

  lessSpecialDiscountOnChange(event) {
    let data = this.state

    if(event.target.value !== '') {
      var total = ((parseFloat(data.subtotal)) - ((parseFloat(event.target.value) / 100) * parseFloat(data.subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(data.subtotal)) - (parseFloat(data.lessDiscountAmount))).toFixed(2)
      var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

      this.setState({
          lessSpecialDiscount: event.target.value,
          grandTotal: total,
          finalTotal: finalTotal
      });
    } else {
      var total = ((parseFloat(data.subtotal)) - (parseFloat(0)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(data.subtotal)) - (parseFloat(data.lessDiscountAmount))).toFixed(2)
      var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

      this.setState({
          lessSpecialDiscount: 0,
          grandTotal: total,
          finalTotal: finalTotal
      });
    }
  }

  lessDiscountAmountOnChange(event) {
    let data = this.state

    console.log(parseFloat(data.subtotal))
    console.log((parseFloat(data.lessCashDiscount) / 100) * parseFloat(data.subtotal))
    console.log((parseFloat(data.lessSpecialDiscount) / 100) * parseFloat(data.subtotal))

    if(event.target.value !== '') {
      var total = ((parseFloat(data.subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(data.subtotal)) - ((parseFloat(data.lessSpecialDiscount) / 100) * parseFloat(data.subtotal)) - (parseFloat(event.target.value))).toFixed(2)
      var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

      this.setState({
          lessDiscountAmount: event.target.value,
          grandTotal: total,
          finalTotal: finalTotal
      });
    } else {
      var total = ((parseFloat(data.subtotal)) - ((parseFloat(data.lessSpecialDiscount) / 100) * parseFloat(data.subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(data.subtotal)) - (parseFloat(0))).toFixed(2)
      var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

      this.setState({
          lessDiscountAmount: 0,
          grandTotal: total,
          finalTotal: finalTotal
      });
    }
  }

  gstOnChange(event) {
    let data = this.state

    if(event.target.value !== '') {
      var total = ((parseFloat(data.grandTotal)) +  ((parseFloat(event.target.value) / 100) * parseFloat(data.grandTotal))).toFixed(2)

      this.setState({
          gst: event.target.value,
          finalTotal: total
      });
    } else {
      var total = ((parseFloat(data.grandTotal)) +  ((parseFloat(0) / 100) * parseFloat(data.grandTotal))).toFixed(2)

      this.setState({
          gst: 0,
          finalTotal: total
      });
    }
  }


  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUprCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  productsOnChange = prod => {

    this.setState({ prod });
      let comp = this

      firebase.database().ref().child('Products').child(prod.value)
      .once("value", function(snapshot) {
          if(snapshot.exists()) {
              comp.setState({
                  productName: snapshot.val().Name,
                  productImage: snapshot.val().Image,
                  category: snapshot.val().Category,
                  productPushId: snapshot.val().PushId,
                  custProdId: snapshot.val().CustomerProductId
              })
          }
      })

      firebase.database().ref().child('Products').child(prod.value).child("Sizes")
      .once("value", function(snapshot) {
          if(snapshot.exists()) {
              let sizes = []
              snapshot.forEach(function(snap) {
                sizes.push(snap.val())
              })
              comp.setState({
                  sizes: sizes
              })
          }
      })
  }

  sizeOnChange(e) {
    let comp = this

    console.log(e.target.value)

    firebase.database().ref().child('Products').child(this.state.productPushId).child("Sizes").child(e.target.value)
    .once("value", function(snapshot) {
        if(snapshot.exists()) {
            comp.setState({
                sizePushId: snapshot.val().PushId,
                sizeName: snapshot.val().Size,
                mrpPrice: snapshot.val().MRPPrice,
                codPrice: snapshot.val().CODPrice,
                creditPrice: snapshot.val().CreditPrice,
                stock: snapshot.val().Stock
            })
        }
    })
}

addCart(e) {
e.preventDefault();
    let comp = this

    let data = this.state

  if(data.productPushId === 'Select') {
      alert("Select Product");
      document.getElementById("productPushId").focus();
      return;
  }

  if(data.sizePushId === 'Select') {
    alert("Select Size");
    document.getElementById("sizePushId").focus();
    return;
    }

  if(data.quantity === '') {
    alert("Enter Quantity");
    document.getElementById("quantity").focus();
    return;
  }

  if(data.quantity < 1) {
    alert("Quantity should be greater than 0");
    document.getElementById("quantity").focus();
    return;
  }

  // if(data.quantity > data.stock) {
  //   if (window.confirm('Are you sure you want to order the product when the stock is low?')) {

  //   } else {
  //       return
  //   }
  // }

  const weightUnits = {
      Name: data.productName,
      Image: data.productImage,
      Category: data.category,
      CustomerProductId: data.custProdId,
      Price: data.codPrice,
      MRPPrice: data.mrpPrice,
      ProductId: data.productPushId,
      Qty: data.quantity,
      Total: (parseFloat(data.quantity) * parseFloat(data.codPrice)).toFixed(2),
      Units: data.sizeName,
      PushId: data.sizePushId
  }

  this.setState({
      cart: [...this.state.cart, weightUnits]
  })

  this.setState({
      productPushId: 'Select',
      quantity: '1',
      productName: '',
      productImage: '',
      category: '',
      codPrice: '',
      sizeName: '',
      mrpPrice: '',
      creditPrice: '',
      sizePushId: 'Select',
  })

  setTimeout(function() {
    var myTab = document.getElementById('dataTableCart');
    var subtotal = 0


    var myTab = document.getElementById("dataTableCart");
    for (let i = 0; i < myTab.rows.length; i++) {
      var objCells = myTab.rows.item(i).cells;
      subtotal = (parseFloat(subtotal) + (parseFloat(objCells.item(5).innerHTML))).toFixed(2)
    }

    var total = ((parseFloat(subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(subtotal)) - (parseFloat(data.lessDiscountAmount))).toFixed(2)
    var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

    comp.setState({
      subtotal: subtotal,
      grandTotal: total,
      finalTotal: finalTotal
  })
    }, 1000);
}

deleteRowCart(e) {
    let comp = this
    let data = this.state
    var con = [...this.state.cart];
    con.splice(e, 1);
    this.setState({
        cart: con
    })

    setTimeout(function() {
        var myTab = document.getElementById('dataTableCart');
        var subtotal = 0
    
        var myTab = document.getElementById("dataTableCart");
        for (let i = 0; i < myTab.rows.length; i++) {
          var objCells = myTab.rows.item(i).cells;
          subtotal = (parseFloat(subtotal) + (parseFloat(objCells.item(5).innerHTML))).toFixed(2)
        }
        var total = ((parseFloat(subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(subtotal)) - ((parseFloat(data.lessCashDiscount) / 100) * parseFloat(subtotal)) - (parseFloat(data.lessDiscountAmount))).toFixed(2)
        var finalTotal = ((parseFloat(total)) +  ((parseFloat(data.gst) / 100) * parseFloat(total))).toFixed(2)

          comp.setState({
              subtotal: subtotal,
              grandTotal: total,
              finalTotal: finalTotal
          })
        }, 1000);
}

handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    var createdUser = localStorage.getItem("name")
    if(createdUser == null) {
        createdUser = sessionStorage.getItem("name")
    }

    if(data.customerId === '') {
        alert("Enter Vendor Id");
        document.getElementById("customerId").focus();
        return;
    }

    if(data.custName === '') {
        alert("Click on the Search Icon to fetch user details")
        return
    }

    // if(data.productPushId === 'Select') {
    //     alert("Select Product");
    //     document.getElementById("productPushId").focus();
    //     return;
    // }

    var myTab = document.getElementById("dataTableCart");
    if(myTab.rows.length === 0) {
        alert("Add Items");
        return;
    }

    if(data.dueDate === 'Select') {
        alert("Select Due Date");
        document.getElementById("dueDate").focus();
        return;
    }

    if(data.salesMan === 'Select') {
      alert("Select Sales Man");
      document.getElementById("salesMan").focus();
      return;
  }


    var subtotal= 0
    var quantity = 0
    var itemDetails = ""
  
    for (let i = 0; i < myTab.rows.length; i++) {
      var objCells = myTab.rows.item(i).cells;
      subtotal = (parseFloat(subtotal) + (parseFloat(objCells.item(5).innerHTML))).toFixed(2)
      quantity = parseInt(quantity + (parseInt(objCells.item(4).innerHTML)))
      itemDetails = itemDetails + objCells.item(0).innerHTML + ":" + objCells.item(4).innerHTML + ","
  }

      function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

      var date = new Date()
      var todayDate = formatDate(date)
    
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
      var orderDateTime = ("0" + date.getDate()).slice(-2) + ','+ monthNames[date.getMonth()] + ' ' +date.getFullYear() + ' '
        + date.getHours() + ':' + date.getMinutes()
    
      var epchTime = Date.parse(date)
    
      var total = ((parseFloat(subtotal) + parseFloat(data.deliveryCharges)) - (parseFloat(data.discount))).toFixed(2)

      var tax = ((parseFloat(data.gst) / 100) * parseFloat(data.grandTotal)).toFixed(2)




      var firebaseref=firebase.database().ref().child("Purchase").push();
      firebaseref.child("Pushid").set(firebaseref.getKey());
      firebaseref.child("Address").set(data.custAddress);
      firebaseref.child("CName").set(data.custName);
      firebaseref.child("DeliveryCharges").set(data.deliveryCharges);
      firebaseref.child("DeliveryPrice").set("");
      firebaseref.child("Discount").set(data.discount);
      firebaseref.child("Discount1").set(data.discount);
      firebaseref.child("Flat").set(data.flat);
      firebaseref.child("ItemDetails").set(String(itemDetails));
      firebaseref.child("LocationCoordinates").set(data.coord);
      firebaseref.child("Number").set(data.custNumber);
      firebaseref.child("OrderDate").set(String(todayDate));
      firebaseref.child("OrderDateTime").set(String(orderDateTime));
      firebaseref.child("OrderNo").set(String(epchTime));
      firebaseref.child("OrderType").set("Normal");
      firebaseref.child("Packing").set("0.00");
      firebaseref.child("Payment").set("CREDIT");
      firebaseref.child("PaymentId").set("CREDIT");
      firebaseref.child("PaymentStatus").set("Pending");
      firebaseref.child("Pincode").set(data.pincode);
      firebaseref.child("Qty").set(String(quantity));
      firebaseref.child("Status").set("2");
      firebaseref.child("Subtotal").set(data.subtotal);
      firebaseref.child("Taxes").set(String(tax));
      firebaseref.child("GST").set(data.gst)
      firebaseref.child("Total").set(data.finalTotal);
      firebaseref.child("DueDate").set(data.dueDate);
      firebaseref.child("LessCashDiscount").set(data.lessCashDiscount);
      firebaseref.child("LessSpecialDiscount").set(data.lessSpecialDiscount);
      firebaseref.child("LessDiscountAmount").set(data.lessDiscountAmount);
      firebaseref.child("UserId").set(String(data.customerId.value));
      firebaseref.child("DeliveryPartnerNumber").set(data.salesMan)
      firebaseref.child("DeliveryPartnerName").set(data.salesManName)
      firebaseref.child("DeliveryPartnerStatus").set(data.salesMan)
      firebaseref.child("GSTNumber").set(data.gstNo)
      firebaseref.child("Area").set(data.area)

      for (let i = 0; i < myTab.rows.length; i++) {
        var objCells = myTab.rows.item(i).cells;
            var firebaseref1 = firebaseref.child("Cart").child(objCells.item(8).innerHTML);
            firebaseref1.child("CustomerProductId").set(objCells.item(10).innerHTML);
            firebaseref1.child("Category").set(objCells.item(7).innerHTML);
            firebaseref1.child("Image").set(objCells.item(6).innerHTML);
            firebaseref1.child("Name").set(objCells.item(0).innerHTML);
            firebaseref1.child("MRPPrice").set(objCells.item(2).innerHTML);
            firebaseref1.child("Price").set(objCells.item(3).innerHTML);
            firebaseref1.child("ProductId").set(objCells.item(9).innerHTML);
            firebaseref1.child("PushId").set(objCells.item(8).innerHTML);
            firebaseref1.child("Qty").set(objCells.item(4).innerHTML);
            firebaseref1.child("Total").set(String(parseFloat(objCells.item(5).innerHTML).toFixed(2)));
            firebaseref1.child("Units").set(objCells.item(1).innerHTML);    
      }

      alert("Purchase Invoice Created Successfully")

      comp.setState({
          customerId: '',
        deliveryDate: '',
        productPushId: 'Select',
        sizePushId: 'Select',
        codPrice: '',
        creditPrice: '',
        mrpPrice: '',
        stock: '',
        quantity: '1',
        cart: [{}],
  
        custAddress: '',
        custName: '',
        custNumber: '',
        deliveryCharges: 0,
        discount: 0,
        flat: '',
        pincode: '',
        coord: '',
        subtotal: 0,
        grandTotal: 0,
        grandTotal: 0,
        lessCashDiscount: 0,
        lessSpecialDiscount: 0,
        lessDiscountAmount: 0,
        finalTotal: 0,
        gst: 0,

        dueDate: 'Select',
        creditDays: '',
        outstandingBalance: '',
        prod: [],
        salesMan: 'Select',
        salesManName: '',
        gstNo: '',
        area: ''
      })
}

fetchCart(key) {
    sessionStorage.setItem("orderPushId", key.PushId)
    this.setState({
        orderDetail: true
    })
}

moveToInvoice(key) {
    sessionStorage.setItem("orderPushId", key.PushId)
    window.open("/purchase-invoice")
    // this.setState({
    //     invoice: true
    // })
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Entry" breadcrumbItem="Purchase" />
            {this.state.orderDetail ? <Redirect to = "/purchase-detials" /> : null }
            {/* {this.state.invoice ? <Redirect to = "/purchase-invoice" /> : null } */}
                    <Row id = "case-list">
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                    <Row>
                                            <Col md = "4"> 
                                                <FormGroup className="select2-container">
                                                      <label className="control-label">Customer Name</label>
                                                      <Select
                                                        value = {this.state.customerId}
                                                        // isMulti={true}
                                                        onChange={this.handleMulti}
                                                        options={this.state.custs}
                                                        classNamePrefix="select1-selection"
                                                        id = "customerId"
                                                        placeholder = "Select Customer"
                                                      />
                                                  </FormGroup>
                                                </Col>
                                        </Row>
                                        <br />

                                        <h6>Due Date: {this.state.creditDays}</h6> <br />
                                        <h6>OutStanding Balance: {this.state.outstandingBalance}</h6> <br />

                                        <Row>
                                                <Col md = "4">
                                                    <FormGroup className="select2-container">
                                                      <label className="control-label">Products</label>
                                                      <Select
                                                        value = {this.state.prod}
                                                        // isMulti={true}
                                                        onChange={this.productsOnChange}
                                                        options={this.state.products}
                                                        classNamePrefix="select1-selection"
                                                        id = "prod"
                                                        placeholder = "Select Product"
                                                      />
                                                  </FormGroup>
                                                </Col>
                                        </Row>

                                        <br />

                                        {console.log(this.state.sizes)}

                                        <Row>   

                                                <Col md = "2">
                                                    <FormGroup>
                                                    <Label for="exampleEmailGrid">Size</Label>
                                                    <Input type="select" id = "sizePushId" name = "sizePushId" value = {this.state.sizePushId} onChange = {this.sizeOnChange.bind(this)}>
                                                        <option value = "Select">Select Size</option>
                                                        {this.state.sizes.map((city, index) => {
                                                            return (
                                                                <option key = {index} value={city.PushId}>{city.Size}</option>
                                                                );
                                                        })};
                                                    </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">MRP Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mrpPrice"
                                                          placeholder="MRP Price"
                                                          type="number"
                                                          className="form-control"
                                                          id="mrpPrice"
                                                          value = {this.state.mrpPrice}
                                                          disabled
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">COD Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="codPrice"
                                                          placeholder="COD Price"
                                                          type="number"
                                                          className="form-control"
                                                          id="codPrice"
                                                          value = {this.state.codPrice}
                                                          disabled
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="creditPrice"
                                                          placeholder="Credit Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="creditPrice"
                                                          value = {this.state.creditPrice}
                                                          disabled
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                {/* <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Stock<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="stock"
                                                          placeholder="Stock"
                                                          type="number"
                                                          className="form-control"
                                                          id="stock"
                                                          value = {this.state.stock}
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col> */}

                                                <Col md="2">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Quantity<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                        name="quantity"
                                                        placeholder="Quantity"
                                                        type="number"
                                                        className="form-control"
                                                        id="quantity"
                                                        value = {this.state.quantity}
                                                        onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}} style = {{margin: "auto"}}>
                                                        <Button color = "primary" onClick = {this.addCart.bind(this)}  type="submit" >Add</Button>
                                                </Col>
                                            </Row>
                                            <br />

                                            <Row>
                                                <Col md = "12">

                                                <div className="table-responsive" data-pattern="priority-columns">
                                                                <Table className="table mb-0 table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                                <th>Product Name</th>
                                                                                <th>Size</th>
                                                                                <th>MRP Price</th>
                                                                                <th>Price</th>
                                                                                <th>Quantity</th>
                                                                                <th>Total</th>
                                                                                <th>Delete</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id = "dataTableCart">
                                                                    {this.state.cart.slice(1, this.state.cart.length).map((rowData, index) => (
                                                                        <tr key = {index}>
                                                                            <td>{rowData.Name}</td>
                                                                            <td>{rowData.Units}</td>
                                                                            <td>{rowData.MRPPrice}</td>
                                                                            <td>{rowData.Price}</td>
                                                                            <td>{rowData.Qty}</td>
                                                                            <td>{rowData.Total}</td>
                                                                            <td style = {{display: "none"}}>{rowData.Image}</td>
                                                                            <td style = {{display: "none"}}>{rowData.Category}</td>
                                                                            <td style = {{display: "none"}}>{rowData.PushId}</td>
                                                                            <td style = {{display: "none"}}>{rowData.ProductId}</td>
                                                                            <td style = {{display: "none"}}>{rowData.CustomerProductId}</td>
                                                                            <td onClick = {this.deleteRowCart.bind(this, index +1)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                        </div>
                                                        </Col>

                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col md = "8"></Col>
                                                    <Col md = "2"><h6><b>Sub Total: </b></h6></Col>
                                                    <Col md = "2"><h6><b>{this.state.subtotal}</b></h6></Col>
                                                </Row>

                                                <br />

                                                <Row>
                                                    <Col md = "8"></Col>
                                                    <Col md = "2"><h6><b>Less Cash Discount: </b></h6></Col>
                                                    <Col md = "2">
                                                    <FormGroup>
                                                        <Input
                                                          name="lessCashDiscount"
                                                          placeholder="Less Cash Discount"
                                                          type="number"
                                                          className="form-control"
                                                          id="lessCashDiscount"
                                                          value = {this.state.lessCashDiscount}
                                                          onChange = {this.lessCashDiscountOnChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                    </Col>
                                                </Row>

                                                <br />

                                                  <Row>
                                                      <Col md = "8"></Col>
                                                      <Col md = "2"><h6><b>Less Special Discount: </b></h6></Col>
                                                      <Col md = "2">
                                                      <FormGroup>
                                                          <Input
                                                            name="lessSpecialDiscount"
                                                            placeholder="Less Special Discount"
                                                            type="number"
                                                            className="form-control"
                                                            id="lessSpecialDiscount"
                                                            value = {this.state.lessSpecialDiscount}
                                                            onChange = {this.lessSpecialDiscountOnChange.bind(this)}
                                                          />
                                                      </FormGroup>
                                                      </Col>
                                                  </Row>

                                                  <br />

                                                  <Row>
                                                      <Col md = "8"></Col>
                                                      <Col md = "2"><h6><b>Less Discount Amount: </b></h6></Col>
                                                      <Col md = "2">
                                                      <FormGroup>
                                                          <Input
                                                            name="lessDiscountAmount"
                                                            placeholder="Less Discount Amount"
                                                            type="number"
                                                            className="form-control"
                                                            id="lessDiscountAmount"
                                                            value = {this.state.lessDiscountAmount}
                                                            onChange = {this.lessDiscountAmountOnChange.bind(this)}
                                                          />
                                                      </FormGroup>
                                                      </Col>
                                                  </Row>

                                                  <br />
                                                <Row>
                                                    <Col md = "8"></Col>
                                                    <Col md = "2"><h6><b>Total: </b></h6></Col>
                                                    <Col md = "2"><h6><b>{this.state.grandTotal}</b></h6></Col>
                                                </Row>

                                                <br />

                                                <Row>
                                                      <Col md = "8"></Col>
                                                      <Col md = "2"><h6><b>GST: </b></h6></Col>
                                                      <Col md = "2">
                                                      <FormGroup>
                                                          <Input
                                                            name="gst"
                                                            placeholder="GST"
                                                            type="number"
                                                            className="form-control"
                                                            id="gst"
                                                            value = {this.state.gst}
                                                            onChange = {this.gstOnChange.bind(this)}
                                                          />
                                                      </FormGroup>
                                                      </Col>
                                                  </Row>

                                                  <br />
                                                <Row>
                                                    <Col md = "8"></Col>
                                                    <Col md = "2"><h6><b>Grand Total: </b></h6></Col>
                                                    <Col md = "2"><h6><b>{this.state.finalTotal}</b></h6></Col>
                                                </Row>

                                            {/* </Col> */}
                                            {/* </Row> */}
                                            <br />

                                            <Row>

                                            <Col md="4">
                                                        <FormGroup>
                                                            <Label htmlFor="validationCustom05">Due Date<span style = {{color: "red"}}>*</span></Label>
                                                            <Input type="select" id = "dueDate" name = "dueDate" value = {this.state.dueDate} onChange = {this.handleChange.bind(this)}>
                                                                <option value = "Select">Select Due Date</option>
                                                                <option value = "7">7</option>
                                                                <option value = "15">15</option>
                                                                <option value = "30">30</option>
                                                                <option value = "35">35</option>
                                                                <option value = "60">60</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>


                                         
                                                    <Col md = "4">
                                              <FormGroup>
                                                      <Label htmlFor="validationCustom05">Sales Man<span style = {{color: "red"}}>*</span></Label>
                                                      <Input type="select" id = "salesMan" name = "salesMan" value = {this.state.salesMan} onChange = {this.salesManOnChange.bind(this)}>
                                                        <option value = "Select">Select Sales Man</option>
                                                        {this.state.deliveryPartners.map((city, index) => {
                                                            return (
                                                               <option key = {index} value={city.MobileNumber}>{city.SalesName +"-" + city.MobileNumber}</option>
                                                            );
                                                        })};
                                                    </Input>
                                                </FormGroup>
                                            </Col>           

                                            {/* <Col md="4">
                                                <FormGroup>
                                                    <Label htmlFor="validationCustom03">Discount</Label>
                                                    <Input
                                                    name="discount"
                                                    placeholder="Discount"
                                                    type="number"
                                                    className="form-control"
                                                    id="discount"
                                                    value = {this.state.discount}
                                                    onChange = {this.discountOnChange.bind(this)}
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                            </Row>
                                            <br/>
                                        <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "1"
													})}
													onClick={() => {
														this.toggleCustomJustified("1");
													}}
												>
													<span className="d-none d-sm-block">Pending Orders</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "2"
													})}
													onClick={() => {
														this.toggleCustomJustified("2");
													}}
												>
													<span className="d-none d-sm-block">Accepted Orders</span>
												</NavLink>
											</NavItem>
											{/* <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "3"
													})}
													onClick={() => {
														this.toggleCustomJustified("3");
													}}
												>
													<span className="d-none d-sm-block">Dispatched Orders</span>
												</NavLink>
											</NavItem> */}
                                            {/* <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "4"
													})}
													onClick={() => {
														this.toggleCustomJustified("4");
													}}
												>
													<span className="d-none d-sm-block">Delivered Orders</span>
												</NavLink>
											</NavItem> */}
										</Nav>

										<TabContent activeTab={this.state.activeTabJustify}>
                                        <TabPane tabId="1" className="p-3">
												<Row>
													<Col sm="12">
														<CardText>
                                                        <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Custumer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Delivery Address</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.pendingOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.fetchCart.bind(this, rowData)} type="submit">Edit</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Payment}</td>
                                                                        <td>{rowData.Address}</td>
                                                                        <td><Button color="primary" onClick = {this.moveToInvoice.bind(this, rowData)} type="submit">Prepare Invoice</Button><br /><br />
                                                                        <Button color="success" onClick = {this.acceptOrder.bind(this, rowData)} type="submit">Accept</Button></td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                          								</CardText>
													</Col>
												</Row>
											</TabPane>
											<TabPane tabId="2" className="p-3">
												<Row>
													<Col sm="12">
                                                    <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Custumer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Delivery Address</th>
                                                                    <th>Delivery Partner</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.acceptedOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.viewModal2.bind(this, rowData)} type="submit">View</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Payment}</td>
                                                                        <td>{rowData.Address}</td>
                                                                        <td>{rowData.DeliveryPartnerName} <br />
                                                                        <Input type="select" id = "deliveryNumber" name = "deliveryNumber" onChange={(event) => this.salesOnChange(event,index)}>
                                                                            <option value = "">Select Sales man</option>
                                                                            {this.state.deliveryPartners.map((city, index) => {
                                                                                return (
                                                                                    <option key = {index} value={city.MobileNumber}>{city.SalesName +"-" + city.MobileNumber}</option>
                                                                                );
                                                                            })};
                                                                        </Input>
                                                                        </td>
                                                                        <td><Button color="primary" onClick = {this.moveToInvoice.bind(this, rowData)} type="submit">Invoice</Button><br /><br />
                                                                            <Button color="success" onClick = {this.assignDeliveryAgent.bind(this, rowData, index)} type="submit">Assign</Button></td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
													</Col>
												</Row>
											</TabPane>
										</TabContent>

                                        <Modal isOpen={this.state.itemOpen} toggle={this.closeModal2.bind(this)}>
                                            <ModalHeader toggle={this.closeModal2.bind(this)}><h3>Items</h3></ModalHeader>
                                                <ModalBody>
                                                <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                            <tr>
                                                                    <th>Image</th>
                                                                    <th>Product Id</th>
                                                                    <th>Name</th>
                                                                    <th>Category</th>
                                                                    <th>Units</th>
                                                                    <th>Qty</th>
                                                                    <th>Price</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.cart1.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td><img src= {rowData.Image} alt = "Item" className="img-responsive inline-block" width="50" height="50" /></td>
                                                                        <td>{rowData.CustomerProductId}</td>
                                                                        <td>{rowData.Name}</td>
                                                                        <td>{rowData.Category}</td>
                                                                        <td>{rowData.Units}</td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Price}</td>
                                                                        <td>{rowData.Total}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                                                </ModalBody>
                                            </Modal>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default CreateVendorInvoice;