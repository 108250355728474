import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Container, Input, Form, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../assets/images/Shyamlogo.png'


class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      success_msg: false,
      employeeName: '',
      mobileNumber: '',
      email: '',
      address: '',
      password: '',
      userid: ''
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Employee")
      .on('value', (snapshot) => {
        if (snapshot.exists()) {
          const newData = []
          snapshot.forEach(function (doc) {
            newData.push(doc.val())
          })
          this.setState({
            firebaseData: newData
          })
        }
      })
  }


  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state
    let comp = this


    if (data.employeeName === '') {
      alert("Enter Employee Name");
      document.getElementById("employeeName").focus();
      return;
    }

    if (data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if (data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if (data.email === '') {
      alert("Enter Email");
      document.getElementById("email").focus();
      return;
    }

    if (data.address === '') {
      alert("Enter Address");
      document.getElementById("address").focus();
      return;
    }

    if (data.password === '') {
      alert("Enter Password");
      document.getElementById("password").focus();
      return;
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    today = yyyy + '-' + mm + '-' + dd;


    var tot = 0;

    var stock = firebase.database().ref().child("EmployeeId");
    stock.transaction(function (currentstock) {
      tot = currentstock + 1;
      return tot;
    },
      function (error, committed, snapshot) {
        if (error) {
          console.log('Transaction failed abnormally!', error);
        } else if (committed) {


          var userid = "SHRIEMP" + tot;


          var firebaseref = firebase.database().ref().child("Employee").child(userid)
          firebaseref.child("EmployeeName").set(data.employeeName)
          firebaseref.child("MobileNumber").set(data.mobileNumber)
          firebaseref.child("Email").set(data.email)
          firebaseref.child("Address").set(data.address)
          firebaseref.child("Password").set(data.password)
          firebaseref.child("UserId").set(userid)
          firebaseref.child("Created").set(today)

          comp.setState({
            success_msg: true,
            employeeName: '',
            email: '',
            mobileNumber: '',
            address: '',
            password: ''
          })
        }
      })
  }



  sweetAlertOnConfirm() {
    let data = this.state
    this.setState({
      success_msg: false,
      employeeName: '',
      email: '',
      mobileNumber: '',
      address: '',
      password: ''
    })
  }

  editRow(key) {

    document.getElementById("submit").style.display = "none";
    document.getElementById("update").style.display = "block";

    this.setState({
      success_msg: false,
      employeeName: key.EmployeeName,
      email: key.Email,
      mobileNumber: key.MobileNumber,
      address: key.Address,
      password: key.Password,
      userid: key.UserId
    })
    window.scrollTo('top', 0)

  }

  delete(key) {
    if (window.confirm('Are you sure you want to delete the employee?')) {
      firebase.database().ref().child('Employee').child(key.UserId).remove()
      alert("Successfully Deleted!")
    } else { }
  }

  handleUpdate(e) {
    e.preventDefault();

    let data = this.state
    let comp = this


    if (data.employeeName === '') {
      alert("Enter Employee Name");
      document.getElementById("employeeName").focus();
      return;
    }

    if (data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if (data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if (data.email === '') {
      alert("Enter Email");
      document.getElementById("email").focus();
      return;
    }

    if (data.address === '') {
      alert("Enter Address");
      document.getElementById("address").focus();
      return;
    }

    if (data.password === '') {
      alert("Enter Password");
      document.getElementById("password").focus();
      return;
    }


    var firebaseref = firebase.database().ref().child("Employee").child(data.userid)
    firebaseref.child("EmployeeName").set(data.employeeName)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("Email").set(data.email)
    firebaseref.child("Address").set(data.address)
    firebaseref.child("Password").set(data.password)

    alert("Updated Successfully")


    comp.setState({
      employeeName: '',
      email: '',
      mobileNumber: '',
      address: '',
      password: '',
      userid: ''
    })

    document.getElementById("submit").style.display = "block";
    document.getElementById("update").style.display = "none";
  }

  myFunction() {
    var input, filter, table, tr, td1, td2, td3, td4, td5, td6, td7, td8, td9, td10, td11, td12;
    var i, txtValue1, txtValue2, txtValue3, txtValue4, txtValue5, txtValue6, txtValue7, txtValue8, txtValue9, txtValue10, txtValue11, txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td1 = tr[i].getElementsByTagName("td")[1];
      td2 = tr[i].getElementsByTagName("td")[2];
      td3 = tr[i].getElementsByTagName("td")[3];
      td4 = tr[i].getElementsByTagName("td")[4];
      td5 = tr[i].getElementsByTagName("td")[5];
      td6 = tr[i].getElementsByTagName("td")[6];
      td7 = tr[i].getElementsByTagName("td")[7];
      // td8 = tr[i].getElementsByTagName("td")[8];
      // td9 = tr[i].getElementsByTagName("td")[9];

      if (td1) {
        txtValue1 = td1.textContent || td1.innerText;
        txtValue2 = td2.textContent || td2.innerText;
        txtValue3 = td3.textContent || td3.innerText;
        txtValue4 = td4.textContent || td4.innerText;
        txtValue5 = td5.textContent || td5.innerText;
        txtValue6 = td6.textContent || td6.innerText;
        txtValue7 = td7.textContent || td7.innerText;
        // txtValue8 = td8.textContent || td8.innerText;
        // txtValue9 = td9.textContent || td9.innerText;

        var main = txtValue1 + txtValue2 + txtValue3 + txtValue4 + txtValue5 + txtValue6 + txtValue7;
        if (main.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Master" breadcrumbItem="Add Employee" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Form id="create-form">
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="validationCustom03">Name<span style={{ color: "red" }}>*</span></Label>
                            <Input
                              name="employeeName"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="employeeName"
                              value={this.state.employeeName}
                              onChange={this.handleChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={{ size: 4, offset: 2 }}>
                          <FormGroup>
                            <Label htmlFor="validationCustom03">Mobile Number<span style={{ color: "red" }}>*</span></Label>
                            <Input
                              name="mobileNumber"
                              placeholder="Mobile Number"
                              type="number"
                              className="form-control"
                              id="mobileNumber"
                              value={this.state.mobileNumber}
                              onChange={this.handleChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={{ size: 4 }}>
                          <FormGroup>
                            <Label htmlFor="validationCustom03">Email<span style={{ color: "red" }}>*</span></Label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="text"
                              className="form-control"
                              id="email"
                              value={this.state.email}
                              onChange={this.handleChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={{ size: 4, offset: 2 }}>
                          <FormGroup>
                            <Label htmlFor="validationCustom03">Address<span style={{ color: "red" }}>*</span></Label>
                            <Input
                              name="address"
                              placeholder="Address"
                              type="text"
                              className="form-control"
                              id="address"
                              value={this.state.address}
                              onChange={this.handleChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup>
                            <Label htmlFor="validationCustom03">Password<span style={{ color: "red" }}>*</span></Label>
                            <Input
                              name="password"
                              placeholder="Password"
                              type="password"
                              className="form-control"
                              id="password"
                              value={this.state.password}
                              onChange={this.handleChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button color="primary" id="submit" onClick={this.handleSubmit.bind(this)} type="submit">Submit</Button>
                      <Button color="primary" id="update" style={{ display: "none" }} onClick={this.handleUpdate.bind(this)} type="submit">Update</Button>
                      {this.state.success_msg ? (
                        <SweetAlert
                          title="Good job!"
                          success
                          confirmBtnBsStyle="success"
                          onConfirm={this.sweetAlertOnConfirm.bind(this)}
                        >
                          Employee Successfully Created!
                        </SweetAlert>
                      ) : null}

                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>

                    {/* <Row>
                                            <Col md = "6">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                        </Row>
                                        <br /> */}

                    <div className="table-responsive" data-pattern="priority-columns">
                      <Table className="table mb-0 table-striped table-bordered" id="dataTable">
                        <thead>
                          <tr>
                            <th>Sl.No</th>
                            <th>Employee Name</th>
                            <th>Mobile Number</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Details</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.firebaseData.map((rowData, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{rowData.EmployeeName}</td>
                              <td>{rowData.MobileNumber}</td>
                              <td>{rowData.Email}</td>
                              <td>{rowData.Address}</td>
                              <td onClick={this.editRow.bind(this, rowData)}><i style={{ color: "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer" }} className="fas fa-edit"></i></td>
                              <td onClick={this.delete.bind(this, rowData)}><i style={{ color: "red", fontSize: "15px", padding: "10px", cursor: "pointer" }} className="fas fa-trash"></i></td>
                            </tr>
                          ))}

                        </tbody>
                      </Table>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddEmployee;