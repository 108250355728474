import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      success_msg: false,
      name: '',
      empImage: '',
      mobileNumber: '',
      address: '',
      aadharNumber: '',
      pins: [],
      outStandingAmount: '',
      pushId: ''
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Employee")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            newData.push(doc.val())
        })
        this.setState({
          firebaseData: newData
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.name === '') {
        alert("Enter Employee Name");
        document.getElementById("name").focus();
        return;
    }

    if(data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.address === '') {
        alert("Enter Address");
        document.getElementById("address").focus();
        return;
    }

    if(data.aadharNumber === '') {
        alert("Enter Aadhar Number");
        document.getElementById("aadharNumber").focus();
        return;
      }
  
      if(data.aadharNumber.length !== 12) {
        alert("Enter Correct Aadhar Number");
        document.getElementById("aadharNumber").focus();
        return;
      }

       
    var tot=0;
    
    var stock=firebase.database().ref().child("EmployeeId");              
    stock.transaction(function(currentstock) {
     tot = currentstock+1;   
    return tot;
    },
    function(error, committed, snapshot) {
    if (error) {
      console.log('Transaction failed abnormally!', error);
    } else if (committed) {


          var userid = "STREMP" +tot;
      

    var firebaseref = firebase.database().ref().child("Employee").child(userid)
    firebaseref.child("Name").set(data.name)
    firebaseref.child("Image").set(data.empImage)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("Address").set(data.address)
    firebaseref.child("AadharNumber").set(data.aadharNumber)
    firebaseref.child("Balances").set(parseInt(0))
    firebaseref.child("UserId").set(userid)

    comp.setState({
      success_msg: true
    })


    data.name = ""
    data.empImage = ""
    data.mobileNumber = ""
    data.address = ""
    data.aadharNumber = ""
    document.getElementById("create-form").reset()
  }
})
}

empUpload = e =>{
        
  if(e.target.files[0] === 0){
     alert("Add Employee Image");
     return;
 }

 const ref = firebase.storage().ref("/Employee/");
 const file = e.target.files[0];
 const name = e.target.files[0] + Date();
 const metadata = {
 contentType: file.type
 };
 const task = ref.child(name).put(file, metadata);
 task
 .then(snapshot => snapshot.ref.getDownloadURL())
 .then((url) => {
   this.setState({
     empImage: url
   })
 })
 .catch(console.error);         
}

sweetAlertOnConfirm(){
  let data = this.state
  this.setState({
      success_msg: false
  })
 
  data.name = ""
  data.empImage = ""
  data.mobileNumber = ""
  data.address = ""
  data.aadharNumber = ""
  document.getElementById("create-form").reset()
}

updateRow(e) {
    e.preventDefault();
  
    let data = this.state
  
    let comp = this
  
    if(data.name === '') {
        alert("Enter Employee Name");
        document.getElementById("name").focus();
        return;
    }

    if(data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.address === '') {
        alert("Enter Address");
        document.getElementById("address").focus();
        return;
    }

    if(data.aadharNumber === '') {
        alert("Enter Aadhar Number");
        document.getElementById("aadharNumber").focus();
        return;
      }
  
      if(data.aadharNumber.length !== 12) {
        alert("Enter Correct Aadhar Number");
        document.getElementById("aadharNumber").focus();
        return;
      }
      

    var firebaseref = firebase.database().ref().child("Employee").child(data.pushId)
    firebaseref.child("Name").set(data.name)
    firebaseref.child("Image").set(data.empImage)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("Address").set(data.address)
    firebaseref.child("AadharNumber").set(data.aadharNumber)

    comp.setState({
      update_msg: true
    })


    data.name = ""
    data.empImage = ""
    data.mobileNumber = ""
    data.address = ""
    data.aadharNumber = ""
    document.getElementById("create-form").reset()
  }
  
  editRow(key) {
    let data = this.state
    document.getElementById("submit").style.display = "none"
    document.getElementById("update").style.display = "block"
  
    data.name = key.Name
    data.empImage = key.Image
    data.mobileNumber = key.MobileNumber
    data.aadress = key.Address
    data.aadharNumber = key.AadharNumber
    data.empImage = key.Image
    data.pushId = key.UserId
  
    document.getElementById("name").value = key.Name
    document.getElementById("address").value = key.Address
    document.getElementById("mobileNumber").value = key.MobileNumber
    document.getElementById("aadharNumber").value = key.AadharNumber
  
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Master" breadcrumbItem="Employee" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="name"
                                                          placeholder="Employee Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="name"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.name}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Image</Label>
                                                        <Input
                                                          name="empImage"
                                                          type="file"
                                                          className="form-control"
                                                          id="empImage"
                                                          onChange = {this.empUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mobileNumber"
                                                          placeholder="Mobile Number"
                                                          type="number"
                                                          className="form-control"
                                                          id="mobileNumber"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.mobileNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Address<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="address"
                                                          placeholder="Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="address"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.address}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Aadhar Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="aadharNumber"
                                                          placeholder="Aadhar Number"
                                                          type="number"
                                                          className="form-control"
                                                          id="aadharNumber"
                                                          onChange = {this.handleChange.bind(this)}
                                                          value = {this.state.aadharNumber}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                             </Row>
                                             <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                             <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={this.sweetAlertOnConfirm.bind(this)}
                                                  >
                                                    Employee Successfully Created!
                                                  </SweetAlert>
                                                ) : null}

                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  Employee Updated Successfully!
                                                </SweetAlert>
                                              ) : null}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Image</th>
                                                            <th>Name</th>
                                                            <th>Address</th>
                                                            <th>Mobile Number</th>
                                                            <th>Aadhar Number</th>
                                                            <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td><a href = {rowData.Image}><img src = {rowData.Image} height = "70" width = "70"></img></a></td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Address}</td>
                                                        <td>{rowData.MobileNumber}</td>
                                                        <td>{rowData.AadharNumber}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Employee;