import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import Select from "react-select";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../assets/images/Shyamlogo.png'


class SalesAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesName: '',
      mobileNumber: '',
      email: '',
      address: '',
      firebaseData: [],
      success_msg: false,
      selectedMulti: [],
      pincodes: [],
      pushId: '',
      password: '',
      image: ''
    };
  }

  componentDidMount() {
    // firebase.database().ref().child("Masters").child("Pincode")
    // .on('value',(snapshot) => {
    //   if(snapshot.exists()) {
    //     const newData = []
    //     snapshot.forEach(function(doc) {
    //         const dict = {
    //             options: [ {
    //             label: doc.val().Pincode,
    //             value: doc.val().Pincode
    //             }]
    //         }
    //         newData.push(dict)
    //     })
    //     this.setState({
    //       pincodes: newData
    //     })
    //   }
    // })

    firebase.database().ref().child("Masters").child("SalesAgent")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
          if(doc.val().MobileNumber !== undefined) {
            const dict = {
              SalesName: doc.val().SalesName,
              MobileNumber: doc.val().MobileNumber,
              Pincodes: doc.val().Pincodes,
              Email: doc.val().Email,
              Address: doc.val().Address,
              Password: doc.val().Password,
              Image: doc.val().Image
            }
            newData.push(dict)
          }
        })
        this.setState({
          firebaseData: newData
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    if(data.salesName === '') {
      alert("Enter Sales Man Name");
      document.getElementById("salesName").focus();
      return;
    }

    if(data.mobileNumber === '') {
        alert("Enter Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.image === '') {
      alert("Select Image");
      document.getElementById("image").focus();
      return;
    }

    if(data.address === '') {
      alert("Enter Address");
      document.getElementById("address").focus();
      return;
    }

    // if(data.selectedMulti.length === 0) {
    //     alert("Select Areas");
    //     document.getElementById("assignPincode").focus();
    //     return;
    // }

    if(data.password === '') {
      alert("Enter Password");
      document.getElementById("password").focus();
      return;
  }

    var firebaseref = firebase.database().ref().child("Masters").child("SalesAgent").child(data.mobileNumber)
    firebaseref.child("SalesName").set(data.salesName)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("Email").set("")
    firebaseref.child("Address").set(data.address)
    firebaseref.child("Password").set(data.password)
    firebaseref.child("Image").set(data.image)

    var pin = ""

    for (let i =0; i< data.selectedMulti.length; i++) {
        pin = pin + data.selectedMulti[i].value + ","
    }

    firebaseref.child("Pincodes").set("")

    comp.setState({
      success_msg: true
    })

    data.salesName = ""
    data.mobileNumber = ""
    data.email = ""
    data.address = ""
    data.selectedMulti = []
    data.password = ''
    data.image = ''
    document.getElementById("create-form").reset()
}

handleMulti = selectedMulti => {
  this.setState({ selectedMulti });
};

editRow(key) {
  let data = this.state
  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  data.salesName = key.SalesName
  data.mobileNumber = key.MobileNumber
  data.image = key.Image
  data.address = key.Address
  data.password = key.Password

  let pinc = key.Pincodes.split(',')
  let a = []
  for (let i = 0; i< pinc.length - 1; i++) {
      a.push({
          label: pinc[i],
          value: pinc[i]
        })
  }
  this.setState({
        selectedMulti: a
    })

  document.getElementById("salesName").value = key.SalesName
  document.getElementById("mobileNumber").value = key.MobileNumber
  // document.getElementById("email").value = key.Email
  document.getElementById("address").value = key.Address
  document.getElementById("password").value = key.Password
  document.getElementById("mobileNumber").disabled = true
}

delete(key) {
  if (window.confirm('Are you sure you want to delete the sales man?')) {
      firebase.database().ref().child("Masters").child('SalesAgent').child(key.MobileNumber).remove()
      alert("Successfully Deleted!")
    } else {}
}

updateRow(e) {
  e.preventDefault();

  let data = this.state

  let comp = this

  if(data.salesName === '') {
    alert("Enter Sales Man Name");
    document.getElementById("salesName").focus();
    return;
  }

  if(data.image === '' || data.image === undefined) {
    alert("Select Image");
    document.getElementById("image").focus();
    return;
  }

  if(data.address === '') {
    alert("Enter Address");
    document.getElementById("address").focus();
    return;
  }

  // if(data.selectedMulti.length === 0) {
  //     alert("Select Areas");
  //     document.getElementById("assignPincode").focus();
  //     return;
  // }

  if(data.password === '' || data.password === undefined) {
    alert("Enter Password")
    document.getElementById("password").focus()
    return
  }

  var firebaseref = firebase.database().ref().child("Masters").child("SalesAgent").child(data.mobileNumber)
  firebaseref.child("SalesName").set(data.salesName)
  firebaseref.child("MobileNumber").set(data.mobileNumber)
  firebaseref.child("Email").set("")
  firebaseref.child("Address").set(data.address)
  firebaseref.child("Password").set(data.password)
  firebaseref.child("Image").set(data.image)

  var pin = ""

  for (let i =0; i< data.selectedMulti.length; i++) {
      pin = pin + data.selectedMulti[i].value + ","
  }

  firebaseref.child("Pincodes").set("")

  comp.setState({
    update_msg: true
  })

  data.salesName = ""
  data.mobileNumber = ""
  data.email = ""
  data.address = ""
  data.selectedMulti = []
  data.password = ''
  data.image = ''
  document.getElementById("create-form").reset()
  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"
}


salesManPhotoUpload = e =>{
        
  if(e.target.files[0] === 0){
     alert("Add Image");
     return;
 }

 const ref = firebase.storage().ref("/SalesMan/");
 const file = e.target.files[0];
 const name = e.target.files[0] + Date();
 const metadata = {
 contentType: file.type
 };
 const task = ref.child(name).put(file, metadata);
 task
 .then(snapshot => snapshot.ref.getDownloadURL())
 .then((url) => {
   this.setState({
     image: url
   })
 })
 .catch(console.error);         
}

myFunction() {
  var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
  var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
  input = document.getElementById("search");
  filter = input.value.toUpperCase();
  table = document.getElementById("dataTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
  td1 = tr[i].getElementsByTagName("td")[1];
  td2 = tr[i].getElementsByTagName("td")[2];
  td3 = tr[i].getElementsByTagName("td")[3];
  td4 = tr[i].getElementsByTagName("td")[4];
  td5 = tr[i].getElementsByTagName("td")[5];
  // td9 = tr[i].getElementsByTagName("td")[9];

  if (td1) {
    txtValue1 = td1.textContent || td1.innerText;
    txtValue2 = td2.textContent || td2.innerText;
    txtValue3 = td3.textContent || td3.innerText;
    txtValue4 = td4.textContent || td4.innerText;
    txtValue5 = td5.textContent || td5.innerText;
  
   var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5;
     if (main.toUpperCase().indexOf(filter) > -1) {
      tr[i].style.display = "";
    } else {
      tr[i].style.display = "none";
    }
  }       
}
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Sales Man" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="salesName"
                                                          placeholder="SalesMan Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="salesName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mobileNumber"
                                                          placeholder="Mobile Number"
                                                          type="number"
                                                          className="form-control"
                                                          id="mobileNumber"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Photo<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="image"
                                                          placeholder="image"
                                                          type="file"
                                                          className="form-control"
                                                          id="image"
                                                          onChange = {this.salesManPhotoUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Address<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="address"
                                                          placeholder="Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="address"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Password<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="password"
                                                          placeholder="Password"
                                                          type="text"
                                                          className="form-control"
                                                          id="password"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4" style = {{display: "none"}}> 
                                                <FormGroup className="select2-container">
                                                      <label className="control-label">Assign Area</label>
                                                      <Select
                                                        value = {this.state.selectedMulti}
                                                        isMulti={true}
                                                        onChange={this.handleMulti}
                                                        options={this.state.pincodes}
                                                        classNamePrefix="select1-selection"
                                                        id = "assignPincode"
                                                        placeholder = "Select Areas"
                                                      />
                                                  </FormGroup>
                                                </Col>
                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    SalesMan Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  SalesMan Updated Successfully!
                                                </SweetAlert>
                                              ) : null}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                    <Row>
                                            <Col md = "6">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                        </Row>
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered"  id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Photo</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Address</th>
                                                            <th>Password</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        {rowData.Image === '' || rowData.Image === undefined ?
                                                        <td><img src= {Logo} style = {{backgroundColor: "#2A3042"}} alt = "logo" className="img-responsive inline-block" width="100" height="100" /></td> :
                                                        <td><img src= {rowData.Image} alt = "salesman" className="img-responsive inline-block" width="100" height="100" /></td> }
                                                        <td>{rowData.SalesName}</td>
                                                        <td>{rowData.MobileNumber}</td>
                                                        <td>{rowData.Address}</td>
                                                        <td>{rowData.Password}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default SalesAgent;