import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, CardSubtitle,Container, Button, Input, Label, FormGroup, Form, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase';
import SweetAlert from "react-bootstrap-sweetalert";

class PromoCode extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            promocodeType: 'Discount',
            promoCodeName: '',
            discountPer: '',
            minAmount: '',
            maxAmount: '',
            status1: 'Select',
            discountSuccess:  false,
            updateDiscountSuccess: false,

            flatPromoCodeName: '',
            flatMinAmount: '',
            flatAmount: '',
            flatStatus1: 'Select',
            flatSuccess:  false,
            updateFlatSuccess: false,
            firebaseData: []
        };
    }

    componentDidMount() {
        if(this.state.promocodeType === "Discount") {
            document.getElementById("discount").checked = true
        }

        firebase.database().ref().child("Masters").child("PromoCode")
        .on('value',(snapshot) => {
          if(snapshot.exists()) {
            const newData = []
            snapshot.forEach(function(doc) {
                const dict = {
                  Name: doc.val().Name,
                  Discount: doc.val().Discount,
                  MinAmount: doc.val().MinAmount,
                  MaxAmount: doc.val().MaxAmount,
                  Category: doc.val().Category,
                  Status: doc.val().Status
                }
                newData.push(dict)
            })
            this.setState({
              firebaseData: newData
            })
          }
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
      }

      handleSubmit(e) {
        e.preventDefault();

        let data = this.state
        let comp = this

        if(data.promoCodeName === '') {
            alert("Enter Promo Code");
            document.getElementById("promoCodeName").focus();
            return;
        }

        if(data.discountPer === '') {
            alert("Enter Discount Percentage");
            document.getElementById("discountPer").focus();
            return;
        }

        if(data.minAmount === '') {
            alert("Enter Minimum Amount");
            document.getElementById("minAmount").focus();
            return;
        }

        if(data.maxAmount === '') {
            alert("Enter Maximum Amount");
            document.getElementById("maxAmount").focus();
            return;
        }

        if(data.status1 === 'Select') {
            alert("Select Status");
            document.getElementById("status1").focus();
            return;
        }

        var firebaseref = firebase.database().ref().child("Masters").child("PromoCode").child(data.promoCodeName)
        firebaseref.child("Discount").set(data.discountPer)
        firebaseref.child("MaxAmount").set(data.maxAmount)
        firebaseref.child("MinAmount").set(data.minAmount)
        firebaseref.child("Name").set(data.promoCodeName)
        firebaseref.child("Status").set(data.status1)
        firebaseref.child("Category").set("Discount")
    
        comp.setState({
          discountSuccess: true
        })

        data.discount = ""
        data.maxAmount = ""
        data.minAmount = ""
        data.promoCodeName = ""
        data.status1 = ""
        document.getElementById("create-form").reset()
    }
    
    updateRow(e) {
        e.preventDefault();

        let data = this.state
        let comp = this

        if(data.discountPer === '') {
            alert("Enter Discount Percentage");
            document.getElementById("discountPer").focus();
            return;
        }

        if(data.minAmount === '') {
            alert("Enter Minimum Amount");
            document.getElementById("minAmount").focus();
            return;
        }

        if(data.maxAmount === '') {
            alert("Enter Maximum Amount");
            document.getElementById("maxAmount").focus();
            return;
        }

        if(data.status1 === 'Select') {
            alert("Select Status");
            document.getElementById("status1").focus();
            return;
        }

        var firebaseref = firebase.database().ref().child("Masters").child("PromoCode").child(data.promoCodeName)
        firebaseref.child("Discount").set(data.discountPer)
        firebaseref.child("MaxAmount").set(data.maxAmount)
        firebaseref.child("MinAmount").set(data.minAmount)
        firebaseref.child("Name").set(data.promoCodeName)
        firebaseref.child("Status").set(data.status1)
        firebaseref.child("Category").set("Discount")
    
        comp.setState({
          updateDiscountSuccess: true
        })

        data.discount = ""
        data.maxAmount = ""
        data.minAmount = ""
        data.promoCodeName = ""
        data.status1 = ""
        document.getElementById("create-form").reset()

        document.getElementById("submit").style.display = "block"
        document.getElementById("update").style.display = "none"
    } 

    flatSubmit(e) {
        e.preventDefault();

        let data = this.state
        let comp = this

        if(data.flatPromoCodeName === '') {
            alert("Enter Flat Promo Code");
            document.getElementById("flatPromoCodeName").focus();
            return;
        }

        if(data.flatAmount === '') {
            alert("Enter Flat Amount");
            document.getElementById("flatAmount").focus();
            return;
        }

        if(data.flatMinAmount === '') {
            alert("Enter Minimum Amount");
            document.getElementById("flatMinAmount").focus();
            return;
        }

        if(data.flatStatus1 === 'Select') {
            alert("Select Status");
            document.getElementById("flatStatus1").focus();
            return;
        }

        var firebaseref = firebase.database().ref().child("Masters").child("PromoCode").child(data.flatPromoCodeName)
        firebaseref.child("Discount").set(data.flatAmount)
        firebaseref.child("MinAmount").set(data.flatMinAmount)
        firebaseref.child("Name").set(data.flatPromoCodeName)
        firebaseref.child("Status").set(data.flatStatus1)
        firebaseref.child("Category").set("Flat")
    
        comp.setState({
          flatSuccess: true
        })

        data.flatAmount = ""
        data.flatMinAmount = ""
        data.flatPromoCodeName = ""
        data.flatStatus1 = ""
        document.getElementById("create-form1").reset()
    }

    flatUpdate(e) {
        e.preventDefault();

        let data = this.state
        let comp = this

        if(data.flatAmount === '') {
            alert("Enter Flat Amount");
            document.getElementById("flatAmount").focus();
            return;
        }

        if(data.flatMinAmount === '') {
            alert("Enter Minimum Amount");
            document.getElementById("flatMinAmount").focus();
            return;
        }

        if(data.flatStatus1 === 'Select') {
            alert("Select Status");
            document.getElementById("flatStatus1").focus();
            return;
        }

        var firebaseref = firebase.database().ref().child("Masters").child("PromoCode").child(data.flatPromoCodeName)
        firebaseref.child("Discount").set(data.flatAmount)
        firebaseref.child("MinAmount").set(data.flatMinAmount)
        firebaseref.child("Name").set(data.flatPromoCodeName)
        firebaseref.child("Status").set(data.flatStatus1)
        firebaseref.child("Category").set("Flat")
    
        comp.setState({
          updateFlatSuccess: true
        })

        data.flatAmount = ""
        data.flatMinAmount = ""
        data.flatPromoCodeName = ""
        data.flatStatus1 = ""
        document.getElementById("create-form1").reset()

        document.getElementById("submit1").style.display = "block"
        document.getElementById("update1").style.display = "none"
    }
    
    editRow(key) {

        let data = this.state  
        data.pushid = key.PushId

        if(key.Category === "Discount") {

            data.promocodeType = "Discount"
            data.promoCodeName = key.Name
            data.discountPer = key.Discount
            data.minAmount = key.MinAmount
            data.maxAmount = key.MaxAmount
            data.status1 = key.Status

            this.forceUpdate()

            setTimeout(
                function() {
                    document.getElementById("submit").style.display = "none"
                    document.getElementById("update").style.display = "block"
                    document.getElementById("discount").checked = true
                    document.getElementById("promoCodeName").value = key.Name
                    document.getElementById("promoCodeName").disabled = true
                    document.getElementById("discountPer").value = key.Discount
                    document.getElementById("minAmount").value = key.MinAmount
                    document.getElementById("maxAmount").value = key.MaxAmount
                    document.getElementById("status1").value = key.Status
            }, 1000)
        } else if(key.Category === "Flat") {

            data.promocodeType = "Flat"
            data.flatPromoCodeName = key.Name
            data.flatAmount = key.Discount
            data.flatMinAmount = key.MinAmount
            data.flatStatus1 = key.Status

            this.forceUpdate()

            setTimeout(
                function() {
                    document.getElementById("submit1").style.display = "none"
                    document.getElementById("update1").style.display = "block"
                    document.getElementById("flat").checked = true
                    document.getElementById("flatPromoCodeName").value = key.Name
                    document.getElementById("flatPromoCodeName").disabled = true
                    document.getElementById("flatAmount").value = key.Discount
                    document.getElementById("flatMinAmount").value = key.MinAmount
                    document.getElementById("flatStatus1").value = key.Status
            }, 1000)
        }

    }

    delete(key) {
        if (window.confirm('Are you sure you want to delete the promo code?')) {
            firebase.database().ref().child("Masters").child('Promocode').child(key.Name).remove()
            alert("Successfully Deleted!")
          } else {}
      }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="PromoCode" breadcrumbItem="Add PromoCode" />
                <Row>
                    <Col>
                        <Card>
                        <CardBody>
                        <Row onChange = {this.handleChange.bind(this)}>
                            <div className="form-check mb-3" style = {{marginLeft: "10px"}}>
                                <input className="form-check-input" type="radio" name="promocodeType" id="discount" value="Discount" />
                                <label className="form-check-label" htmlFor="exampleRadios1">Discount</label>
                            </div>
                            <div className="form-check mb-3" style = {{marginLeft: "10px"}}>
                                <input className="form-check-input" type="radio" name="promocodeType" id="flat" value="Flat" />
                                <label className="form-check-label" htmlFor="exampleRadios1">Flat</label>
                            </div>
                        </Row>
                        <Form id = "create-form">

                        {this.state.promocodeType === "Discount"?
                        <div>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom03">PromoCode Name<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="promoCodeName"
                                      placeholder="PromoCode Name"
                                      type="text"
                                      className="form-control"
                                      id="promoCodeName"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md= {{size: 4, offset: 2}}>
                                <FormGroup>
                                    <Label htmlFor="validationCustom02">Discount Percentage(%)<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="discountPer"
                                      placeholder="Discount Percentage"
                                      type="number"
                                      className="form-control"
                                      id="discountPer"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom04">Minimum Order Amount<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="minAmount"
                                      placeholder="Min Amount"
                                      type="number"
                                      className="form-control"
                                      id="minAmount"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md= {{size: 4, offset: 2}}>
                                <FormGroup>
                                    <Label htmlFor="validationCustom04">Maximum Discount Amount<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="maxAmount"
                                      placeholder="Max Amount"
                                      type="number"
                                      className="form-control"
                                      id="maxAmount"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row>   
                        <Col md = "4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom05">Status<span style = {{color: "red"}}>*</span></Label>
                                    <Input type="select" id = "status1" name = "status1" onChange = {this.handleChange.bind(this)}>
                                    <option value = "Select Status">Select Status</option>
                                    <option value = "Active">Active</option>
                                    <option value = "InActive">InActive</option>
                                </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <br />


                        
                                               
                        <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                        <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.discountSuccess ? (
											<SweetAlert
												title="Great"
												success
												confirmBtnBsStyle="success"
												onConfirm={() => this.setState({ discountSuccess: false })}
											>
												Discount PromoCode Added Successfully!
											</SweetAlert>
										) : null}
                                        {this.state.updateDiscountSuccess ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ updateDiscountSuccess: false })}
                                                >
                                                  Discount PromoCode Updated Successfully!
                                                </SweetAlert>
                                              ) : null}
                        </div> : null } </Form>

                        <Form id = "create-form1">

                        {this.state.promocodeType === "Flat"?
                        <div>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom03">PromoCode Name<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="flatPromoCodeName"
                                      placeholder="PromoCode Name"
                                      type="text"
                                      className="form-control"
                                      id="flatPromoCodeName"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md= {{size: 4, offset: 2}}>
                                <FormGroup>
                                    <Label htmlFor="validationCustom02">Flat Amount<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="flatAmount"
                                      placeholder="Flat Amount"
                                      type="number"
                                      className="form-control"
                                      id="flatAmount"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom04">Minimum Order Amount<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="flatMinAmount"
                                      placeholder="Min Amount"
                                      type="number"
                                      className="form-control"
                                      id="flatMinAmount"
                                      onChange = {this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                             
                        <Col md= {{size: 4, offset: 2}}>
                                <FormGroup>
                                    <Label htmlFor="validationCustom05">Status<span style = {{color: "red"}}>*</span></Label>
                                    <Input type="select" id = "flatStatus1" name = "flatStatus1" onChange = {this.handleChange.bind(this)}>
                                    <option value = "Select Status">Select Status</option>
                                    <option value = "Active">Active</option>
                                    <option value = "InActive">InActive</option>
                                </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <br />
                        
                                               
                        <Button color="primary" onClick = {this.flatSubmit.bind(this)} id = "submit1" type="submit">Submit</Button>
                        <Button color="primary" onClick = {this.flatUpdate.bind(this)}  id = "update1" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.flatSuccess ? (
											<SweetAlert
												title="Great"
												success
												confirmBtnBsStyle="success"
												onConfirm={() => this.setState({ flatSuccess: false })}
											>
												Flat PromoCode Added Successfully!
											</SweetAlert>
										) : null}
                                        {this.state.updateFlatSuccess ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ updateFlatSuccess: false })}
                                                >
                                                  Flat PromoCode Updated Successfully!
                                                </SweetAlert>
                                              ) : null}
                        </div> : null } </Form>

                            </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>PromoCode Name</th>
                                                            <th>Category</th>
                                                            <th>Discount</th>
                                                            <th>MinAmount</th>
                                                            <th>Status</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Category}</td>
                                                        <td>{rowData.Discount}</td>
                                                        <td>{rowData.MinAmount}</td>
                                                        <td>{rowData.Status}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PromoCode;
