import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { faTrash, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from '../../assets/images/Shyamlogo.png'


class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      productName: '',
      categories: [],
      category: 'Select',
      codPrice: '',
      creditPrice: '',
      sizes: [],
      size: 'Select',
      stock: '',
      productImage: '',
      categoryName: '',
      sizeName: '',
      pushId: '',
      mrpPrice: '',
      weights: [{}],
      weights1: [{}],
      size1: 'Select',
      sizeName1: '',
      stock1: '',
      mrpPrice1: '',
      codPrice1: '',
      creditPrice1: '',
      productId: ''
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Masters").child("Category")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                CategoryName: doc.val().CategoryName,
                PushId: doc.val().PushId
            }
            newData.push(dict)
        })
        this.setState({
          categories: newData
        })
      }
    })

    firebase.database().ref().child("Masters").child("Size")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                SizeName: doc.val().SizeName,
                PushId: doc.val().PushId
            }
            newData.push(dict)
        })
        this.setState({
          sizes: newData
        })
      }
    })

    firebase.database().ref().child("Products")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                Name: doc.val().Name,
                Category: doc.val().Category,
                CODPrice: doc.val().CODPrice,
                CreditPrice: doc.val().CreditPrice,
                Size: doc.val().Size,
                Stock: doc.val().Stock,
                Image: doc.val().Image,
                PushId: doc.val().PushId,
                CategoryPushId: doc.val().CategoryPushId,
                SizePushId: doc.val().SizePushId,
                CustomerProductId: doc.val().CustomerProductId
            }
            newData.push(dict)
        })
        this.setState({
          firebaseData: newData
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    if(data.productId === '') {
      alert("Enter Product Id");
      document.getElementById("productId").focus();
      return;
    }


    if(data.productName === '') {
        alert("Enter Product Name");
        document.getElementById("productName").focus();
        return;
    }

    if(data.category === 'Select') {
        alert("Select Group");
        document.getElementById("category").focus();
        return;
    }

    // if(data.productImage === '') {
    //   alert("Select Product Image");
    //   document.getElementById("productImage").focus();
    //   return;
    // }

    var myTab = document.getElementById('datatable');
    if(myTab.rows.length === 0){
        alert("Add Size and Price");
        return;
    }

    var firebaseref = firebase.database().ref().child("Products").push()
    firebaseref.child("CustomerProductId").set(data.productId)
    firebaseref.child("Name").set(data.productName)
    firebaseref.child("CategoryPushId").set(data.category)
    firebaseref.child("Category").set(data.categoryName)
    // firebaseref.child("CODPrice").set(data.codPrice)
    // firebaseref.child("CreditPrice").set(data.creditPrice)
    // firebaseref.child("SizePushId").set(data.size)
    // firebaseref.child("Size").set(data.sizeName)
    // firebaseref.child("Stock").set(parseFloat(data.stock))
    firebaseref.child("Image").set(data.productImage)
    firebaseref.child("PushId").set(firebaseref.getKey())
    firebaseref.child("Status").set("Active")

    for (let i = 0; i < myTab.rows.length; i++) {
      var objCells = myTab.rows.item(i).cells;
          var firebaseref1 = firebaseref.child("Sizes").push();
          firebaseref1.child("PushId").set(firebaseref1.getKey());
          firebaseref1.child("SizePushId").set(objCells.item(1).innerHTML)
          firebaseref1.child("Size").set(objCells.item(0).innerHTML)
          firebaseref1.child("MRPPrice").set(objCells.item(2).innerHTML);
          firebaseref1.child("CODPrice").set(objCells.item(3).innerHTML);
          firebaseref1.child("CreditPrice").set(objCells.item(4).innerHTML);
          firebaseref1.child("Stock").set(parseInt(objCells.item(4).innerHTML));
          firebaseref1.child("Status").set("Active");
    }



    comp.setState({
      success_msg: true
    })

    var contacts = [...this.state.weights];
    contacts.splice(1, this.state.weights.length);
    comp.setState({
      weights: contacts
    });


    data.productId = ""
    data.productName = ""
    data.category = "Select"
    data.codPrice = ""
    data.creditPrice = ""
    data.size = "Select"
    data.stock = ""
    data.productImage = ""
    document.getElementById("create-form").reset()
}

categoryOnChange(e) {
  this.setState({
      [e.target.name]: e.target.value
  })

  firebase.database().ref().child("Masters").child("Category").child(e.target.value).child("CategoryName")
  .once("value",(snapshot) => {
      this.setState({
        categoryName:snapshot.val()
    })
  })
}

sizeOnChange(e) {
  this.setState({
      [e.target.name]: e.target.value
  })

  firebase.database().ref().child("Masters").child("Size").child(e.target.value).child("SizeName")
  .once("value",(snapshot) => {
      this.setState({
        sizeName:snapshot.val()
    })
  })
}

sizeOnChange1(e) {
  this.setState({
      [e.target.name]: e.target.value
  })

  firebase.database().ref().child("Masters").child("Size").child(e.target.value).child("SizeName")
  .once("value",(snapshot) => {
      this.setState({
        sizeName1:snapshot.val()
    })
  })
}

productUpload = e =>{
        
  if(e.target.files[0] === 0){
     alert("Add Product Image");
     return;
 }

 const ref = firebase.storage().ref("/Products/");
 const file = e.target.files[0];
 const name = e.target.files[0] + Date();
 const metadata = {
 contentType: file.type
 };
 const task = ref.child(name).put(file, metadata);
 task
 .then(snapshot => snapshot.ref.getDownloadURL())
 .then((url) => {
   this.setState({
     productImage: url
   })
 })
 .catch(console.error);         
}

editRow(key) {
  let data = this.state

  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  if(key.CustomerProductId === undefined) {
    data.productId = ""
    document.getElementById("productId").value = ""
  } else {
    data.productId = key.CustomerProductId
    document.getElementById("productId").value = key.CustomerProductId
  }

  data.productName = key.Name
  data.pushId = key.PushId
  data.category = key.CategoryPushId
  data.productImage = key.Image
  data.categoryName = key.Category

  document.getElementById("productName").value = key.Name
  document.getElementById("category").value = key.CategoryPushId

  document.getElementById("sizeUpdate").style.display = "block"
  document.getElementById("sizeRead").style.display = "none"

    


  
    const ref1 = firebase.database().ref().child("Products").child(key.PushId).child("Sizes");
      ref1.on('value',(snapshot) => {
          let newState1 = [];
          snapshot.forEach(childSnapShot => {
                  const items = {
                      CODPrice:childSnapShot.val().CODPrice,
                      CreditPrice: childSnapShot.val().CreditPrice,
                      MRPPrice: childSnapShot.val().MRPPrice,
                      PushId: childSnapShot.val().PushId,
                      Status: childSnapShot.val().Status,
                      Size: childSnapShot.val().Size,
                      SizePushId: childSnapShot.val().SizePushId,
                      Stock: childSnapShot.val().Stock
                  };
                  newState1.push(items)
          });
    
          this.setState({
            weights1: newState1
          });
      });

  window.scrollTo("top", 0)

  }

delete(key) {
  if (window.confirm('Are you sure you want to delete the product?')) {
      firebase.database().ref().child('Products').child(key.PushId).remove()
      alert("Successfully Deleted!")
    } else {}
}

updateRow(e) {
  e.preventDefault();


  let data = this.state

  let comp = this

  if(data.productId === '') {
    alert("Enter Product Id");
    document.getElementById("productId").focus();
    return;
  }

  if(data.productName === '') {
    alert("Enter Product Name");
    document.getElementById("productName").focus();
    return;
  }

  if(data.category === 'Select') {
      alert("Select Group");
      document.getElementById("category").focus();
      return;
  }

  // if(data.productImage === '') {
  //   alert("Select Product Image");
  //   document.getElementById("productImage").focus();
  //   return;
  // }

  var myTab = document.getElementById('weightTable');
  if(myTab.rows.length === 0){
      alert("Add Size and Price");
      return;
  }

  var firebaseref = firebase.database().ref().child("Products").child(data.pushId)
  firebaseref.child("CustomerProductId").set(data.productId)
  firebaseref.child("Name").set(data.productName)
  firebaseref.child("CategoryPushId").set(data.category)
  firebaseref.child("Category").set(data.categoryName)
  firebaseref.child("Image").set(data.productImage)

  firebaseref.child("Sizes").remove()

  for (let i = 0; i < myTab.rows.length; i++) {
    var objCells = myTab.rows.item(i).cells;

    console.log(objCells.item(0).innerHTML)

    
    if(objCells.item(0).innerHTML === '') {
      var firebaseref1 = firebaseref.child("Sizes").push();
      firebaseref1.child("PushId").set(firebaseref1.getKey());
      firebaseref1.child("SizePushId").set(objCells.item(1).innerHTML)
      firebaseref1.child("Size").set(objCells.item(2).innerHTML)
      firebaseref1.child("MRPPrice").set(objCells.item(3).innerHTML);
      firebaseref1.child("CODPrice").set(objCells.item(4).innerHTML);
      firebaseref1.child("CreditPrice").set(objCells.item(5).innerHTML);
      firebaseref1.child("Stock").set(parseInt(objCells.item(6).innerHTML));
      firebaseref1.child("Status").set("Active");
    } else {
      var firebaseref2 = firebaseref.child("Sizes").child(objCells.item(0).innerHTML);
      firebaseref2.child("PushId").set(objCells.item(0).innerHTML);
      firebaseref2.child("SizePushId").set(objCells.item(1).innerHTML)
      firebaseref2.child("Size").set(objCells.item(2).innerHTML)
      firebaseref2.child("MRPPrice").set(objCells.item(3).innerHTML);
      firebaseref2.child("CODPrice").set(objCells.item(4).innerHTML);
      firebaseref2.child("CreditPrice").set(objCells.item(5).innerHTML);
      firebaseref2.child("Stock").set(parseInt(objCells.item(6).innerHTML));
      firebaseref2.child("Status").set("Active");
    }
  }

  alert("Updated Successfully!");

  data.productId = ""
  data.productName = ""
  data.category = "Select"
  data.codPrice = ""
  data.creditPrice = ""
  data.size = "Select"
  data.stock = ""
  data.productImage = ""

//   comp.setState({
//     update_msg: true
//   })

//   data.productName = ""
//   data.category = "Select"
//   data.codPrice = ""
//   data.creditPrice = ""
//   data.size = "Select"
//   data.stock = ""
//   data.productImage = ""
  document.getElementById("create-form").reset()

  var contacts = data.weights1
  contacts.splice(1, data.weights1.length);
  this.setState({
    weights1: contacts,
  });

  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"


  document.getElementById("sizeRead").style.display = "block"
  document.getElementById("sizeUpdate").style.display = "none"
}

addAddons() {
  let data = this.state;

  if(data.size === 'Select') {
    alert("Select Size");
    document.getElementById("size").focus();
    return;
  }

  if(data.mrpPrice === '') {
    alert("Enter MRP Price");
    document.getElementById("mrpPrice").focus();
    return;
  }

  if(data.codPrice === '') {
    alert("Enter COD Price");
    document.getElementById("codPrice").focus();
    return;
  }

  if(data.creditPrice === '') {
    alert("Enter Credit Price");
    document.getElementById("creditPrice").focus();
    return;
  }

  if(data.stock === '') {
    alert("Enter Stock");
    document.getElementById("stock").focus();
    return;
  }

  const weightUnits = {
    Size: data.sizeName,
    SizePushId:  data.size,
    MRPPrice: data.mrpPrice,
    CODPrice: data.codPrice,
    CreditPrice: data.creditPrice,
    Stock: data.stock
  }

    this.setState({
        weights: [...this.state.weights, weightUnits],
      });

    data.size = "Select"
    data.mrpPrice = ""
    data.codPrice = ""
    data.creditPrice = ""
    data.stock = ""

    document.getElementById("size").value = "Select";
    document.getElementById("mrpPrice").value = "";
    document.getElementById("codPrice").value = "";
    document.getElementById("creditPrice").value = "";
    document.getElementById("stock").value = "";
  }

  addAddons1() {
    let data = this.state;
  
    if(data.size1 === 'Select') {
      alert("Select Size");
      document.getElementById("size1").focus();
      return;
    }
  
    if(data.mrpPrice1 === '') {
      alert("Enter MRP Price");
      document.getElementById("mrpPrice1").focus();
      return;
    }
  
    if(data.codPrice1 === '') {
      alert("Enter COD Price");
      document.getElementById("codPrice1").focus();
      return;
    }
  
    if(data.creditPrice1 === '') {
      alert("Enter Credit Price");
      document.getElementById("creditPrice1").focus();
      return;
    }
  
    if(data.stock1 === '') {
      alert("Enter Stock");
      document.getElementById("stock1").focus();
      return;
    }
  
    const weightUnits = {
      Size: data.sizeName1,
      SizePushId:  data.size1,
      MRPPrice: data.mrpPrice1,
      CODPrice: data.codPrice1,
      CreditPrice: data.creditPrice1,
      Stock: data.stock1
    }
  
      this.setState({
          weights1: [...this.state.weights1, weightUnits],
        });
  
      data.size1 = "Select"
      data.mrpPrice1 = ""
      data.codPrice1 = ""
      data.creditPrice1 = ""
      data.stock1 = ""
  
      document.getElementById("size1").value = "Select";
      document.getElementById("mrpPrice1").value = "";
      document.getElementById("codPrice1").value = "";
      document.getElementById("creditPrice1").value = "";
      document.getElementById("stock1").value = "";
    }

  deleteRow(e){
    var contacts = [...this.state.weights];
    contacts.splice(e, 1);
    this.setState({
      weights: contacts,
    });
  }

  deleteRow1(e){
    var contacts = [...this.state.weights1];
    contacts.splice(e, 1);
    this.setState({
      weights1: contacts,
    });
  }

  editWeights(key, index) {

    let data = this.state
  
    document.getElementById("add1").style.display = "none"
    document.getElementById("updateWeight").style.display = "block"

  
    data.size1 = key.SizePushId
    data.sizeName1 = key.Size
    data.mrpPrice1 = key.MRPPrice
    data.codPrice1 = key.CODPrice
    data.creditPrice1 = key.CreditPrice
    data.stock1 = key.Stock
  
    document.getElementById("size1").value = key.SizePushId;
    document.getElementById("mrpPrice1").value = key.MRPPrice;
    document.getElementById("codPrice1").value = key.CODPrice;
    document.getElementById("creditPrice1").value = key.CreditPrice;
    document.getElementById("stock1").value = key.Stock;
  
    this.setState({
      index: index
    })
  }

  // updateAddons() {
  //   let data = this.state;

  
  //   if(data.size === 'Select') {
  //     alert("Select Size");
  //     document.getElementById("size").focus();
  //     return;
  //   }
  
  //   if(data.mrpPrice === '') {
  //     alert("Enter MRP Price");
  //     document.getElementById("mrpPrice").focus();
  //     return;
  //   }
  
  //   if(data.codPrice === '') {
  //     alert("Enter COD Price");
  //     document.getElementById("codPrice").focus();
  //     return;
  //   }
  
  //   if(data.creditPrice === '') {
  //     alert("Enter Credit Price");
  //     document.getElementById("creditPrice").focus();
  //     return;
  //   }
  
  //   if(data.stock === '') {
  //     alert("Enter Stock");
  //     document.getElementById("stock").focus();
  //     return;
  //   }

  //   console.log(data.size)
  //   console.log(data.sizeName)

  


  //   var row = document.getElementById('weightTable').rows[parseInt(data.index)].cells;
  //   row[parseInt(1)].innerHTML= data.size;
  //   row[parseInt(2)].innerHTML= data.sizeName;
  //   row[parseInt(3)].innerHTML= data.mrpPrice;
  //   row[parseInt(4)].innerHTML= data.codPrice;
  //   row[parseInt(5)].innerHTML= data.creditPrice;
  //   row[parseInt(6)].innerHTML= data.stock;
  
  //   data.size = "Select"
  //   data.mrpPrice = ""
  //   data.codPrice = ""
  //   data.creditPrice = ""
  //   data.stock = ""

  //   document.getElementById("size").value = "Select";
  //   document.getElementById("mrpPrice").value = "";
  //   document.getElementById("codPrice").value = "";
  //   document.getElementById("creditPrice").value = "";
  //   document.getElementById("stock").value = "";

  //     document.getElementById("add").style.display = "block"
  //     document.getElementById("updateWeight").style.display = "none"
  //   }

  updateAddons() {
    let data = this.state;

  
    if(data.size1 === 'Select') {
      alert("Select Size");
      document.getElementById("size1").focus();
      return;
    }
  
    if(data.mrpPrice1 === '') {
      alert("Enter MRP Price");
      document.getElementById("mrpPrice1").focus();
      return;
    }
  
    if(data.codPrice1 === '') {
      alert("Enter COD Price");
      document.getElementById("codPrice1").focus();
      return;
    }
  
    if(data.creditPrice1 === '') {
      alert("Enter Credit Price");
      document.getElementById("creditPrice1").focus();
      return;
    }
  
    if(data.stock1 === '') {
      alert("Enter Stock");
      document.getElementById("stock1").focus();
      return;
    }

  


    var row = document.getElementById('weightTable').rows[parseInt(data.index)].cells;
    row[parseInt(1)].innerHTML= data.size1;
    row[parseInt(2)].innerHTML= data.sizeName1;
    row[parseInt(3)].innerHTML= data.mrpPrice1;
    row[parseInt(4)].innerHTML= data.codPrice1;
    row[parseInt(5)].innerHTML= data.creditPrice1;
    row[parseInt(6)].innerHTML= data.stock1;
  
    data.size1 = "Select"
    data.mrpPrice1 = ""
    data.codPrice1 = ""
    data.creditPrice1 = ""
    data.stock1 = ""

    document.getElementById("size1").value = "Select";
    document.getElementById("mrpPrice1").value = "";
    document.getElementById("codPrice1").value = "";
    document.getElementById("creditPrice1").value = "";
    document.getElementById("stock1").value = "";

      document.getElementById("add1").style.display = "block"
      document.getElementById("updateWeight").style.display = "none"
  }

  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("dataTable1");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    // td9 = tr[i].getElementsByTagName("td")[9];

    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
    
     var main = txtValue1+ txtValue2+txtValue3+txtValue4;
       if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Inventory" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                              <Col md="4">
                                                      <FormGroup>
                                                          <Label htmlFor="validationCustom03">Product Id<span style = {{color: "red"}}>*</span></Label>
                                                          <Input
                                                            name="productId"
                                                            placeholder="Product Id"
                                                            type="text"
                                                            className="form-control"
                                                            id="productId"
                                                            onChange = {this.handleChange.bind(this)}
                                                          />
                                                      </FormGroup>
                                                  </Col>

                                               <Col md = {{size: 4, offset:2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Product Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="productName"
                                                          placeholder="Product Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="productName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Group<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "category" name = "category" onChange = {this.categoryOnChange.bind(this)}>
                                                            <option value = "Select">Select Group</option>
                                                            {this.state.categories.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.PushId}>{city.CategoryName}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset:2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Product Image</Label>
                                                        <Input
                                                          name="productImage"
                                                          placeholder="GST Number"
                                                          type="file"
                                                          className="form-control"
                                                          id="productImage"
                                                          onChange = {this.productUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                </Row>
                                                <br />

                                                <div id = "sizeRead">

                                                <Row>
                                                  <Col md = "2">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Size<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "size" name = "size" onChange = {this.sizeOnChange.bind(this)}>
                                                            <option value = "Select">Select Size</option>
                                                            {this.state.sizes.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.PushId}>{city.SizeName}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">MRP Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mrpPrice"
                                                          placeholder="MRP Price"
                                                          type="number"
                                                          className="form-control"
                                                          id="mrpPrice"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">COD Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="codPrice"
                                                          placeholder="COD Price"
                                                          type="number"
                                                          className="form-control"
                                                          id="codPrice"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="creditPrice"
                                                          placeholder="Credit Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="creditPrice"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Stock<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="stock"
                                                          placeholder="Stock"
                                                          type="number"
                                                          className="form-control"
                                                          id="stock"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={2}  id = "add" style = {{margin: "auto"}}>
                                                  <Button color="primary"  style = {{margin: "auto"}} onClick = {this.addAddons.bind(this)}>Add                                                   
                                                  </Button>
                                                </Col>

                                                {/* <Col sm={2}  id = "updateWeight" style = {{margin: "auto", display: "none"}}>
                                                  <Button color="primary" onClick = {this.updateAddons.bind(this)}>Update                                                   
                                                  </Button>
                                                </Col> */}
                                                </Row>

                                                <Row>

                                                <Col  sm={6}>
                                                    <Table hover>
                                                  <thead>
                                                    <tr>
                                                      <th>Size</th>
                                                      <th style = {{display: "none"}}>SizePushId</th>
                                                      <th>MRP Price</th>
                                                      <th>COD Price</th>
                                                      <th>Credit Price</th>
                                                      <th>Stock</th>
                                                      <th>Delete</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody id = "datatable">
                                                    {this.state.weights.slice(1, this.state.weights.length).map((rowData, index) => (
                                                    <tr key = {index}>
                                                      <td>{rowData.Size}</td>
                                                      <td style = {{display: "none"}}>{rowData.SizePushId}</td>
                                                      <td>{rowData.MRPPrice}</td>
                                                      <td>{rowData.CODPrice}</td>
                                                      <td>{rowData.CreditPrice}</td>
                                                      <td>{rowData.Stock}</td>
                                                      <td className = "delete-row" onClick = {this.deleteRow.bind(this,index+1)}><FontAwesomeIcon style = {{color :"Red", fontSize: "15px", cursor: "pointer"}} icon={faTrash}/></td>
                                                      </tr>
                                                  ))}
                                                    </tbody>
                                                  </Table>
                                                  </Col>
                                                  </Row>
                                              </div>


                                              <div id = "sizeUpdate" style = {{display: "none"}}>

                                                <Row>
                                                <Col md = "2">
                                                  <FormGroup>
                                                      <Label htmlFor="validationCustom03">Size<span style = {{color: "red"}}>*</span></Label>
                                                      <Input type="select" id = "size1" name = "size1" onChange = {this.sizeOnChange1.bind(this)}>
                                                          <option value = "Select">Select Size</option>
                                                          {this.state.sizes.map((city, index) => {
                                                              return (
                                                                  <option key = {index} value={city.PushId}>{city.SizeName}</option>
                                                              );
                                                          })};
                                                      </Input>
                                                  </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                  <FormGroup>
                                                      <Label htmlFor="validationCustom03">MRP Price<span style = {{color: "red"}}>*</span></Label>
                                                      <Input
                                                        name="mrpPrice1"
                                                        placeholder="MRP Price"
                                                        type="number"
                                                        className="form-control"
                                                        id="mrpPrice1"
                                                        onChange = {this.handleChange.bind(this)}
                                                      />
                                                  </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                <FormGroup>
                                                      <Label htmlFor="validationCustom03">COD Price<span style = {{color: "red"}}>*</span></Label>
                                                      <Input
                                                        name="codPrice1"
                                                        placeholder="COD Price"
                                                        type="number"
                                                        className="form-control"
                                                        id="codPrice1"
                                                        onChange = {this.handleChange.bind(this)}
                                                      />
                                                  </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                  <FormGroup>
                                                      <Label htmlFor="validationCustom03">Credit Price<span style = {{color: "red"}}>*</span></Label>
                                                      <Input
                                                        name="creditPrice1"
                                                        placeholder="Credit Price"
                                                        type="text"
                                                        className="form-control"
                                                        id="creditPrice1"
                                                        onChange = {this.handleChange.bind(this)}
                                                      />
                                                  </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                  <FormGroup>
                                                      <Label htmlFor="validationCustom03">Stock<span style = {{color: "red"}}>*</span></Label>
                                                      <Input
                                                        name="stock1"
                                                        placeholder="Stock"
                                                        type="number"
                                                        className="form-control"
                                                        id="stock1"
                                                        onChange = {this.handleChange.bind(this)}
                                                      />
                                                  </FormGroup>
                                                </Col>

                                                <Col sm={2}  id = "add1" style = {{margin: "auto"}}>
                                                <Button color="primary"  style = {{margin: "auto"}} onClick = {this.addAddons1.bind(this)}>Add                                                   
                                                </Button>
                                                </Col>

                                                <Col sm={2}  id = "updateWeight" style = {{margin: "auto", display: "none"}}>
                                                <Button color="primary" onClick = {this.updateAddons.bind(this)}>Update                                                   
                                                </Button>
                                                </Col>
                                                </Row>

                                                <Row>

                                                <Col  sm={6}>
                                                  <Table hover>
                                                <thead>
                                                  <tr>
                                                    <th style = {{display: "none"}}>PushId</th>
                                                    <th style = {{display: "none"}}>SizePushId</th>
                                                    <th>Size</th>
                                                    <th>MRP Price</th>
                                                    <th>COD Price</th>
                                                    <th>Credit Price</th>
                                                    <th>Stock</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                  </tr>
                                                </thead>
                                                <tbody id = "weightTable">
                                                  {this.state.weights1.map((rowData, index) => (
                                                  <tr key = {index}>
                                                    <td style = {{display: "none"}}>{rowData.PushId}</td>
                                                    <td style = {{display: "none"}}>{rowData.SizePushId}</td>
                                                    <td>{rowData.Size}</td>
                                                    <td>{rowData.MRPPrice}</td>
                                                    <td>{rowData.CODPrice}</td>
                                                    <td>{rowData.CreditPrice}</td>
                                                    <td>{rowData.Stock}</td>
                                                    <td onClick = {this.editWeights.bind(this,rowData, index)}><FontAwesomeIcon style = {{color :"Blue", fontSize: "15px", cursor: "pointer"}} icon={faInfo}/></td>
                                                    <td className = "delete-row" onClick = {this.deleteRow1.bind(this,index)}><FontAwesomeIcon style = {{color :"Red", fontSize: "15px", cursor: "pointer"}} icon={faTrash}/></td>
                                                    </tr>
                                                ))}
                                                  </tbody>
                                                </Table>
                                                </Col>


                                                </Row>
                                                </div>

                                             
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Product Successfully Added!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  Product Updated Successfully!
                                                </SweetAlert>
                                              ) : null}     
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                          <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                    <Row>
                                            <Col md = "6">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                        </Row>
                                        <br />

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable1">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Product Image</th>
                                                            <th>Product Id</th>
                                                            <th>Product Name</th>
                                                            <th>Group</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                      {console.log(rowData.Image)}
                                                        <td>{index + 1}</td>
                                                        {rowData.Image === '' ?
                                                        <td><img src= {Logo} style = {{backgroundColor: "#2A3042"}} alt = "logo" className="img-responsive inline-block" width="100" height="100" /></td> :
                                                        <td><img src= {rowData.Image} alt = "category" className="img-responsive inline-block" width="100" height="100" /></td> }
                                                        <td>{rowData.CustomerProductId}</td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Category}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Products;