import firebase from 'firebase';

const firebaseConfig = {
  // apiKey: "AIzaSyBvaxnwVofyZEJeerX_iLrk86kYh9xxjYM",
  // authDomain: "shyamdeep-traders-b7cc2.firebaseapp.com",
  // databaseURL: "https://shyamdeep-traders-b7cc2.firebaseio.com",
  // projectId: "shyamdeep-traders-b7cc2",
  // storageBucket: "shyamdeep-traders-b7cc2.appspot.com",
  // messagingSenderId: "346677120800",
  // appId: "1:346677120800:web:85aa3d34ed4b0121cdd145",
  // measurementId: "G-PPGVCK2WFR"
  apiKey: "AIzaSyAmTxuycthkimqg2NkmIpT2bLcvy1Jk1PI",
  authDomain: "shri-ram-footwear.firebaseapp.com",
  projectId: "shri-ram-footwear",
  storageBucket: "shri-ram-footwear.appspot.com",
  messagingSenderId: "121529607474",
  appId: "1:121529607474:web:d548e972bc84f6cb8e632b"
};

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }

//   firebase.initializeApp(config);

  export default firebase;