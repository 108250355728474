import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Container, Input, Form, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../assets/images/Shyamlogo.png'


class AddRetailers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: [],
            success_msg: false,
            customerName: '',
            retailerName: '',
            mobileNumber: '',
            address: '',
            creditLimit: '',
            creditDays: '',
            gstNo: '',
            gstImage: '',
            gstType: 'Select',
            gstStateCode: '',
            area: '',
            location: '',
            openingBalance: '',
            customerType: 'Select',
            outStandingAmount: '',
            image: ''
        };
    }

    componentDidMount() {
        firebase.database().ref().child("Retailer")
            .on('value', (snapshot) => {
                if (snapshot.exists()) {
                    const newData = []
                    snapshot.forEach(function (doc) {
                        newData.push(doc.val())
                    })
                    this.setState({
                        firebaseData: newData
                    })
                }
            })
    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        let data = this.state
        let comp = this


        if (data.customerName === '') {
            alert("Enter Customer Name");
            document.getElementById("customerName").focus();
            return;
        }

        if (data.retailerName === '') {
            alert("Enter Retailer Name");
            document.getElementById("retailerName").focus();
            return;
        }

        if (data.mobileNumber === '') {
            alert("Enter Mobile Number");
            document.getElementById("mobileNumber").focus();
            return;
        }

        if (data.mobileNumber.length !== 10) {
            alert("Enter Correct Mobile Number");
            document.getElementById("mobileNumber").focus();
            return;
        }

        if (data.address === '') {
            alert("Enter Address");
            document.getElementById("address").focus();
            return;
        }

        if (data.creditLimit === '') {
            alert("Enter Credit Limit");
            document.getElementById("creditLimit").focus();
            return;
        }

        if (data.creditDays === '') {
            alert("Enter Credit Days");
            document.getElementById("creditDays").focus();
            return;
        }

        // if(data.gstNo === '') {
        //   alert("Enter GST Number");
        //   document.getElementById("gstNo").focus();
        //   return;
        // }

        // if(data.gstImage === '') {
        //   alert("Select GST Image");
        //   document.getElementById("gstImage").focus();
        //   return;
        // }

        // if(data.gstType === 'Select') {
        //     alert("Select GST Type");
        //     document.getElementById("gstType").focus();
        //     return;
        // }

        // if(data.gstStateCode === '') {
        //   alert("Enter GST State Code");
        //   document.getElementById("gstStateCode").focus();
        //   return;
        // }

        // if(data.pincode === 'Select') {
        //   alert("Select Area");
        //   document.getElementById("pincode").focus();
        //   return;
        // }

        if (data.openingBalance === '') {
            alert("Enter Opening Balance");
            document.getElementById("openingBalance").focus();
            return;
        }

        if (data.outStandingAmount === '') {
            alert("Enter OutStanding Amount");
            document.getElementById("outStandingAmount").focus();
            return;
        }

        if (data.customerType === 'Select') {
            alert("Select Customer Type");
            document.getElementById("customerType").focus();
            return;
        }

        if (data.image === '') {
            alert("Select Photo");
            document.getElementById("image").focus();
            return;
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        
        today = yyyy + '-' + mm + '-' + dd;


        var tot = 0;

        var stock = firebase.database().ref().child("RetailerId");
        stock.transaction(function (currentstock) {
            tot = currentstock + 1;
            return tot;
        },
            function (error, committed, snapshot) {
                if (error) {
                    console.log('Transaction failed abnormally!', error);
                } else if (committed) {


                    var userid = "SHRIRET" + tot;


                    var firebaseref = firebase.database().ref().child("Retailer").child(userid)
                    firebaseref.child("CustomerName").set(data.customerName)
                    firebaseref.child("RetailerName").set(data.retailerName)
                    firebaseref.child("MobileNumber").set(data.mobileNumber)
                    firebaseref.child("Address").set(data.address)
                    firebaseref.child("CreditLimit").set(data.creditLimit)
                    firebaseref.child("CreditDays").set(data.creditDays)
                    firebaseref.child("GSTNumber").set(data.gstNo)
                    firebaseref.child("GSTImage").set(data.gstImage)
                    firebaseref.child("GSTType").set(data.gstType)
                    firebaseref.child("GSTStateCode").set(data.gstStateCode)
                    firebaseref.child("Area").set(data.area)
                    firebaseref.child("Location").set(data.location)
                    firebaseref.child("OpeningBalance").set(parseInt(data.openingBalance))
                    firebaseref.child("Balances").set(parseInt(0))
                    firebaseref.child("OutStanding").set(data.outStandingAmount)
                    firebaseref.child("CustomerType").set(data.customerType)
                    firebaseref.child("UserId").set(userid)
                    firebaseref.child("Image").set(data.image)
                    firebaseref.child("Created").set(today)

                    comp.setState({
                        success_msg: true,
                        customerName: '',
                        retailerName: '',
                        mobileNumber: '',
                        address: '',
                        creditLimit: '',
                        creditDays: '',
                        gstNo: '',
                        gstImage: '',
                        gstType: 'Select',
                        gstStateCode: '',
                        area: '',
                        location: '',
                        openingBalance: '',
                        customerType: 'Select',
                        outStandingAmount: '',
                        image: ''
                    })
                }
            })
    }

    gstUpload = e => {

        if (e.target.files[0] === 0) {
            alert("Add GST Image");
            return;
        }

        const ref = firebase.storage().ref("/Retailer/");
        const file = e.target.files[0];
        const name = e.target.files[0] + Date();
        const metadata = {
            contentType: file.type
        };
        const task = ref.child(name).put(file, metadata);
        task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                this.setState({
                    gstImage: url
                })
            })
            .catch(console.error);
    }

    sweetAlertOnConfirm() {
        let data = this.state
        this.setState({
            success_msg: false,
            customerName: '',
            retailerName: '',
            mobileNumber: '',
            address: '',
            creditLimit: '',
            creditDays: '',
            gstNo: '',
            gstImage: '',
            gstType: 'Select',
            gstStateCode: '',
            area: '',
            location: '',
            openingBalance: '',
            customerType: 'Select',
            outStandingAmount: '',
            image: ''
        })
    }

    editRow(key) {

        document.getElementById("submit").style.display = "none";
        document.getElementById("update").style.display = "block";

        this.setState({
            customerName: key.CustomerName,
            retailerName: key.RetailerName,
            mobileNumber: key.MobileNumber,
            address: key.Address,
            creditLimit: key.CreditLimit,
            creditDays: key.CreditDays,
            gstNo: key.GSTNumber,
            gstImage: key.GSTImage,
            gstType: key.GSTType,
            gstStateCode: key.GSTStateCode,
            area: key.Area,
            location: key.Location,
            openingBalance: key.OpeningBalance,
            customerType: key.CustomerType,
            outStandingAmount: key.OutStanding,
            image: key.Image,
            userid: key.UserId

        })

        window.scrollTo('top', 0)

    }

    delete(key) {
        if (window.confirm('Are you sure you want to delete the retailer?')) {
            firebase.database().ref().child('Retailer').child(key.UserId).remove()
            alert("Successfully Deleted!")
        } else { }
    }

    handleUpdate(e) {
        e.preventDefault();

        let data = this.state
        let comp = this


        if (data.customerName === '') {
            alert("Enter Customer Name");
            document.getElementById("customerName").focus();
            return;
        }

        if (data.retailerName === '') {
            alert("Enter Retailer Name");
            document.getElementById("retailerName").focus();
            return;
        }

        if (data.mobileNumber === '') {
            alert("Enter Mobile Number");
            document.getElementById("mobileNumber").focus();
            return;
        }

        if (data.mobileNumber.length !== 10) {
            alert("Enter Correct Mobile Number");
            document.getElementById("mobileNumber").focus();
            return;
        }

        if (data.address === '') {
            alert("Enter Address");
            document.getElementById("address").focus();
            return;
        }

        if (data.creditLimit === '') {
            alert("Enter Credit Limit");
            document.getElementById("creditLimit").focus();
            return;
        }

        if (data.creditDays === '') {
            alert("Enter Credit Days");
            document.getElementById("creditDays").focus();
            return;
        }

        // if(data.gstNo === '') {
        //   alert("Enter GST Number");
        //   document.getElementById("gstNo").focus();
        //   return;
        // }

        // if(data.gstImage === '') {
        //   alert("Select GST Image");
        //   document.getElementById("gstImage").focus();
        //   return;
        // }

        // if(data.gstType === 'Select') {
        //     alert("Select GST Type");
        //     document.getElementById("gstType").focus();
        //     return;
        // }

        // if(data.gstStateCode === '') {
        //   alert("Enter GST State Code");
        //   document.getElementById("gstStateCode").focus();
        //   return;
        // }

        // if(data.pincode === 'Select') {
        //   alert("Select Area");
        //   document.getElementById("pincode").focus();
        //   return;
        // }

        if (data.openingBalance === '') {
            alert("Enter Opening Balance");
            document.getElementById("openingBalance").focus();
            return;
        }

        if (data.outStandingAmount === '' || data.outStandingAmount === undefined) {
            alert("Enter OutStanding Amount");
            document.getElementById("outStandingAmount").focus();
            return;
        }

        if (data.customerType === 'Select') {
            alert("Select Customer Type");
            document.getElementById("customerType").focus();
            return;
        }

        if (data.image === '' || data.image === undefined) {
            alert("Select Photo");
            document.getElementById("image").focus();
            return;
        }


        var firebaseref = firebase.database().ref().child("Retailer").child(data.userid)
        firebaseref.child("CustomerName").set(data.customerName)
        firebaseref.child("RetailerName").set(data.retailerName)
        firebaseref.child("MobileNumber").set(data.mobileNumber)
        firebaseref.child("Address").set(data.address)
        firebaseref.child("CreditLimit").set(data.creditLimit)
        firebaseref.child("CreditDays").set(data.creditDays)
        firebaseref.child("GSTNumber").set(data.gstNo)
        firebaseref.child("GSTImage").set(data.gstImage)
        firebaseref.child("GSTType").set(data.gstType)
        firebaseref.child("GSTStateCode").set(data.gstStateCode)
        firebaseref.child("Area").set(data.area)
        firebaseref.child("Location").set(data.location)
        firebaseref.child("OpeningBalance").set(parseInt(data.openingBalance))
        firebaseref.child("OutStanding").set(data.outStandingAmount)
        firebaseref.child("CustomerType").set(data.customerType)
        firebaseref.child("Image").set(data.image)

        alert("Updated Successfully")

        this.setState({
            customerName: '',
            retailerName: '',
            mobileNumber: '',
            address: '',
            creditLimit: '',
            creditDays: '',
            gstNo: '',
            gstImage: '',
            gstType: 'Select',
            gstStateCode: '',
            area: '',
            location: '',
            openingBalance: '',
            customerType: 'Select',
            outStandingAmount: '',
            image: '',
            userid: ''
        })

        document.getElementById("submit").style.display = "block";
        document.getElementById("update").style.display = "none";
    }

    customerPhotoUpload = e => {

        if (e.target.files[0] === 0) {
            alert("Add Image");
            return;
        }

        const ref = firebase.storage().ref("/Retailer/");
        const file = e.target.files[0];
        const name = e.target.files[0] + Date();
        const metadata = {
            contentType: file.type
        };
        const task = ref.child(name).put(file, metadata);
        task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                this.setState({
                    image: url
                })
            })
            .catch(console.error);
    }

    myFunction() {
        var input, filter, table, tr, td1, td2, td3, td4, td5, td6, td7, td8, td9, td10, td11, td12;
        var i, txtValue1, txtValue2, txtValue3, txtValue4, txtValue5, txtValue6, txtValue7, txtValue8, txtValue9, txtValue10, txtValue11, txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td1 = tr[i].getElementsByTagName("td")[1];
            td2 = tr[i].getElementsByTagName("td")[2];
            td3 = tr[i].getElementsByTagName("td")[3];
            td4 = tr[i].getElementsByTagName("td")[4];
            td5 = tr[i].getElementsByTagName("td")[5];
            td6 = tr[i].getElementsByTagName("td")[6];
            td7 = tr[i].getElementsByTagName("td")[7];
            // td8 = tr[i].getElementsByTagName("td")[8];
            // td9 = tr[i].getElementsByTagName("td")[9];

            if (td1) {
                txtValue1 = td1.textContent || td1.innerText;
                txtValue2 = td2.textContent || td2.innerText;
                txtValue3 = td3.textContent || td3.innerText;
                txtValue4 = td4.textContent || td4.innerText;
                txtValue5 = td5.textContent || td5.innerText;
                txtValue6 = td6.textContent || td6.innerText;
                txtValue7 = td7.textContent || td7.innerText;
                // txtValue8 = td8.textContent || td8.innerText;
                // txtValue9 = td9.textContent || td9.innerText;

                var main = txtValue1 + txtValue2 + txtValue3 + txtValue4 + txtValue5 + txtValue6 + txtValue7;
                if (main.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Master" breadcrumbItem="Add Retailer" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form id="create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Customer Name<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="customerName"
                                                            placeholder="Customer Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="customerName"
                                                            value={this.state.customerName}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Retailer Name<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="retailerName"
                                                            placeholder="Retailer Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="retailerName"
                                                            value={this.state.retailerName}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="mobileNumber"
                                                            placeholder="Mobile Number"
                                                            type="number"
                                                            className="form-control"
                                                            id="mobileNumber"
                                                            value={this.state.mobileNumber}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Address<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="address"
                                                            placeholder="Address"
                                                            type="text"
                                                            className="form-control"
                                                            id="address"
                                                            value={this.state.address}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Limit<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="creditLimit"
                                                            placeholder="Credit Limit"
                                                            type="number"
                                                            className="form-control"
                                                            id="creditLimit"
                                                            value={this.state.creditLimit}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Days<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="creditDays"
                                                            placeholder="Credit Days"
                                                            type="number"
                                                            className="form-control"
                                                            id="creditDays"
                                                            value={this.state.creditDays}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">GST Number</Label>
                                                        <Input
                                                            name="gstNo"
                                                            placeholder="GST Number"
                                                            type="text"
                                                            className="form-control"
                                                            id="gstNo"
                                                            value={this.state.gstNo}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">GST Image</Label>
                                                        <Input
                                                            name="gstImage"
                                                            placeholder="GST Number"
                                                            type="file"
                                                            className="form-control"
                                                            id="gstImage"
                                                            onChange={this.gstUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">GST Type</Label>
                                                        <Input type="select" id="gstType" name="gstType" value={this.state.gstType} onChange={this.handleChange.bind(this)}>
                                                            <option value="Select">Select GST Type</option>
                                                            <option value="Regular">Regular</option>
                                                            <option value="Compositor">Compositor</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">GST State Code</Label>
                                                        <Input
                                                            name="gstStateCode"
                                                            placeholder="GST State Code"
                                                            type="number"
                                                            className="form-control"
                                                            id="gstStateCode"
                                                            value={this.state.gstStateCode}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Area<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="area"
                                                            placeholder="Area"
                                                            type="text"
                                                            className="form-control"
                                                            id="area"
                                                            value={this.state.area}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                        {/* <Input type="select" id = "pincode" name = "pincode" onChange = {this.handleChange.bind(this)}>
                                                            <option value = "Select">Select Area</option>
                                                            {this.state.pins.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.Pincode}>{city.Pincode}</option>
                                                                );
                                                            })};
                                                        </Input> */}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Location<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="location"
                                                            placeholder="Location"
                                                            type="text"
                                                            className="form-control"
                                                            id="location"
                                                            value={this.state.location}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Opening Balance<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="openingBalance"
                                                            placeholder="Opening Balance"
                                                            type="number"
                                                            className="form-control"
                                                            id="openingBalance"
                                                            value={this.state.openingBalance}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">OutStanding Amount<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="outStandingAmount"
                                                            placeholder="Outstanding Amount"
                                                            type="number"
                                                            className="form-control"
                                                            id="outStandingAmount"
                                                            value={this.state.outStandingAmount}
                                                            onChange={this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={{ size: 4 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Customer Type<span style={{ color: "red" }}>*</span></Label>
                                                        <Input type="select" id="customerType" name="customerType" value={this.state.customerType} onChange={this.handleChange.bind(this)}>
                                                            <option value="Select">Select Customer Type</option>
                                                            <option value="Creditor">Creditor</option>
                                                            <option value="Debitor">Debitor</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                {/* <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Sales Man</Label>
                                                        <Input type="select" id = "salesMan" name = "salesMan" onChange = {this.handleChange.bind(this)}>
                                                            <option value = "">Select Sales Man</option>
                                                            {this.state.sales.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.SalesName}>{city.SalesName}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col> */}

                                                <Col md={{ size: 4, offset: 2 }}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Photo<span style={{ color: "red" }}>*</span></Label>
                                                        <Input
                                                            name="image"
                                                            placeholder="image"
                                                            type="file"
                                                            className="form-control"
                                                            id="image"
                                                            onChange={this.customerPhotoUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button color="primary" id="submit" onClick={this.handleSubmit.bind(this)} type="submit">Submit</Button>
                                            <Button color="primary" id="update" style={{ display: "none" }} onClick={this.handleUpdate.bind(this)} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={this.sweetAlertOnConfirm.bind(this)}
                                                >
                                                    Retailer Successfully Created!
                                                </SweetAlert>
                                            ) : null}

                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        {/* <Row>
                                            <Col md = "6">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                        </Row>
                                        <br /> */}

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id="dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>Photo</th>
                                                        <th>Customer Name</th>
                                                        <th>Retailer Name</th>
                                                        <th>Mobile Number</th>
                                                        <th>Address</th>
                                                        <th>GSTNumber</th>
                                                        <th>Area</th>
                                                        <th>Details</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.firebaseData.map((rowData, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            {rowData.Image === '' || rowData.Image === undefined ?
                                                                <td><img src={Logo} style={{ backgroundColor: "#2A3042" }} alt="logo" className="img-responsive inline-block" width="100" height="100" /></td> :
                                                                <td><img src={rowData.Image} alt="salesman" className="img-responsive inline-block" width="100" height="100" /></td>}
                                                            <td>{rowData.CustomerName}</td>
                                                            <td>{rowData.RetailerName}</td>
                                                            <td>{rowData.MobileNumber}</td>
                                                            <td>{rowData.Address}</td>
                                                            <td>{rowData.GSTNumber}</td>
                                                            <td>{rowData.Area}</td>
                                                            <td onClick={this.editRow.bind(this, rowData)}><i style={{ color: "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer" }} className="fas fa-edit"></i></td>
                                                            <td onClick={this.delete.bind(this, rowData)}><i style={{ color: "red", fontSize: "15px", padding: "10px", cursor: "pointer" }} className="fas fa-trash"></i></td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default AddRetailers;