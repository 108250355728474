import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


class Blank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      success_msg: false,
      name: '',
      empImage: '',
      mobileNumber: '',
      address: '',
      aadharNumber: '',
      pins: [],
      outStandingAmount: '',
      pushId: ''
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
                    </div>
      </React.Fragment>
    );
  }
}

export default Blank;