import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ExcelRenderer } from 'react-excel-renderer';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class BulkUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      pincodes: [],
      rows: [],
      cols: [],
      categoryName: 'Select',
      customerCount: '',
      firebaseData1: []
    };
  }

  componentDidMount() {

    let customerCount = 0
    let comp = this

    firebase.database().ref().child("CustomerId").once("value", function (snapshot) {
      customerCount = snapshot.val()

      comp.setState({
        customerCount: customerCount
      })
    })

    let newData1 = []
    const del1 = {
      Sales_Date_YYYY_MM_DD: "",
      Retailer_Name: "",
      Type: "",
      Sales_Number: "",
      Debit_Amount: ""
    }
    newData1.push(del1)

    this.setState({
      firebaseData1: newData1
    })
  }

  imageUpload = event => {

    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      }
      else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows
        });
      }
    })
  }


  exportToCSV(csvData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  submit(e) {
    e.preventDefault();
    let data = this.state

    let retailerNames = []
    let retailerUserIds = []
    let retailerOutstandingBalances = []

    console.log(this.state.rows)
    console.log(this.state.cols)

    var myTab = document.getElementById('excelTable');

    console.log(myTab)

    if (myTab.rows.length === 0) {
      alert("Upload Excel Sheet");
      return;
    }

    let a = data.customerCount
    a = a + 1

    firebase.database().ref().child("Retailer")
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          const newData = []
          snapshot.forEach(function (doc) {
            retailerNames.push(doc.val().RetailerName)
            retailerUserIds.push(doc.val().UserId)
            retailerOutstandingBalances.push(doc.val().OutStanding)
          })
        }
        // })

        // setTimeout(function() {


        let trigger = 0

        for (let i = 0; i < myTab.rows.length; i++) {
          let objCells = myTab.rows.item(i).cells;


          if (retailerNames.indexOf(objCells.item(1).innerHTML) === -1) {
            alert("Retailer Name is Invalid. Please Enter Correct Area With Case Matching: " + objCells.item(1).innerHTML)
            trigger = 1
            break;
          }
        }

        if (trigger === 0) {
          for (let i = 0; i < myTab.rows.length; i++) {
            let objCells = myTab.rows.item(i).cells;

            let balance = Number(retailerOutstandingBalances[retailerNames.indexOf(objCells.item(1).innerHTML)]) + Number((objCells.item(4).innerHTML))

            var firebaseref = firebase.database().ref().child("Sales").push();
            firebaseref.child("RetailerName").set(objCells.item(1).innerHTML)
            firebaseref.child("RetailerUserId").set(retailerUserIds[retailerNames.indexOf(objCells.item(1).innerHTML)])
            firebaseref.child("SalesDate").set(objCells.item(0).innerHTML)
            firebaseref.child("SalesNumber").set(objCells.item(3).innerHTML)
            firebaseref.child("Amount").set(Number(objCells.item(4).innerHTML))
            firebaseref.child("PushId").set(firebaseref.getKey());

            var firebaseref1 = firebase.database().ref().child("Retailer").child(retailerUserIds[retailerNames.indexOf(objCells.item(1).innerHTML)]).child("Sales")
            firebaseref1.child("RetailerName").set(objCells.item(1).innerHTML)
            firebaseref1.child("RetailerUserId").set(retailerUserIds[retailerNames.indexOf(objCells.item(1).innerHTML)])
            firebaseref1.child("SalesDate").set(objCells.item(0).innerHTML)
            firebaseref1.child("SalesNumber").set(objCells.item(3).innerHTML)
            firebaseref1.child("Amount").set(Number(objCells.item(4).innerHTML))
            firebaseref1.child("PushId").set(firebaseref1.getKey());

            firebase.database().ref().child("Retailer").child(retailerUserIds[retailerNames.indexOf(objCells.item(1).innerHTML)]).child("OutStanding").set(balance);
          }
        }

        if (trigger === 0) {
          alert('Sales Data Added Sucessfully!');
        }

        data.rows = []
        data.cols = []
        document.getElementById("productImage").value = ""
      })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <Breadcrumbs title="Masters" breadcrumbItem="Upload Daily Sales" />

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>

                    {/* <Form id="create-form1"> */}
                      <Row>
                        <Col md={{ size: 4 }}>
                          <h4>Upload Daily Sales</h4> <br />

                          <Col md="12">
                            {/* <FormGroup> */}
                              <Button color="primary" onClick={this.exportToCSV.bind(this, this.state.firebaseData1, 'Sales Report Template')} type="submit">Download Sample Template</Button>
                            {/* </FormGroup> */}
                          </Col>
                        </Col>
                      </Row>
                    {/* </Form> */}

                    {/* <Table responsive hover id="dataTable" style={{ display: "none" }}>
                      <thead>
                        <tr>
                          <th>Date (YYYY-MM-DD format)</th>
                          <th>Retailer Name</th>
                          <th>Type</th>
                          <th>Sales No</th>
                          <th>Debit Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </Table> */}
                    <br />

                    <Form>
                      <FormGroup row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="exampleAddressGrid">Upload Sheet
                              <span style={{ color: "Red" }}>*</span>
                            </Label>
                            <Input
                              type="file"
                              name="productImage"
                              id="productImage"
                              onChange={this.imageUpload}
                            />
                          </FormGroup>
                        </Col>

                        <Table responsive hover>
                          <thead>
                            <tr>
                              <th>Date (YYYY-MM-DD format)</th>
                              <th>Retailer Name</th>
                              <th>Type</th>
                              <th>Sales No</th>
                              <th>Debit Amount</th>
                            </tr>
                          </thead>
                          <tbody id="excelTable">
                            {this.state.rows.slice(1, this.state.rows.length).map((rowData, index) => (
                              <tr key={index}>
                                <td>{rowData[0]}</td>
                                <td>{rowData[1]}</td>
                                <td>{rowData[2]}</td>
                                <td>{rowData[3]}</td>
                                <td>{rowData[4]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </FormGroup>

                      <Button type="submit" color="primary" onClick={this.submit.bind(this)}>
                        Upload
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BulkUpload;
