import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import Select from "react-select";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


class DeliveryPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesName: '',
      mobileNumber: '',
      email: '',
      address: '',
      firebaseData: [],
      success_msg: false,
      selectedMulti: [],
      pincodes: [],
      pushId: '',
      password: ''
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Masters").child("Pincode")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                options: [ {
                label: doc.val().Pincode,
                value: doc.val().Pincode
                }]
            }
            newData.push(dict)
        })
        this.setState({
          pincodes: newData
        })
      }
    })

    firebase.database().ref().child("Masters").child("DeliveryPartner")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
          if(doc.val().MobileNumber !== undefined) {
            const dict = {
              DeliveryName: doc.val().DeliveryName,
              MobileNumber: doc.val().MobileNumber,
              Pincodes: doc.val().Pincodes,
              Email: doc.val().Email,
              Address: doc.val().Address,
              Password: doc.val().Password
            }
            newData.push(dict)
          }
        })
        this.setState({
          firebaseData: newData
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this

    if(data.salesName === '') {
      alert("Enter Delivery Name");
      document.getElementById("salesName").focus();
      return;
    }

    if(data.mobileNumber === '') {
        alert("Enter Mobile Number");
        document.getElementById("mobileNumber").focus();
        return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.email === '') {
      alert("Enter Email");
      document.getElementById("email").focus();
      return;
    }

    if(data.address === '') {
      alert("Enter Address");
      document.getElementById("address").focus();
      return;
    }

    if(data.selectedMulti.length === 0) {
        alert("Select Areas");
        document.getElementById("assignPincode").focus();
        return;
    }

    if(data.password === '') {
      alert("Enter Password");
      document.getElementById("password").focus();
      return;
  }

    var firebaseref = firebase.database().ref().child("Masters").child("DeliveryPartner").child(data.mobileNumber)
    firebaseref.child("DeliveryName").set(data.salesName)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("Email").set(data.email)
    firebaseref.child("Address").set(data.address)
    firebaseref.child("Password").set(data.password)

    var pin = ""

    for (let i =0; i< data.selectedMulti.length; i++) {
        pin = pin + data.selectedMulti[i].value + ","
    }

    firebaseref.child("Pincodes").set(pin)

    comp.setState({
      success_msg: true
    })

    data.salesName = ""
    data.mobileNumber = ""
    data.email = ""
    data.address = ""
    data.selectedMulti = []
    data.password = ''
    document.getElementById("create-form").reset()
}

handleMulti = selectedMulti => {
  this.setState({ selectedMulti });
};

editRow(key) {
  let data = this.state
  document.getElementById("submit").style.display = "none"
  document.getElementById("update").style.display = "block"

  data.salesName = key.DeliveryName
  data.mobileNumber = key.MobileNumber
  data.email = key.Email
  data.address = key.Address
  data.password = key.Password

  let pinc = key.Pincodes.split(',')
  let a = []
  for (let i = 0; i< pinc.length - 1; i++) {
      a.push({
          label: pinc[i],
          value: pinc[i]
        })
  }
  this.setState({
        selectedMulti: a
    })

  document.getElementById("salesName").value = key.DeliveryName
  document.getElementById("mobileNumber").value = key.MobileNumber
  document.getElementById("email").value = key.Email
  document.getElementById("address").value = key.Address
  document.getElementById("password").value = key.Password
  document.getElementById("mobileNumber").disabled = true
}

delete(key) {
  if (window.confirm('Are you sure you want to delete the delivery partner?')) {
      firebase.database().ref().child("Masters").child('DeliveryPartner').child(key.MobileNumber).remove()
      alert("Successfully Deleted!")
    } else {}
}

updateRow(e) {
  e.preventDefault();

  let data = this.state

  let comp = this

  if(data.salesName === '') {
    alert("Enter Delivery Name");
    document.getElementById("salesName").focus();
    return;
  }

  if(data.email === '') {
    alert("Enter Email");
    document.getElementById("email").focus();
    return;
  }

  if(data.address === '') {
    alert("Enter Address");
    document.getElementById("address").focus();
    return;
  }

  if(data.selectedMulti.length === 0) {
      alert("Select Areas");
      document.getElementById("assignPincode").focus();
      return;
  }

  if(data.password === '' || data.password === undefined) {
    alert("Enter Password")
    document.getElementById("password").focus()
    return
  }

  var firebaseref = firebase.database().ref().child("Masters").child("DeliveryPartner").child(data.mobileNumber)
  firebaseref.child("DeliveryName").set(data.salesName)
  firebaseref.child("MobileNumber").set(data.mobileNumber)
  firebaseref.child("Email").set(data.email)
  firebaseref.child("Address").set(data.address)
  firebaseref.child("Password").set(data.password)

  var pin = ""

  for (let i =0; i< data.selectedMulti.length; i++) {
      pin = pin + data.selectedMulti[i].value + ","
  }

  firebaseref.child("Pincodes").set(pin)

  comp.setState({
    update_msg: true
  })

  data.salesName = ""
  data.mobileNumber = ""
  data.email = ""
  data.address = ""
  data.selectedMulti = []
  data.password = ''
  document.getElementById("create-form").reset()
  document.getElementById("submit").style.display = "block"
  document.getElementById("update").style.display = "none"
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Create Delivery Partner" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="salesName"
                                                          placeholder="Delivery Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="salesName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mobileNumber"
                                                          placeholder="Mobile Number"
                                                          type="number"
                                                          className="form-control"
                                                          id="mobileNumber"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Email<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="email"
                                                          placeholder="Email"
                                                          type="text"
                                                          className="form-control"
                                                          id="email"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Address<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="address"
                                                          placeholder="Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="address"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4"> 
                                                <FormGroup className="select2-container">
                                                      <label className="control-label">Assign Areas</label>
                                                      <Select
                                                        value = {this.state.selectedMulti}
                                                        isMulti={true}
                                                        onChange={this.handleMulti}
                                                        options={this.state.pincodes}
                                                        classNamePrefix="select1-selection"
                                                        id = "assignPincode"
                                                        placeholder = "Select Areas"
                                                      />
                                                  </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Password<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="password"
                                                          placeholder="Password"
                                                          type="text"
                                                          className="form-control"
                                                          id="password"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)} id = "submit" type="submit">Submit</Button>
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update" style = {{display: "none"}} type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Delivery Partner Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  Delivery Partner Updated Successfully!
                                                </SweetAlert>
                                              ) : null}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email</th>
                                                            <th>Address</th>
                                                            <th>Password</th>
                                                            <th>Area</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td>{rowData.DeliveryName}</td>
                                                        <td>{rowData.MobileNumber}</td>
                                                        <td>{rowData.Email}</td>
                                                        <td>{rowData.Address}</td>
                                                        <td>{rowData.Password}</td>
                                                        <td>{rowData.Pincodes}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default DeliveryPartner;