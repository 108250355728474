import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Logo from '../../assets/images/Shyamlogo.png'


class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      success_msg: false,
      customerName: '',
      retailerName: '',
      mobileNumber: '',
      address: '',
      creditLimit: '',
      creditDays: '',
      gstNo: '',
      gstImage: '',
      gstType: 'Select',
      gstStateCode: '',
      pincode:'Select',
      openingBalance: '',
      customerType: 'Select',
      pins: [],
      sales: [],
      salesMan: '',
      outStandingAmount: '',
      image: ''
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Masters").child("Pincode")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                Pincode: doc.val().Pincode,
            }
            newData.push(dict)
        })
        this.setState({
          pins: newData
        })
      }
    })

    firebase.database().ref().child("Masters").child("SalesAgent")
    .once('value', (snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
          if(doc.val().MobileNumber !== undefined) {
            const dict = {
              SalesName: doc.val().SalesName,
              MobileNumber: doc.val().MobileNumber,
              Pincodes: doc.val().Pincodes,
              Email: doc.val().Email,
              Address: doc.val().Address,
              Password: doc.val().Password
            }
            newData.push(dict)
          }
        })
        this.setState({
          sales: newData
        })
      }
    })

    firebase.database().ref().child("Customer")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                CustomerName: doc.val().CustomerName,
                RetailerName: doc.val().RetailerName,
                MobileNumber: doc.val().MobileNumber,
                Address: doc.val().VendorAddress,
                CreditLimit: doc.val().CreditLimit,
                CreditDays: doc.val().CreditDays,
                GSTNumber: doc.val().GSTNumber,
                GSTImage: doc.val().GSTImage,
                GSTType: doc.val().GSTType,
                GSTStateCode: doc.val().GSTStateCode,
                Pincode: doc.val().Pincode,
                OpeningBalance: doc.val().OpeningBalance,
                CustomerType: doc.val().CustomerType,
                OutStanding: doc.val().OutStanding,
                SalesMan: doc.val().SalesMan,
                UserId: doc.val().UserId,
                Image: doc.val().Image
            }
            newData.push(dict)
        })
        this.setState({
          firebaseData: newData
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.customerName === '') {
        alert("Enter Customer Name");
        document.getElementById("customerName").focus();
        return;
    }

    if(data.retailerName === '') {
      alert("Enter Retailer Name");
      document.getElementById("retailerName").focus();
      return;
    }

    if(data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.address === '') {
      alert("Enter Address");
      document.getElementById("address").focus();
      return;
    }

    if(data.creditLimit === '') {
      alert("Enter Credit Limit");
      document.getElementById("creditLimit").focus();
      return;
    }

    if(data.creditDays === '') {
      alert("Enter Credit Days");
      document.getElementById("creditDays").focus();
      return;
    }

    // if(data.gstNo === '') {
    //   alert("Enter GST Number");
    //   document.getElementById("gstNo").focus();
    //   return;
    // }

    // if(data.gstImage === '') {
    //   alert("Select GST Image");
    //   document.getElementById("gstImage").focus();
    //   return;
    // }

    // if(data.gstType === 'Select') {
    //     alert("Select GST Type");
    //     document.getElementById("gstType").focus();
    //     return;
    // }

    // if(data.gstStateCode === '') {
    //   alert("Enter GST State Code");
    //   document.getElementById("gstStateCode").focus();
    //   return;
    // }

    if(data.pincode === 'Select') {
      alert("Select Area");
      document.getElementById("pincode").focus();
      return;
    }

    if(data.openingBalance === '') {
      alert("Enter Opening Balance");
      document.getElementById("openingBalance").focus();
      return;
    }

    if(data.outStandingAmount === '') {
      alert("Enter OutStanding Amount");
      document.getElementById("outStandingAmount").focus();
      return;
    }

    if(data.customerType === 'Select') {
      alert("Select Customer Type");
      document.getElementById("customerType").focus();
      return;
    }

    if(data.image === '') {
      alert("Select Photo");
      document.getElementById("image").focus();
      return;
    }

       
    var tot=0;
    
    var stock=firebase.database().ref().child("CustomerId");              
    stock.transaction(function(currentstock) {
     tot = currentstock+1;   
    return tot;
    },
    function(error, committed, snapshot) {
    if (error) {
      console.log('Transaction failed abnormally!', error);
    } else if (committed) {


          var userid = "ST" +tot;
      

    var firebaseref = firebase.database().ref().child("Customer").child(userid)
    firebaseref.child("CustomerName").set(data.customerName)
    firebaseref.child("RetailerName").set(data.retailerName)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("VendorAddress").set(data.address)
    firebaseref.child("CreditLimit").set(data.creditLimit)
    firebaseref.child("CreditDays").set(data.creditDays)
    firebaseref.child("GSTNumber").set(data.gstNo)
    firebaseref.child("GSTImage").set(data.gstImage)
    firebaseref.child("GSTType").set(data.gstType)
    firebaseref.child("GSTStateCode").set(data.gstStateCode)
    firebaseref.child("Pincode").set(data.pincode)
    firebaseref.child("OpeningBalance").set(parseInt(data.openingBalance))
    firebaseref.child("Balances").set(parseInt(0))
    firebaseref.child("OutStanding").set(data.outStandingAmount)
    firebaseref.child("CustomerType").set(data.customerType)
    firebaseref.child("SalesMan").set(data.salesMan)
    firebaseref.child("UserId").set(userid)
    firebaseref.child("Image").set(data.image)

    comp.setState({
      success_msg: true
    })


    data.customerName = ""
    data.retailerName = ""
    data.mobileNumber = ""
    data.address = ""
    data.creditLimit = ""
    data.creditDays = ""
    data.gstNo = ""
    data.gstImage = ""
    data.gstType = "Select"
    data.gstStateCode = ""
    data.pincode = "Select"
    data.openingBalance = ""
    data.outStandingAmount = ""
    data.customerType = "Select"
    data.salesMan = ""
    data.image = ''
    document.getElementById("create-form").reset()
  }
})
}

gstUpload = e =>{
        
  if(e.target.files[0] === 0){
     alert("Add GST Image");
     return;
 }

 const ref = firebase.storage().ref("/Customer/");
 const file = e.target.files[0];
 const name = e.target.files[0] + Date();
 const metadata = {
 contentType: file.type
 };
 const task = ref.child(name).put(file, metadata);
 task
 .then(snapshot => snapshot.ref.getDownloadURL())
 .then((url) => {
   this.setState({
     gstImage: url
   })
 })
 .catch(console.error);         
}

sweetAlertOnConfirm(){
  let data = this.state
  this.setState({
      success_msg: false
  })
 
  data.customerName = ""
  data.retailerName = ""
  data.mobileNumber = ""
  data.address = ""
  data.creditLimit = ""
  data.creditDays = ""
  data.gstNo = ""
  data.gstImage = ""
  data.gstType = "Select"
  data.gstStateCode = ""
  data.pincode = "Select"
  data.openingBalance = ""
  data.outStandingAmount = ""
  data.customerType = "Select"
  data.salesMan = ""
  data.image = ""
  document.getElementById("create-form").reset()
}

editRow(key) {

  document.getElementById("submit").style.display = "none";
  document.getElementById("update").style.display = "block";

  let data = this.state
  data.customerName = key.CustomerName
  data.retailerName = key.RetailerName
  data.mobileNumber = key.MobileNumber
  data.address = key.Address
  data.creditLimit = key.CreditLimit
  data.creditDays = key.CreditDays
  data.gstNo = key.GSTNumber
  data.gstImage = key.GSTImage
  data.gstType = key.GSTType
  data.gstStateCode = key.GSTStateCode
  data.pincode = key.Pincode
  data.openingBalance = key.OpeningBalance
  data.customerType = key.CustomerType
  data.outStandingAmount = key.OutStanding
  data.image = key.Image

  if(key.SalesMan === undefined) {
    data.salesMan = ""
    document.getElementById("salesMan").value = ""
  } else {
    data.salesMan = key.SalesMan
    document.getElementById("salesMan").value = key.SalesMan
  }
  data.userid = key.UserId

  // if(key.GSTImage !== '') 
  // document.getElementById("imageView").style.visibility = "visible"

  document.getElementById("customerName").value = key.CustomerName
  document.getElementById("retailerName").value = key.RetailerName
  document.getElementById("mobileNumber").value = key.MobileNumber
  document.getElementById("address").value = key.Address
  document.getElementById("creditLimit").value = key.CreditLimit
  document.getElementById("creditDays").value = key.CreditDays
  document.getElementById("gstNo").value = key.GSTNumber
  // document.getElementById("imageView").href = key.GSTImage
  document.getElementById("gstType").value = key.GSTType
  document.getElementById("gstStateCode").value = key.GSTStateCode
  document.getElementById("pincode").value = key.Pincode
  document.getElementById("openingBalance").value = key.OpeningBalance
  document.getElementById("customerType").value = key.CustomerType
  document.getElementById("outStandingAmount").value = key.OutStanding

  window.scrollTo('top', 0)

}

delete(key) {
  if (window.confirm('Are you sure you want to delete the customer?')) {
      firebase.database().ref().child('Customer').child(key.UserId).remove()
      alert("Successfully Deleted!")
    } else {}
}

handleUpdate(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.customerName === '') {
        alert("Enter Customer Name");
        document.getElementById("customerName").focus();
        return;
    }

    if(data.retailerName === '') {
      alert("Enter Retailer Name");
      document.getElementById("retailerName").focus();
      return;
    }

    if(data.mobileNumber === '') {
      alert("Enter Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.mobileNumber.length !== 10) {
      alert("Enter Correct Mobile Number");
      document.getElementById("mobileNumber").focus();
      return;
    }

    if(data.address === '') {
      alert("Enter Address");
      document.getElementById("address").focus();
      return;
    }

    if(data.creditLimit === '') {
      alert("Enter Credit Limit");
      document.getElementById("creditLimit").focus();
      return;
    }

    if(data.creditDays === '') {
      alert("Enter Credit Days");
      document.getElementById("creditDays").focus();
      return;
    }

    // if(data.gstNo === '') {
    //   alert("Enter GST Number");
    //   document.getElementById("gstNo").focus();
    //   return;
    // }

    // if(data.gstImage === '') {
    //   alert("Select GST Image");
    //   document.getElementById("gstImage").focus();
    //   return;
    // }

    // if(data.gstType === 'Select') {
    //     alert("Select GST Type");
    //     document.getElementById("gstType").focus();
    //     return;
    // }

    // if(data.gstStateCode === '') {
    //   alert("Enter GST State Code");
    //   document.getElementById("gstStateCode").focus();
    //   return;
    // }

    if(data.pincode === 'Select') {
      alert("Select Area");
      document.getElementById("pincode").focus();
      return;
    }

    if(data.openingBalance === '') {
      alert("Enter Opening Balance");
      document.getElementById("openingBalance").focus();
      return;
    }

    if(data.outStandingAmount === '' || data.outStandingAmount === undefined) {
      alert("Enter OutStanding Amount");
      document.getElementById("outStandingAmount").focus();
      return;
    }

    if(data.customerType === 'Select') {
      alert("Select Customer Type");
      document.getElementById("customerType").focus();
      return;
    } 

    if(data.image === '' || data.image === undefined) {
      alert("Select Photo");
      document.getElementById("image").focus();
      return;
    }


    var firebaseref = firebase.database().ref().child("Customer").child(data.userid)
    firebaseref.child("CustomerName").set(data.customerName)
    firebaseref.child("RetailerName").set(data.retailerName)
    firebaseref.child("MobileNumber").set(data.mobileNumber)
    firebaseref.child("VendorAddress").set(data.address)
    firebaseref.child("CreditLimit").set(data.creditLimit)
    firebaseref.child("CreditDays").set(data.creditDays)
    firebaseref.child("GSTNumber").set(data.gstNo)
    firebaseref.child("GSTImage").set(data.gstImage)
    firebaseref.child("GSTType").set(data.gstType)
    firebaseref.child("GSTStateCode").set(data.gstStateCode)
    firebaseref.child("Pincode").set(data.pincode)
    firebaseref.child("OpeningBalance").set(parseInt(data.openingBalance))
    firebaseref.child("OutStanding").set(data.outStandingAmount)
    firebaseref.child("CustomerType").set(data.customerType)
    firebaseref.child("SalesMan").set(data.salesMan)
    firebaseref.child("Image").set(data.image)

    alert("Updated Successfully")


    data.customerName = ""
    data.retailerName = ""
    data.mobileNumber = ""
    data.address = ""
    data.creditLimit = ""
    data.creditDays = ""
    data.gstNo = ""
    data.gstImage = ""
    data.gstType = "Select"
    data.gstStateCode = ""
    data.pincode = "Select"
    data.openingBalance = ""
    data.customerType = "Select"
    data.outStandingAmount = ""
    data.salesMan = ""
    data.image = ''
    document.getElementById("create-form").reset()

    document.getElementById("submit").style.display = "block";
    document.getElementById("update").style.display = "none";
}

customerPhotoUpload = e =>{
        
  if(e.target.files[0] === 0){
     alert("Add Image");
     return;
 }

 const ref = firebase.storage().ref("/Customer/");
 const file = e.target.files[0];
 const name = e.target.files[0] + Date();
 const metadata = {
 contentType: file.type
 };
 const task = ref.child(name).put(file, metadata);
 task
 .then(snapshot => snapshot.ref.getDownloadURL())
 .then((url) => {
   this.setState({
     image: url
   })
 })
 .catch(console.error);         
}

myFunction() {
  var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
  var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
  input = document.getElementById("search");
  filter = input.value.toUpperCase();
  table = document.getElementById("dataTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
  td1 = tr[i].getElementsByTagName("td")[1];
  td2 = tr[i].getElementsByTagName("td")[2];
  td3 = tr[i].getElementsByTagName("td")[3];
  td4 = tr[i].getElementsByTagName("td")[4];
  td5 = tr[i].getElementsByTagName("td")[5];
  td6 = tr[i].getElementsByTagName("td")[6];
  td7 = tr[i].getElementsByTagName("td")[7];
  // td8 = tr[i].getElementsByTagName("td")[8];
  // td9 = tr[i].getElementsByTagName("td")[9];

  if (td1) {
    txtValue1 = td1.textContent || td1.innerText;
    txtValue2 = td2.textContent || td2.innerText;
    txtValue3 = td3.textContent || td3.innerText;
    txtValue4 = td4.textContent || td4.innerText;
    txtValue5 = td5.textContent || td5.innerText;
    txtValue6 = td6.textContent || td6.innerText;
    txtValue7 = td7.textContent || td7.innerText;
    // txtValue8 = td8.textContent || td8.innerText;
    // txtValue9 = td9.textContent || td9.innerText;
  
   var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6+txtValue7;
     if (main.toUpperCase().indexOf(filter) > -1) {
      tr[i].style.display = "";
    } else {
      tr[i].style.display = "none";
    }
  }       
}
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Master" breadcrumbItem="Account Master" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                      <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Customer Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="customerName"
                                                          placeholder="Customer Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="customerName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Retailer Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="retailerName"
                                                          placeholder="Retailer Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="retailerName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">Mobile Number<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mobileNumber"
                                                          placeholder="Mobile Number"
                                                          type="number"
                                                          className="form-control"
                                                          id="mobileNumber"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Address<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="address"
                                                          placeholder="Address"
                                                          type="text"
                                                          className="form-control"
                                                          id="address"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Limit<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="creditLimit"
                                                          placeholder="Credit Limit"
                                                          type="number"
                                                          className="form-control"
                                                          id="creditLimit"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Days<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="creditDays"
                                                          placeholder="Credit Days"
                                                          type="number"
                                                          className="form-control"
                                                          id="creditDays"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">GST Number</Label>
                                                        <Input
                                                          name="gstNo"
                                                          placeholder="GST Number"
                                                          type="text"
                                                          className="form-control"
                                                          id="gstNo"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">GST Image</Label>
                                                        <Input
                                                          name="gstImage"
                                                          placeholder="GST Number"
                                                          type="file"
                                                          className="form-control"
                                                          id="gstImage"
                                                          onChange = {this.gstUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4"> 
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">GST Type</Label>
                                                        <Input type="select" id = "gstType" name = "gstType" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "Select">Select GST Type</option>
                                                        <option value = "Regular">Regular</option>
                                                        <option value = "Compositor">Compositor</option>
                                                    </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">GST State Code</Label>
                                                        <Input
                                                          name="gstStateCode"
                                                          placeholder="GST State Code"
                                                          type="number"
                                                          className="form-control"
                                                          id="gstStateCode"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Area<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "pincode" name = "pincode" onChange = {this.handleChange.bind(this)}>
                                                            <option value = "Select">Select Area</option>
                                                            {this.state.pins.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.Pincode}>{city.Pincode}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Opening Balance<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="openingBalance"
                                                          placeholder="Opening Balance"
                                                          type="number"
                                                          className="form-control"
                                                          id="openingBalance"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">OutStanding Amount<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="outStandingAmount"
                                                          placeholder="Outstanding Amount"
                                                          type="number"
                                                          className="form-control"
                                                          id="outStandingAmount"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom05">Customer Type<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "customerType" name = "customerType" onChange = {this.handleChange.bind(this)}>
                                                        <option value = "Select">Select Customer Type</option>
                                                        <option value = "Creditor">Creditor</option>
                                                        <option value = "Debitor">Debitor</option>
                                                    </Input>
                                                    </FormGroup>
                                                </Col> 

                                                <Col md = "4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Sales Man</Label>
                                                        <Input type="select" id = "salesMan" name = "salesMan" onChange = {this.handleChange.bind(this)}>
                                                            <option value = "">Select Sales Man</option>
                                                            {this.state.sales.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.SalesName}>{city.SalesName}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4, offset: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Photo<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="image"
                                                          placeholder="image"
                                                          type="file"
                                                          className="form-control"
                                                          id="image"
                                                          onChange = {this.customerPhotoUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                            <Button color="primary" id = "submit" onClick = {this.handleSubmit.bind(this)}  type="submit">Submit</Button>
                                            <Button color="primary" id = "update" style = {{display: "none"}} onClick = {this.handleUpdate.bind(this)}  type="submit">Update</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={this.sweetAlertOnConfirm.bind(this)}
                                                  >
                                                    Customer Successfully Created!
                                                  </SweetAlert>
                                                ) : null}
                                                 
                                          </Form>
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

                                        {/* <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                    <Row>
                                            <Col md = "6">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                        </Row>
                                        <br />

                                      <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Photo</th>
                                                            <th>Customer Name</th>
                                                            <th>Retailer Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Address</th>
                                                            <th>GSTNumber</th>
                                                            <th>Area</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        {rowData.Image === '' || rowData.Image === undefined ?
                                                        <td><img src= {Logo} style = {{backgroundColor: "#2A3042"}} alt = "logo" className="img-responsive inline-block" width="100" height="100" /></td> :
                                                        <td><img src= {rowData.Image} alt = "salesman" className="img-responsive inline-block" width="100" height="100" /></td> }
                                                        <td>{rowData.CustomerName}</td>
                                                        <td>{rowData.RetailerName}</td>
                                                        <td>{rowData.MobileNumber}</td>
                                                        <td>{rowData.Address}</td>
                                                        <td>{rowData.GSTNumber}</td>
                                                        <td>{rowData.Pincode}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> */}

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default CreateCustomer;