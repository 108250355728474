import React, { Component } from "react";

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { TablePagination } from '@material-ui/core';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


class AttendanceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: [],
            fromDate: '',
            pushId: '',
            pages: [10, 20, 30, 50],
            page: 0,
            rowsPerPage:10
        };
    }

    componentDidMount() {
    let comp = this;

    var date = new Date()
    var todayDate = formatDate(date)
        
    firebase.database().ref().child("Attendance")
    .once("value", function(snapshot) {
        if(snapshot.exists()) {
            let newData = []
            snapshot.forEach(function(snapshot) {
                const del = {
                    Date: snapshot.val().Date,
                    Name: snapshot.val().Name,
                    Number: snapshot.val().Number, 
                    InTimePhoto: snapshot.val().InTimePhoto,
                    In: snapshot.val().In,
                    InLat: snapshot.val().InLat,
                    InLong: snapshot.val().InLong, 
                    Out: snapshot.val().Out,
                    OutTimePhoto: snapshot.val().OutTimePhoto,
                    OutLat: snapshot.val().OutLat,
                    OutLong: snapshot.val().OutLong, 
                    PushId: snapshot.val().PushId
                }
                newData.push(del)
            })
            comp.setState({
                firebaseData: newData
            })
        }
    })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
      }

      handleChangePage(event, newPage) {
        this.setState({
            page: newPage
        })
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    }

      myFunction() {
        var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
        var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
        td1 = tr[i].getElementsByTagName("td")[1];
        td2 = tr[i].getElementsByTagName("td")[2];
        td3 = tr[i].getElementsByTagName("td")[3];
        td4 = tr[i].getElementsByTagName("td")[4];
        td5 = tr[i].getElementsByTagName("td")[5];
        if (td1) {
          txtValue1 = td1.textContent || td1.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
          txtValue4 = td4.textContent || td4.innerText;
          txtValue5 = td5.textContent || td5.innerText;
        
         var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5;
           if (main.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    }


    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Reports" breadcrumbItem="Attendance Report" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    <Row>
                                            <Col md = "4">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            <Col md = "6"></Col>
                                        </Row>
                                        <br /><br />

                                        <div className="table-responsive">
                                            <Table className="table mb-0" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>Date</th>
                                                        <th>Name</th>
                                                        <th>Number</th>
                                                        <th>Check In</th>
                                                        <th>Check In Location</th>
                                                        <th>Check Out</th>
                                                        <th>Check Out Location</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                        <tr key = {index}>
                                                            <td>{index + 1}</td>
                                                            <td>{rowData.Date}</td>
                                                            <td>{rowData.Name}</td>
                                                            <td>{rowData.Number}</td>
                                                            <td><img src= {rowData.InTimePhoto} class="image" height = "100" width = "100" /><p>{rowData.In}</p></td>
                                                            <td><iframe width="200" height="100" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src = {`https://maps.google.com/maps?q=${rowData.InLat},${rowData.InLong}&hl=en&z=14&output=embed`}></iframe></td>
                                                            {rowData.Out !== undefined ? <>
                                                                <td><img src= {rowData.OutTimePhoto} class="image" height = "100" width = "100" /><p>{rowData.Out}</p></td>
                                                                <td><iframe width="200" height="100" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src = {`https://maps.google.com/maps?q=${rowData.OutLat},${rowData.OutLong}&hl=en&z=14&output=embed`}></iframe></td>
                                                            </> : <><td></td><td></td> </> }    
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AttendanceReport;