import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, CardSubtitle,Container, Button, Input, Label, FormGroup, Form, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import SweetAlert from "react-bootstrap-sweetalert";

class AddUsers extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            username: '',
            displayName: '',
            password: '',
            success_msg: false,
            firebaseData: [],
            payments1: false,
            receipts1: false,
            report1: false,
            report2: false,
            report3: false,
            report4: false,
            report5: false,
            masters1: false,
            masters2: false,
            masters3: false,
            user1: false
         };
    }


    componentDidMount() {
        firebase.database().ref().child("WebUsers")
            .on('value', (snapshot) => {
                if (snapshot.exists()) {
                    const newData = []
                    snapshot.forEach(function (doc) {
                        newData.push(doc.val())
                    })
                    this.setState({
                        firebaseData: newData
                    })
                }
            })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
      }

      handleSubmit(e) {
        e.preventDefault();

        let data = this.state

        let comp = this;

        if(data.displayName === '') {
            alert("Enter Name");
            document.getElementById("displayName").focus();
            return;
        }


        if(data.username === '') {
            alert("Enter UserName");
            document.getElementById("username").focus();
            return;
        }

        if(data.password === '') {
            alert("Enter Password");
            document.getElementById("password").focus();
            return;
        }

        let role = ""
        if(data.payments1) {
            role+= "payments1" + ","
        }

        if(data.receipts1) {
        role+= "receipts1" + ","
        }

        if(data.report1) {
            role+= "report1" + ","
        }
        if(data.report2) {
            role+= "report2" + ","
        }
        if(data.report3) {
            role+= "report3" + ","
        }
        if(data.report4) {
            role+= "report4" + ","
        }
        if(data.report5) {
            role+= "report5" + ","
        }

        if(data.masters1) {
            role+= "masters1" + ","
        }
        if(data.masters2) {
            role+= "masters2" + ","
        }
        if(data.masters3) {
            role+= "masters3" + ","
        }

        if(data.user1) {
            role+= "user1" + ","
        }

        let firebaseRef = firebase.database().ref().child('WebUsers').child(data.username);
        firebaseRef.child("Username").set(data.username)
        firebaseRef.child("Password").set(data.password)
        firebaseRef.child("Name").set(data.displayName)
        firebaseRef.child("Role").set(role)
        firebaseRef.child("Position").set("Admin")

        comp.setState({
            success_msg: true,
            username: '',
            displayName: '',
            password: '',
            payments1: false,
            receipts1: false,
            report1: false,
            report2: false,
            report3: false,
            report4: false,
            report5: false,
            masters1: false,
            masters2: false,
            masters3: false,
            user1: false
        })
    } 

    editRow(key) {
        document.getElementById("submit").style.display = "none";
        document.getElementById("update").style.display = "block";

        document.getElementById("username").disabled = true;

        this.setState({
            username: key.Username,
            password: key.Password,
            displayName: key.Name,
            payments1: false,
            receipts1: false,
            report1: false,
            report2: false,
            report3: false,
            report4: false,
            report5: false,
            masters1: false,
            masters2: false,
            masters3: false,
            user1: false
        })


        if(key.Role.includes("payments1")) {
            this.setState({
                payments1: true
            })
        }

        if(key.Role.includes("receipts1")) {
            this.setState({
                receipts1: true
            })
        }
        if(key.Role.includes("report1")) {
            this.setState({
                report1: true
            })
        }
        if(key.Role.includes("report2")) {
            this.setState({
                report2: true
            })
        }
        if(key.Role.includes("report3")) {
            this.setState({
                report3: true
            })
        }
        if(key.Role.includes("report4")) {
            this.setState({
                report4: true
            })
        }
        if(key.Role.includes("report5")) {
            this.setState({
                report5: true
            })
        }
        if(key.Role.includes("masters1")) {
            this.setState({
                masters1: true
            })
        }
        if(key.Role.includes("masters2")) {
            this.setState({
                masters2: true
            })
        }
        if(key.Role.includes("masters3")) {
            this.setState({
                masters3: true
            })
        }
        if(key.Role.includes("user1")) {
            this.setState({
                user1: true
            })
        }

        window.scrollTo('top', 0)

    }

    handleUpdate(e) {
        e.preventDefault();

        let data = this.state
        let comp = this;

        if(data.displayName === '') {
            alert("Enter Name");
            document.getElementById("displayName").focus();
            return;
        }


        if(data.username === '') {
            alert("Enter UserName");
            document.getElementById("username").focus();
            return;
        }

        if(data.password === '') {
            alert("Enter Password");
            document.getElementById("password").focus();
            return;
        }

        let role = ""
        if(data.payments1) {
            role+= "payments1" + ","
        }

        if(data.receipts1) {
        role+= "receipts1" + ","
        }

        if(data.report1) {
            role+= "report1" + ","
        }
        if(data.report2) {
            role+= "report2" + ","
        }
        if(data.report3) {
            role+= "report3" + ","
        }
        if(data.report4) {
            role+= "report4" + ","
        }
        if(data.report5) {
            role+= "report5" + ","
        }

        if(data.masters1) {
            role+= "masters1" + ","
        }
        if(data.masters2) {
            role+= "masters2" + ","
        }
        if(data.masters3) {
            role+= "masters3" + ","
        }

        if(data.user1) {
            role+= "user1" + ","
        }

        let firebaseRef = firebase.database().ref().child('WebUsers').child(data.username);
        firebaseRef.child("Username").set(data.username)
        firebaseRef.child("Password").set(data.password)
        firebaseRef.child("Name").set(data.displayName)
        firebaseRef.child("Role").set(role)

        alert("Updated Successfully")

        comp.setState({
            success_msg: false,
            username: '',
            displayName: '',
            password: '',
            payments1: false,
            receipts1: false,
            report1: false,
            report2: false,
            report3: false,
            report4: false,
            report5: false,
            masters1: false,
            masters2: false,
            masters3: false,
            user1: false
        })

        document.getElementById("submit").style.display = "block";
        document.getElementById("update").style.display = "none";

        document.getElementById("username").disabled = false;
    } 


    delete(key) {
        if (window.confirm('Are you sure you want to delete the user?')) {
            firebase.database().ref().child('WebUsers').child(key.Username).remove()
            alert("Successfully Deleted!")
        } else { }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="User Management" breadcrumbItem="Add Web Users" />
                <Row>
                    <Col>
                        <Card>
                        <CardBody>
                        <Form id = "create-form">
                        <Row>
                        <Col md="4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom02">Name<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="displayName"
                                      placeholder="Display Name"
                                      type="text"
                                      className="form-control"
                                      id="displayName"
                                      onChange = {this.handleChange.bind(this)}
                                      value = {this.state.displayName}
                                    />
                                </FormGroup>
                            </Col>
                                <Col md= {{size: 4, offset: 2}}>
                                    <FormGroup>
                                        <Label htmlFor="validationCustom03">Username</Label>
                                        <Input
                                        name="username"
                                        placeholder="UserName"
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        onChange = {this.handleChange.bind(this)}
                                        value = {this.state.username}
                                        />
                                    </FormGroup>
                                </Col>

                            <Col md="4">
                                <FormGroup>
                                    <Label htmlFor="validationCustom04">Password<span style = {{color: "red"}}>*</span></Label>
                                    <Input
                                      name="password"
                                      placeholder="Password"
                                      type="password"
                                      className="form-control"
                                      id="password"
                                      onChange = {this.handleChange.bind(this)}
                                      value = {this.state.password}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <br />


                                                <Row>
                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Payments</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="payments1" onChange={() => false} checked={this.state.payments1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ payments1: !this.state.payments1 }) }} >Payments</label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Receipts</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="receipts1" onChange={() => false} checked={this.state.receipts1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ receipts1: !this.state.receipts1 }) }} >Receipts</label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                
                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Reports</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report1" onChange={() => false} checked={this.state.report1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report1: !this.state.report1 }) }} >Retailers Report </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report2" onChange={() => false} checked={this.state.report2} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report2: !this.state.report2 }) }} >Employee Cases </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report3" onChange={() => false} checked={this.state.report3} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report3: !this.state.report3 }) }} >Ledger Report </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report4" onChange={() => false} checked={this.state.report4} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report4: !this.state.report4 }) }} >Attendance Report </label>
                                                        </div>


                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="report5" onChange={() => false} checked={this.state.report5} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ report5: !this.state.report5 }) }} >Sales Reports </label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">Masters</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters1" onChange={() => false} checked={this.state.masters1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters1: !this.state.masters1 }) }} >Add Retailers </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters2" onChange={() => false} checked={this.state.masters2} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters2: !this.state.masters2 }) }} >Add Employees </label>
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="masters3" onChange={() => false} checked={this.state.masters3} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ masters3: !this.state.masters3 }) }} >Upload Daily Sales </label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={2}>
                                                    <div className="mt-4 mt-lg-0">
                                                        <h5 className="font-size-14 mb-4">WebUsers</h5>
                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <input type="checkbox" className="custom-control-input" id="user1" onChange={() => false} checked={this.state.user1} />
                                                            <label className="custom-control-label" onClick={() => { this.setState({ user1: !this.state.user1 }) }} >Add Users </label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                </Row>


                        
                                               
                        <Button color="primary" id = "submit" onClick = {this.handleSubmit.bind(this)} type="submit">Submit</Button>
                        <Button color="primary" id="update" style={{ display: "none" }} onClick={this.handleUpdate.bind(this)} type="submit">Update</Button>

                        {this.state.success_msg ? (
                                                <SweetAlert
                                                  title="Good job!"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ success_msg: false })}
                                                >
                                                  Web User Created Successfully!
                                                </SweetAlert>
                                              ) : null}
                            </Form>
                            </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id="dataTable">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>Name</th>
                                                        <th>Username</th>
                                                        <th>Password</th>
                                                        <th>Details</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.firebaseData.map((rowData, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{rowData.Name}</td>
                                                            <td>{rowData.Username}</td>
                                                            <td>{rowData.Password}</td>
                                                            <td onClick={this.editRow.bind(this, rowData)}><i style={{ color: "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer" }} className="fas fa-edit"></i></td>
                                                            <td onClick={this.delete.bind(this, rowData)}><i style={{ color: "red", fontSize: "15px", padding: "10px", cursor: "pointer" }} className="fas fa-trash"></i></td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default AddUsers;
