import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, NavLink, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import CardText from "reactstrap/lib/CardText";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'
import Input from "reactstrap/lib/Input";

class Sales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingOrders: [],
            acceptedOrders: [],
            dispatchedOrders: [],
            deliveredOrders: [],
            activeTabJustify: "1",
            itemOpen: false,
            cart: [],
            deliveryPartners: [],
            deliveryNumber: '',
            deliveryName: '',
            topIndex: '-1'
        };
        this.togglemodal.bind(this);
        this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
    }

    toggleCustomJustified(tab) {
		if (this.state.activeTabJustify !== tab) {
			this.setState({
				activeTabJustify: tab
			});
		}
    }
    
    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount() {
        let comp = this

        firebase.database().ref().child("Masters").child("DeliveryPartner")
        .once("value", function(snapshot) {
            if(snapshot.exists()) {
                let del = []
                snapshot.forEach(function(snap) {
                    del.push(snap.val())
                })
                comp.setState({
                    deliveryPartners: del
                })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("1")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let newData1 = []
                var status = ""
                snapshot.forEach(function(snapshot) {
                  const lock = {
                      Address: snapshot.val().Address,
                      CName: snapshot.val().CName,
                      Flat: snapshot.val().Flat,
                      DeliveryCharges: snapshot.val().DeliveryCharges,
                      Discount: snapshot.val().Discount,
                      Number: snapshot.val().Number,
                      OrderDate: snapshot.val().OrderDate,
                      OrderDateTime: snapshot.val().OrderDateTime,
                      OrderNo: snapshot.val().OrderNo,
                      OrderType: snapshot.val().OrderType,
                      Packing: snapshot.val().Packing,
                      Payment: snapshot.val().Payment,
                      PaymentId: snapshot.val().PaymentId,
                      Status: snapshot.val().Status,
                      PushId: snapshot.val().Pushid,
                      Qty: snapshot.val().Qty,
                      SubTotal: snapshot.val().SubTotal,
                      Total: snapshot.val().Total,
                      UserId: snapshot.val().UserId
                  }
                  newData1.push(lock)
                })
                comp.setState({
                    pendingOrders: newData1
                })
            } else {
                comp.setState({
                    pendingOrders: []
                })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("2")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let newData2 = []
                var status = ""
                snapshot.forEach(function(snapshot) {
                  const lock = {
                    Address: snapshot.val().Address,
                    CName: snapshot.val().CName,
                    Flat: snapshot.val().Flat,
                    DeliveryCharges: snapshot.val().DeliveryCharges,
                    Discount: snapshot.val().Discount,
                    Number: snapshot.val().Number,
                    OrderDate: snapshot.val().OrderDate,
                    OrderDateTime: snapshot.val().OrderDateTime,
                    OrderNo: snapshot.val().OrderNo,
                    OrderType: snapshot.val().OrderType,
                    Packing: snapshot.val().Packing,
                    Payment: snapshot.val().Payment,
                    PaymentId: snapshot.val().PaymentId,
                    Status: snapshot.val().Status,
                    PushId: snapshot.val().Pushid,
                    Qty: snapshot.val().Qty,
                    SubTotal: snapshot.val().SubTotal,
                    Total: snapshot.val().Total,
                    UserId: snapshot.val().UserId,
                    DeliveryPartnerName: snapshot.val().DeliveryPartnerName,
                    DeliveryPartnerNumber: snapshot.val().DeliveryPartnerNumber,
                  }
                  newData2.push(lock)
                })
                comp.setState({
                    acceptedOrders: newData2
                })
            } else {
                comp.setState({
                    acceptedOrders: []
                })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("3")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let newData3 = []
                var status = ""
                snapshot.forEach(function(snapshot) {
                  const lock = {
                    Address: snapshot.val().Address,
                    CName: snapshot.val().CName,
                    Flat: snapshot.val().Flat,
                    DeliveryCharges: snapshot.val().DeliveryCharges,
                    Discount: snapshot.val().Discount,
                    Number: snapshot.val().Number,
                    OrderDate: snapshot.val().OrderDate,
                    OrderDateTime: snapshot.val().OrderDateTime,
                    OrderNo: snapshot.val().OrderNo,
                    OrderType: snapshot.val().OrderType,
                    Packing: snapshot.val().Packing,
                    Payment: snapshot.val().Payment,
                    PaymentId: snapshot.val().PaymentId,
                    Status: snapshot.val().Status,
                    PushId: snapshot.val().Pushid,
                    Qty: snapshot.val().Qty,
                    SubTotal: snapshot.val().SubTotal,
                    Total: snapshot.val().Total,
                    UserId: snapshot.val().UserId
                  }
                  newData3.push(lock)
                })
                comp.setState({
                    dispatchedOrders: newData3
                })
            } else {
                comp.setState({
                    dispatchedOrders: []
                })
            }
        })

        
        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("4")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
                let newData4 = []
                var status = ""
                snapshot.forEach(function(snapshot) {
                  const lock = {
                    Address: snapshot.val().Address,
                    CName: snapshot.val().CName,
                    Flat: snapshot.val().Flat,
                    DeliveryCharges: snapshot.val().DeliveryCharges,
                    Discount: snapshot.val().Discount,
                    Number: snapshot.val().Number,
                    OrderDate: snapshot.val().OrderDate,
                    OrderDateTime: snapshot.val().OrderDateTime,
                    OrderNo: snapshot.val().OrderNo,
                    OrderType: snapshot.val().OrderType,
                    Packing: snapshot.val().Packing,
                    Payment: snapshot.val().Payment,
                    PaymentId: snapshot.val().PaymentId,
                    Status: snapshot.val().Status,
                    PushId: snapshot.val().Pushid,
                    Qty: snapshot.val().Qty,
                    SubTotal: snapshot.val().SubTotal,
                    Total: snapshot.val().Total,
                    UserId: snapshot.val().UserId
                  }
                  newData4.push(lock)
                })
                comp.setState({
                    deliveredOrders: newData4
                })
            } else {
                comp.setState({
                    deliveredOrders: []
                })
            }
        })
    }

    viewModal2(key) {
        this.setState({
            itemOpen: true,
        })
        let comp = this

      
        firebase.database().ref().child("Orders").child(key.PushId).child("Cart")
        .once("value", function(snapshot) {
          var carts = [];
          snapshot.forEach(function(doc) {
            const lock = {
              Image: doc.val().Image,
              Name: doc.val().Name,
              Price: doc.val().Price,
              Qty: doc.val().Qty,
              Total: doc.val().Total,
              Category: doc.val().Category,
              Units: doc.val().Units,
              Type: doc.val().Type,
              PushId: doc.val().PushId
            }
            carts.push(lock)
          });
          comp.setState({
            cart: carts
          })
      });
      }
      
      closeModal2() {
        this.setState({
            itemOpen: false
        })
      }

      acceptOrder(key) {
        if (window.confirm('Are you sure you want to accept the order?')) {
            firebase.database().ref().child('Orders').child(key.PushId).child("Status").set("2")
            alert("Order Accepted Successfully!")
          } else {}
    }

    assignDeliveryAgent(key, index) {
        let data = this.state

        // console.log(data.topIndex)
        // console.log(index)

        if(index !== data.topIndex) {
            alert("Select Delivery Agent")
            return
        }

        var ref = firebase.database().ref().child('Orders').child(key.PushId)
        ref.child("DeliveryPartnerNumber").set(data.deliveryNumber)
        ref.child("DeliveryPartnerName").set(data.deliveryName)
        ref.child("DeliveryPartnerStatus").set(data.deliveryNumber)

        alert("Delivery Partner Assigned Successfully!")
    }

    deliveryOnChange(e,index) {
        this.setState({
            [e.target.name]: e.target.value,
            topIndex: index
        })
      
        firebase.database().ref().child("Masters").child("DeliveryPartner").child(e.target.value).child("DeliveryName")
        .once("value",(snapshot) => {
            this.setState({
              deliveryName:snapshot.val()
          })
        })
      }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Sales" breadcrumbItem="Sales" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "1"
													})}
													onClick={() => {
														this.toggleCustomJustified("1");
													}}
												>
													<span className="d-none d-sm-block">Pending Orders</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "2"
													})}
													onClick={() => {
														this.toggleCustomJustified("2");
													}}
												>
													<span className="d-none d-sm-block">Accepted Orders</span>
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "3"
													})}
													onClick={() => {
														this.toggleCustomJustified("3");
													}}
												>
													<span className="d-none d-sm-block">Dispatched Orders</span>
												</NavLink>
											</NavItem>
                                            <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: this.state.activeTabJustify === "4"
													})}
													onClick={() => {
														this.toggleCustomJustified("4");
													}}
												>
													<span className="d-none d-sm-block">Delivered Orders</span>
												</NavLink>
											</NavItem>
										</Nav>

										<TabContent activeTab={this.state.activeTabJustify}>
                                        <TabPane tabId="1" className="p-3">
												<Row>
													<Col sm="12">
														<CardText>
                                                        <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Custumer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Delivery Address</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.pendingOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.viewModal2.bind(this, rowData)} type="submit">View</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Payment}</td>
                                                                        <td>{rowData.Address}</td>
                                                                        <td><Button color="success" onClick = {this.acceptOrder.bind(this, rowData)} type="submit">Accept</Button></td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                          								</CardText>
													</Col>
												</Row>
											</TabPane>
											<TabPane tabId="2" className="p-3">
												<Row>
													<Col sm="12">
                                                    <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Custumer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Delivery Address</th>
                                                                    <th>Delivery Partner</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.acceptedOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.viewModal2.bind(this, rowData)} type="submit">View</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Payment}</td>
                                                                        <td>{rowData.Address}</td>
                                                                        <td>{rowData.DeliveryPartnerName} <br />
                                                                        <Input type="select" id = "deliveryNumber" name = "deliveryNumber" onChange={(event) => this.deliveryOnChange(event,index)}>
                                                                            <option value = "">Select Delivery Partner</option>
                                                                            {this.state.deliveryPartners.map((city, index) => {
                                                                                return (
                                                                                    <option key = {index} value={city.MobileNumber}>{city.DeliveryName +"-" + city.MobileNumber}</option>
                                                                                );
                                                                            })};
                                                                        </Input>
                                                                        </td>
                                                                        <td><Button color="info" onClick = {this.assignDeliveryAgent.bind(this, rowData, index)} type="submit">Assign</Button></td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
													</Col>
												</Row>
											</TabPane>
											<TabPane tabId="3" className="p-3">
												<Row>
													<Col sm="12">
														<CardText>
                                                        <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Custumer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Delivery Address</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.dispatchedOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.viewModal2.bind(this, rowData)} type="submit">View</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Payment}</td>
                                                                        <td>{rowData.Address}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                          								</CardText>
													</Col>
												</Row>
											</TabPane>
                                            <TabPane tabId="4" className="p-3">
												<Row>
													<Col sm="12">
														<CardText>
                                                        <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th>Order Date Time</th>
                                                                    <th>Order Id</th>
                                                                    <th>Custumer</th>
                                                                    <th>Items</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Total</th>
                                                                    <th>Payment Method</th>
                                                                    <th>Delivery Address</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.deliveredOrders.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td>{rowData.OrderDateTime}</td>
                                                                        <td>{rowData.OrderNo}</td>
                                                                        <td>{rowData.CName}<br />{rowData.Number}</td>
                                                                        <td><Button color="danger" onClick = {this.viewModal2.bind(this, rowData)} type="submit">View</Button></td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Total}</td>
                                                                        <td>{rowData.Payment}</td>
                                                                        <td>{rowData.Address}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                          								</CardText>
													</Col>
												</Row>
											</TabPane>
										</TabContent>

                                        <Modal isOpen={this.state.itemOpen} toggle={this.closeModal2.bind(this)}>
                                            <ModalHeader toggle={this.closeModal2.bind(this)}><h3>Items</h3></ModalHeader>
                                                <ModalBody>
                                                <div className="table-responsive">
                                                        <table className="table table-centered table-nowrap mb-0">
                                                            <thead className="thead-light">
                                                            <tr>
                                                                    <th>Image</th>
                                                                    <th>Name</th>
                                                                    <th>Category</th>
                                                                    <th>Units</th>
                                                                    <th>Qty</th>
                                                                    <th>Price</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                                <tbody>
                                                                {this.state.cart.map((rowData, index) => (
                                                                    <tr key = {index}>
                                                                        <td><img src= {rowData.Image} alt = "Item" className="img-responsive inline-block" width="50" height="50" /></td>
                                                                        <td>{rowData.Name}</td>
                                                                        <td>{rowData.Category}</td>
                                                                        <td>{rowData.Units}</td>
                                                                        <td>{rowData.Qty}</td>
                                                                        <td>{rowData.Price}</td>
                                                                        <td>{rowData.Total}</td>
                                                                    </tr>

                                                                ))}
                                                                </tbody>
                                                        </table>
                                                    </div>
                                                </ModalBody>
                                            </Modal>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Sales;
