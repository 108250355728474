import React, { Component } from "react";

import { Row, Col, Card, CardBody ,Container, Input, Form, Table, NavLink, Button, CardTitle} from "reactstrap";

import Nav from "reactstrap/lib/Nav";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import classnames from "classnames";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      topIndex: '-1',
      customerName: '',
      customerNumber: '',
      moveToSale: false
    };
  }

//   viewModal2(key) {
//     this.setState({
//         itemOpen: true,
//     })
//     let comp = this

  
//     firebase.database().ref().child("Orders").child(key.PushId).child("Cart")
//     .once("value", function(snapshot) {
//       var carts = [];
//       snapshot.forEach(function(doc) {
//         const lock = {
//           Image: doc.val().Image,
//           Name: doc.val().Name,
//           Price: doc.val().Price,
//           Qty: doc.val().Qty,
//           Total: doc.val().Total,
//           Category: doc.val().Category,
//           Units: doc.val().Units,
//           Type: doc.val().Type,
//           PushId: doc.val().PushId
//         }
//         carts.push(lock)
//       });
//       comp.setState({
//         cart1: carts
//       })
//   });
//   }


  componentDidMount() {
    var orderPushId = sessionStorage.getItem("orderPushId")
    let comp = this

        firebase.database().ref().child("Orders").child(orderPushId)
        .on('value', function(snapshot) {
        if(snapshot.exists()) {
          const newData = [];
          let newItems = []
          snapshot.child("Cart").forEach(function(doc) {
                const lock = {
                    Image: doc.val().Image,
                    Name: doc.val().Name,
                    Price: doc.val().Price,
                    Qty: doc.val().Qty,
                    Total: doc.val().Total,
                    Category: doc.val().Category,
                    Units: doc.val().Units,
                    Type: doc.val().Type,
                    PushId: doc.val().PushId
                }
                newItems.push(lock)
            });
            comp.setState({
                firebaseData: newItems,
                customerName: snapshot.val().CName,
                customerNumber: snapshot.val().Number,
                address: snapshot.val().Address,
            })
        }
    })
  }



  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  backToOrder() {
      this.setState({
          moveToSale: true
      })
  }


  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUprCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

handleSubmit(e) {
    e.preventDefault();

    let data = this.state
    let comp = this

    let orderPushId = sessionStorage.getItem("orderPushId")

    firebase.database().ref().child("Orders").child(orderPushId)
    .once("value", function(snapshot) {
      if(snapshot.exists()) {
        let totalQty = 0
        let totalAmount = 0
        let tax = 0
        let deliveryCharges = 0
        let discount = 0
        let finalTotal = 0
        let extraSum = 0
        let itemDetails = ""
  
        deliveryCharges = snapshot.val().DeliveryCharges
        discount = snapshot.val().Discount
        tax = snapshot.val().Taxes
  
        console.log("Delivery Charges", deliveryCharges)
        console.log("Discount",discount)
        console.log("Tax", tax)
  
        extraSum = ((parseFloat(deliveryCharges) + parseFloat(tax)) - (parseFloat(discount))).toFixed(2)
  
        snapshot.child("Cart").forEach(function(snapshot) {
          totalQty = (parseFloat(totalQty) + parseFloat(snapshot.val().Qty))
          totalAmount = (parseFloat(totalAmount) + parseFloat(snapshot.val().Total)).toFixed(2)
          itemDetails = itemDetails + snapshot.val().Name + ":" + snapshot.val().Qty + ","
        })
        console.log("Total QTY", totalQty)
        console.log("Sub Toatl", totalAmount)
        console.log("Extra Sum", extraSum)
  
        finalTotal = (parseFloat(totalAmount) + parseFloat(extraSum)).toFixed(2)
        console.log("FInal total", finalTotal)
  
      var ref = firebase.database().ref().child("Orders").child(orderPushId)
      ref.child("ItemDetails").set(String(itemDetails))
      ref.child("Qty").set(String(totalQty))
      ref.child("Subtotal").set(String(totalAmount))
      ref.child("Total").set(String(finalTotal))
  
      alert("Updated Successfully")
      }

      comp.setState({
          moveToSale: true
      })
    })
}


quantityOnChange(evt, key, index) {
	const value = evt.target.value;
  console.log(value)

  var orderPushId = sessionStorage.getItem("orderPushId")

  if(value !== '') {
    let total = (parseFloat(value) * parseFloat(key.Price)).toFixed(2)
    console.log(total)

    var firebaseref = firebase.database().ref().child("Orders").child(orderPushId).child("Cart").child(key.PushId)
    firebaseref.child("Qty").set(String(value))
    firebaseref.child("Total").set(String(total))
  }
}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Entry" breadcrumbItem="Order Details" />
            {this.state.moveToSale ?
            <Redirect to  = "/create-invoice"/> : null}
                    <Row id = "case-list">
                            <Col lg={12}>
                                <Card>
                                    <CardBody id = "user-list">
                                            <FontAwesomeIcon icon = {faArrowLeft} style = {{cursor: "pointer", fontSize: "15px"}} onClick = {this.backToOrder.bind(this)} /> <br /><br />

                                                    <div>
                                                        <h6>Customer Name: {this.state.customerName}</h6>
                                                        <h6>Customer Number: {this.state.customerNumber}</h6>
                                                        <h6>Address: {this.state.address}</h6>
                                                    </div>
                                                    <br />
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                    <th>Image</th>    
                                                                    <th>Name</th>
                                                                    <th>Category</th>
                                                                    <th>Units</th>
                                                                    <th>Quantity</th>
                                                                    <th>Price</th>
                                                                    <th>Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.firebaseData.map((itemData, index) => (
                                                                    <tr key = {index}>
                                                                        <td><img src= {itemData.Image} alt = "Item" className="img-responsive inline-block" width="50" height="50" /></td>
                                                                        <td>{itemData.Name}</td>
                                                                        <td>{itemData.Category}</td>
                                                                        <td>{itemData.Units}</td>
                                                                        <td><textarea type = "text" rows = {1} cols = {30} id = "quantity" name = "quantity" defaultValue = {itemData.Qty} onChange={(event) => this.quantityOnChange(event, itemData, index)}></textarea></td>
                                                                        <td>{itemData.Price}</td>
                                                                        <td>{itemData.Total}</td>
                                                                    </tr>
                                                                    ))}
                                                                </tbody>
                                                                </Table>

                                                                <Button color="primary" onClick = {this.handleSubmit.bind(this)}>Update</Button>
                                                </CardBody>
                                    </Card>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default OrderDetails;