import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, FormGroup, Label, Button } from "reactstrap";
import Input from "reactstrap/lib/Input";
import { TablePagination } from '@material-ui/core';
import Select from 'react-select';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import firebase from '../../firebase'

class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firebaseData: [],
            startDate: '',
            endDate: '',
            pages: [50, 100, 300],
            page: 0,
            rowsPerPage: 50,
            topIndex: '-1',
            agents: [],
            agentName: '',
            agentNumber: '',
            customerIds: [],
            customerNames: [],
            custs: [],
            selectedMulti: [],
            customerId: []
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangePage(event, newPage) {
        let comp = this
      
          this.setState({
              page: newPage
          })
      }
      
      handleChangeRowsPerPage(event) {
         this.setState({
             rowsPerPage: parseInt(event.target.value, 10),
             page: 0
         })
      }

    componentDidMount() {
        let comp = this

        firebase.database().ref().child("Retailer")
        .once('value',(snapshot) => {
            if(snapshot.exists()) {
            //   const customerIds = []
            //   const customerNames = []
              let newData = []
              snapshot.forEach(function(doc) {
                //   customerIds.push(doc.val().UserId)
                //   customerNames.push(doc.val().RetailerName)
                  const dict = {
                    options: [ {
                    label: doc.val().RetailerName,
                    value: doc.val().UserId
                    }]
                }
                newData.push(dict)
              })
              comp.setState({
                // customerNames: customerNames,
                // customerIds: customerIds,
                custs: newData
              })
            }
        })

        firebase.database().ref().child("Sales")
        .on('value',(snapshot) => {
            if(snapshot.exists()) {
              const newData = []
              snapshot.forEach(function(doc) {
                  newData.push(doc.val())
              })
              comp.setState({
                firebaseData: newData
              })
            } else {
              comp.setState({
                firebaseData: []
              })
            }
        })
    }

    handleMulti = customerId => {
        this.setState({ customerId });
    
        let comp = this    
        console.log(customerId.value)
    
    firebase.database().ref().child("Sales")
    .orderByChild("RetailerUserId").equalTo(customerId.value)
    .once("value", function(snapshot) {
        if(snapshot.exists()) {
            const newData = []
            snapshot.forEach(function(doc) {
                newData.push(doc.val())
            })
            comp.setState({
              firebaseData: newData
            })
          } else {
            comp.setState({
              firebaseData: []
            })
          }
    });
}

    myFunction() {
        var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
        var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
        input = document.getElementById("search");
        filter = input.value.toUpperCase();
        table = document.getElementById("dataTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
        td1 = tr[i].getElementsByTagName("td")[1];
        td2 = tr[i].getElementsByTagName("td")[2];
        td3 = tr[i].getElementsByTagName("td")[3];
        // td4 = tr[i].getElementsByTagName("td")[4];
        // td5 = tr[i].getElementsByTagName("td")[5];
        // td6 = tr[i].getElementsByTagName("td")[6];
        // td7 = tr[i].getElementsByTagName("td")[7];
        // td8 = tr[i].getElementsByTagName("td")[8];
        // td9 = tr[i].getElementsByTagName("td")[9];

        if (td1) {
          txtValue1 = td1.textContent || td1.innerText;
          txtValue2 = td2.textContent || td2.innerText;
          txtValue3 = td3.textContent || td3.innerText;
        //   txtValue4 = td4.textContent || td4.innerText;
        //   txtValue5 = td5.textContent || td5.innerText;
        //   txtValue6 = td6.textContent || td6.innerText;
        //   txtValue7 = td7.textContent || td7.innerText;
        //   txtValue8 = td8.textContent || td8.innerText;
        //   txtValue9 = td9.textContent || td9.innerText;
        
         var main = txtValue1+ txtValue2+txtValue3;
           if (main.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">

                        <Breadcrumbs title="Report" breadcrumbItem="Sales Report" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md = "4">
                                            <form>
                                                <Input
                                                    placeholder="Search for..."
                                                    value={this.state.query}
                                                    onKeyUp = {this.myFunction.bind(this)}
                                                    className = "form-control"
                                                    id = "search"
                                                />
                                                </form>
                                            </Col>
                                            {/* <Col md = "6"></Col>
                                            <Col md = "2">
                                            <Button color="primary" onClick = {this.exportToCSV.bind(this, this.state.firebaseData, "User Report")} type="submit">Excel</Button>
                                            <ReactHTMLTableToExcel  
                                                className="btn btn-primary"  
                                                table="dataTableExcel"  
                                                filename="Delivery Agent Report"  
                                                sheet="Sheet"  
                                                buttonText="Excel" />  
                                            </Col> */}
                                        </Row>
                                        <br /><br />

                                        <Row>
                                            <Col md = "4"> 
                                                <FormGroup className="select2-container">
                                                      <label className="control-label">Retailer Name</label>
                                                      <Select
                                                        value = {this.state.customerId}
                                                        // isMulti={true}
                                                        onChange={this.handleMulti}
                                                        options={this.state.custs}
                                                        classNamePrefix="select1-selection"
                                                        id = "customerId"
                                                        placeholder = "Select Retailer"
                                                      />
                                                  </FormGroup>
                                                </Col>
                                        </Row>

                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <Table className="table mb-0 table-striped table-bordered" id = "dataTable">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Retailer Name</th>
                                                            <th>Sales Date</th>
                                                            <th>Sales Number</th>
                                                            <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                     {this.state.firebaseData.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage).map((rowData, index) => (
                                                        <tr key = {index}>
                                                            <td>{index + 1}</td>
                                                            <td>{rowData.RetailerName}</td>
                                                            <td>{rowData.SalesDate}</td>
                                                            <td>{rowData.SalesNumber}</td>
                                                            <td>{rowData.Amount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                        <TablePagination
                                                component="div"
                                                page={this.state.page}
                                                rowsPerPageOptions={this.state.pages}
                                                rowsPerPage={this.state.rowsPerPage}
                                                count={this.state.firebaseData.length}
                                                onChangePage={this.handleChangePage.bind(this)}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                                            />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SalesReport;
