import React, { Component } from "react";
import { Container, Row, Col, Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table } from "reactstrap";
// import { Link } from "react-router-dom";

//import Charts
// import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import firebase from '../../firebase'

// Pages Components
// import WelcomeComp from "./WelcomeComp";
// import MonthlyEarning from "./MonthlyEarning";
// import SocialSource from "./SocialSource";
// import ActivityComp from "./ActivityComp";
// import TopCities from "./TopCities";
// import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Bar, Line } from 'react-chartjs-2';

//i18n
import { withNamespaces } from 'react-i18next';
import { Redirect } from "react-router-dom";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [
                // { title: "Orders", iconClass: "bx-cart-alt", description: "0" },
                { title: "Revenue", iconClass: "bx-archive-in", description: "0" },
                { title: "Users", iconClass: "bx-user", description: "0" }
            ],
            email: [
                { title: "Week", linkto: "#", isActive: false },
                { title: "Month", linkto: "#", isActive: false },
                { title: "Year", linkto: "#", isActive: true }
            ],
            modal: false,
            moveTo: '',
            redirectTo: false,

            customers: '0',
            products: '0',
            totalTurnover: '0',
            deliveredOrders: '0',
            todaysOrder: '0',
            pendingOrders: '0',
            acceptedOrders: '0',
            dispatchedOrders: '0',
            options: {
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              },
              data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                  {
                    label: 'Monthly Sales',
                    data: [],
                    backgroundColor: 'rgb(60, 179, 113)',
                  },
                ],
              },
              data1: {
                labels: [],
                datasets: [
                  {
                    label: 'Yearly Sales',
                    fill: false,
                    lineTension: 0.1,
                    data: [],
                    backgroundColor: 'rgb(255, 165, 0)',
                    borderColor: 'rgba(255, 165, 0)',
                  },
                ],
              },
              options1: {
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }
        };
        this.togglemodal.bind(this);
    }

    componentDidMount() {
        let comp = this

        var today = new Date()
        var currentDate = formatDate(today)

        firebase.database().ref().child("Customer")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let customers = 0
              snapshot.forEach(function(doc) {
                  customers = customers + 1
              })
              comp.setState({
                customers: customers
              })
            }
        })

        firebase.database().ref().child("Products")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let products = 0
              snapshot.forEach(function(doc) {
                  products = products + 1
              })
              comp.setState({
                products: products
              })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("4")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let deliveredOrders = 0
              let totalTurnover = 0
              snapshot.forEach(function(doc) {
                  deliveredOrders = deliveredOrders + 1
                  totalTurnover = (parseFloat(totalTurnover) + parseFloat(doc.val().Total)).toFixed(2)
              })
              comp.setState({
                deliveredOrders: deliveredOrders,
                totalTurnover: totalTurnover
              })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("OrderDate").equalTo(currentDate)
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let todaysOrder = 0
              snapshot.forEach(function(doc) {
                  todaysOrder = todaysOrder + 1
              })
              comp.setState({
                todaysOrder: todaysOrder,
              })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("1")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let pendingOrders = 0
              snapshot.forEach(function(doc) {
                  pendingOrders = pendingOrders + 1
              })
              comp.setState({
                pendingOrders: pendingOrders,
              })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("2")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let acceptedOrders = 0
              snapshot.forEach(function(doc) {
                  acceptedOrders = acceptedOrders + 1
              })
              comp.setState({
                acceptedOrders: acceptedOrders,
              })
            }
        })

        firebase.database().ref().child("Orders")
        .orderByChild("Status").equalTo("3")
        .on("value", function(snapshot) {
            if(snapshot.exists()) {
              let dispatchedOrders = 0
              snapshot.forEach(function(doc) {
                  dispatchedOrders = dispatchedOrders + 1
              })
              comp.setState({
                dispatchedOrders: dispatchedOrders,
              })
            }
        })

        let yearsData = []

        var oneYearFromNow = new Date();
        var previousYear = oneYearFromNow.getFullYear() - 1;
        var twoPreviousYear = oneYearFromNow.getFullYear() - 2;

        yearsData.push(twoPreviousYear)
        yearsData.push(previousYear)
        yearsData.push(oneYearFromNow.getFullYear())
        yearsData.push(oneYearFromNow.getFullYear() + 1)
        yearsData.push(oneYearFromNow.getFullYear() + 2)
        yearsData.push(oneYearFromNow.getFullYear() + 3)
        yearsData.push(oneYearFromNow.getFullYear() + 4)
        yearsData.push(oneYearFromNow.getFullYear() + 5)
        yearsData.push(oneYearFromNow.getFullYear() + 6)
        yearsData.push(oneYearFromNow.getFullYear() + 7)



        let ordersMonthlyWise = Array(12).fill(0)
        let ordersYearlyWise = Array(10).fill(0)

        let ordersDate = []
        let ordersDateValue = []


        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;


        var year=yyyy;

        firebase.database().ref().child("Orders")
        .once("value", function(snapshot) {
            if(snapshot.exists()) {
                snapshot.forEach(function(snap) {
                    if(snap.val().Total !== undefined) {
                        ordersDate.push(snap.val().OrderDate)
                        ordersDateValue.push(snap.val().Total)
                    }
                })
            }

        // console.log("Order Date", ordersDate)
        // console.log("Order Date Value", ordersDateValue)
    })




        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-01-01").endAt(year+"-01-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[0]=parseFloat(total).toFixed(2);
            }   
        }); 

        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-02-01").endAt(year+"-02-28")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[1]=parseFloat(total).toFixed(2);
            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-03-01").endAt(year+"-03-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[2]=parseFloat(total).toFixed(2);

            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-04-01").endAt(year+"-04-30")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[3]=parseFloat(total).toFixed(2);
            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-05-01").endAt(year+"-05-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[4]=parseFloat(total).toFixed(2);

            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-06-01").endAt(year+"-06-30")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[5]=parseFloat(total).toFixed(2);

            }   
        }); 

        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-07-01").endAt(year+"-07-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[6]=parseFloat(total).toFixed(2);

            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-08-01").endAt(year+"-08-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[7]=parseFloat(total).toFixed(2);

            }   
        }); 



        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-09-01").endAt(year+"-09-30")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[8]=parseFloat(total).toFixed(2);

            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-10-01").endAt(year+"-10-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[9]=parseFloat(total).toFixed(2);

            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-11-01").endAt(year+"-11-30")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[10]=parseFloat(total).toFixed(2);

            }   
        }); 


        firebase.database().ref().child("Orders")
            .orderByChild('OrderDate').startAt(year+"-12-01").endAt(year+"-12-31")
            .once("value", function(snapshot) {
            if(snapshot.exists()){
                var total=0;
                var number=snapshot.numChildren();
                snapshot.forEach(function(data){
                    var val = data.val();
                    if(val.Total!=null && val.Total != "NaN")
                    total=total + +parseFloat(val.Total);
                }); 
                ordersMonthlyWise[11]=parseFloat(total).toFixed(2);

             } 
            setTimeout(function() {

                for(let i=0; i< yearsData.length; i++) {
                    for(let j=0; j< ordersDate.length; j++) {
                        if(ordersDate[j].includes(yearsData[i])) {
                            ordersYearlyWise[i] = (parseFloat(ordersYearlyWise[i]) + parseFloat(ordersDateValue[j])).toFixed(2)
                        }
                    }
                }
        
                comp.setState({
                    data: {
                      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                      datasets: [
                        {
                          label: 'Monthly Orders',
                          data: ordersMonthlyWise,
                          backgroundColor: 'rgb(60, 179, 113)',
                        },
                      ],
                    },

                    data1: {
                        labels: yearsData,
                        datasets: [
                          {
                            label: 'Yearly Sales',
                            fill: false,
                            lineTension: 0.1,
                            data: ordersYearlyWise,
                            backgroundColor: 'rgb(255, 165, 0)',
                            borderColor: 'rgba(255, 165, 0)',
                          },
                        ],
                      }
                })  
            }, 2000)
 
        })
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    moveToParticularPage(pagePath) {
        this.setState({
            moveTo: pagePath,
            redirectTo: true
        })
    }

    render() {
        return (
            <React.Fragment>
                 {this.state.redirectTo ? <Redirect to = {this.state.moveTo} /> : null }
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('Dashboard')} breadcrumbItem={this.props.t('Dashboard')} />

                        <Row>
                            {/* <Col xl="4">
                                <WelcomeComp />
                                <MonthlyEarning />
                            </Col> */}
                            <Col xl="12">
                                <Row>
                                    {/* Reports Render */}
                                    <Col md="3">
                                        <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/customer-report")}>
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p className="text-muted font-weight-medium">Customers</p>
                                                        <h4 className="mb-0">{this.state.customers}</h4>
                                                    </Media>
                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                        <span className="avatar-title">
                                                            <i className={"bx " + "bx-archive-in" + " font-size-24"}></i>
                                                        </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/products-list")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Products</p>
                                                    <h4 className="mb-0">{this.state.products}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/sales-report")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Total Turnover</p>
                                                    <h4 className="mb-0">{this.state.totalTurnover}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/sales-report")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Delivered Orders</p>
                                                    <h4 className="mb-0">{this.state.deliveredOrders}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/sales")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Today's Order</p>
                                                    <h4 className="mb-0">{this.state.todaysOrder}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/sales")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Pending Orders</p>
                                                    <h4 className="mb-0">{this.state.pendingOrders}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/sales")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Accepted Orders</p>
                                                    <h4 className="mb-0">{this.state.acceptedOrders}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>

                                    <Col md="3">
                                    <Card className="mini-stats-wid" style = {{cursor: "pointer"}} onClick = {this.moveToParticularPage.bind(this, "/sales")}>
                                        <CardBody>
                                            <Media>
                                                <Media body>
                                                    <p className="text-muted font-weight-medium">Dispatched Orders</p>
                                                    <h4 className="mb-0">{this.state.dispatchedOrders}</h4>
                                                </Media>
                                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                    <span className="avatar-title">
                                                        <i className={"bx " + "bx-user" + " font-size-24"}></i>
                                                    </span>
                                                </div>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                    </Col>
                                </Row>

                                <br />
                                <br />

                                <Row>
                                    <Col md="6">
                                        <Card className="mini-stats-wid">
                                            <CardBody style = {{cursor: "pointer"}}>
                                                <Media>
                                                    <Media body>
                                                        <Bar data={this.state.data} width={100} height={50} options={this.state.options} />
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col md="6">
                                        <Card className="mini-stats-wid">
                                            <CardBody style = {{cursor: "pointer"}}>
                                                <Media>
                                                    <Media body>
                                                        <Line data={this.state.data1} width={100} height={50} options={this.state.options1} />
                                                    </Media>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                {/* <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 float-sm-left">
                                            Email Sent
                                        </CardTitle>
                                        <div className="float-sm-right">
                                            <ul className="nav nav-pills">
                                                {
                                                    this.state.email.map((mail, key) =>
                                                        <li className="nav-item" key={"_li_" + key}>
                                                            <Link className={mail.isActive ? "nav-link active" : "nav-link"} to={mail.linkto}>{mail.title}</Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="clearfix"></div>
                                        <StackedColumnChart />
                                    </CardBody>
                                </Card> */}
                            </Col>
                        </Row>

                        <Row>
                            <Col xl="4">
                                {/* <SocialSource /> */}
                            </Col>
                            <Col xl="4">
                                {/* <ActivityComp /> */}
                            </Col>

                            <Col xl="4">
                                {/* <TopCities /> */}
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                {/* <LatestTranaction /> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal isOpen={this.state.modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={this.togglemodal}>
                    <div className="modal-content">
                        <ModalHeader toggle={this.togglemodal}>
                            Order Details
                        </ModalHeader >
                        <ModalBody>
                            <p className="mb-2">Product id: <span className="text-primary">#SK2540</span></p>
                            <p className="mb-4">Billing Name: <span className="text-primary">Neal Matthews</span></p>

                            <div className="table-responsive">
                                <Table className="table table-centered table-nowrap">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <div>
                                                    <img src={modalimage1} alt="" className="avatar-sm" />
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                                                    <p className="text-muted mb-0">$ 225 x 1</p>
                                                </div>
                                            </td>
                                            <td>$ 255</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div>
                                                    <img src={modalimage2} alt="" className="avatar-sm" />
                                                </div>
                                            </th>
                                            <td>
                                                <div>
                                                    <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                                                    <p className="text-muted mb-0">$ 145 x 1</p>
                                                </div>
                                            </td>
                                            <td>$ 145</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Sub Total:</h6>
                                            </td>
                                            <td>$ 400</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Shipping:</h6>
                                            </td>
                                            <td>Free</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <h6 className="m-0 text-right">Total:</h6>
                                            </td>
                                            <td>$ 400</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={this.togglemodal}>Close</Button>
                        </ModalFooter>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
