import React, { Component } from "react";

import { Row, Col, Card, CardBody ,Container, Input, Form, Table, NavLink, Button, CardTitle} from "reactstrap";

import Nav from "reactstrap/lib/Nav";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import logo from "../../assets/images/sh.svg";

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faInfo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import Select from 'react-select';
import classnames from "classnames";

function formatDate(date) {
	var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    if (month.length < 2)
    month = '0' + month;
    if (day.length < 2)
    day = '0' + day;
    return [year, month, day].join('-');
}


class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      topIndex: '-1',
      customerName: '',
      customerNumber: '',
      moveToSale: false
    };
  }

//   viewModal2(key) {
//     this.setState({
//         itemOpen: true,
//     })
//     let comp = this

  
//     firebase.database().ref().child("Orders").child(key.PushId).child("Cart")
//     .once("value", function(snapshot) {
//       var carts = [];
//       snapshot.forEach(function(doc) {
//         const lock = {
//           Image: doc.val().Image,
//           Name: doc.val().Name,
//           Price: doc.val().Price,
//           Qty: doc.val().Qty,
//           Total: doc.val().Total,
//           Category: doc.val().Category,
//           Units: doc.val().Units,
//           Type: doc.val().Type,
//           PushId: doc.val().PushId
//         }
//         carts.push(lock)
//       });
//       comp.setState({
//         cart1: carts
//       })
//   });
//   }


  componentDidMount() {
        var orderPushId = sessionStorage.getItem("orderPushId")
        let comp = this
    
            firebase.database().ref().child("Orders").child(orderPushId)
            .once('value', function(snapshot) {
            if(snapshot.exists()) {
              const newData = [];
              let newItems = []
              let count = 0
              snapshot.child("Cart").forEach(function(doc) {
                  if(doc.val().PushId !== undefined) {
                      count = count + 1
                    const lock = {
                        Image: doc.val().Image,
                        Name: doc.val().Name,
                        Price: doc.val().Price,
                        Qty: doc.val().Qty,
                        Total: doc.val().Total,
                        Category: doc.val().Category,
                        Units: doc.val().Units,
                        Type: doc.val().Type,
                        PushId: doc.val().PushId,
                        MRPPrice: doc.val().MRPPrice
                    }
                    newItems.push(lock)
                }
                });

                let deliveryCharges = "0.00"
                if(snapshot.val().DeliveryCharges !== undefined) {
                    deliveryCharges = parseFloat(snapshot.val().DeliveryCharges).toFixed(2)
                }

                let discount = "0.00"
                if(snapshot.val().discount !== undefined) {
                    discount = parseFloat(snapshot.val().Discount).toFixed(2)
                }

                let lessCashDiscount = "0.00"
                if(snapshot.val().LessCashDiscount !== undefined) {
                    lessCashDiscount = parseFloat(snapshot.val().LessCashDiscount).toFixed(2)
                }

                let lessSpecialDiscount = "0.00"
                if(snapshot.val().LessSpecialDiscount !== undefined) {
                    lessSpecialDiscount = parseFloat(snapshot.val().LessSpecialDiscount).toFixed(2)
                }

                let lessDiscountAmount = "0.00"
                if(snapshot.val().LessDiscountAmount !== undefined) {
                    lessDiscountAmount = parseFloat(snapshot.val().LessDiscountAmount).toFixed(2)
                }

                let taxes = "0.00"
                if(snapshot.val().Taxes !== undefined) {
                    taxes = parseFloat(snapshot.val().Taxes).toFixed(2)
                }

                let gstNo = ""
                if(snapshot.val().GSTNumber !== undefined) {
                    gstNo = (snapshot.val().GSTNumber)
                }

                let area = ""
                if(snapshot.val().Area !== undefined) {
                    area = (snapshot.val().Area)
                }

                comp.setState({
                    firebaseData: newItems,
                    customerName: snapshot.val().CName,
                    customerNumber: snapshot.val().Number,
                    address: snapshot.val().Address,
                    deliveryCharges: deliveryCharges,
                    discount: discount,
                    orderId: snapshot.val().OrderNo,
                    orderDate: snapshot.val().OrderDateTime,
                    subTotal: snapshot.val().Subtotal,
                    grandTotal: snapshot.val().Total,
                    taxes: snapshot.val().Taxes,
                    totalQty: snapshot.val().Qty,
                    lessCashDiscount: lessCashDiscount,
                    lessSpecialDiscount: lessSpecialDiscount,
                    lessDiscountAmount: lessDiscountAmount,
                    taxes: taxes,
                    numberOfItems: count,
                    gstNo: gstNo,
                    area: area
                })
            }
        })

        setTimeout(function() {
            window.print()
        }, 2000)
  }



  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  backToOrder() {
      this.setState({
          moveToSale: true
      })
  }


  myFunction() {
    var input, filter, table, tr, td1,td2,td3,td4,td5,td6,td7,td8,td9,td10,td11,td12;
    var i,txtValue1,txtValue2,txtValue3,txtValue4,txtValue5,txtValue6,txtValue7,txtValue8,txtValue9,txtValue10,txtValue11,txtValue12;
    input = document.getElementById("search");
    filter = input.value.toUprCase();
    table = document.getElementById("dataTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td1 = tr[i].getElementsByTagName("td")[1];
    td2 = tr[i].getElementsByTagName("td")[2];
    td3 = tr[i].getElementsByTagName("td")[3];
    td4 = tr[i].getElementsByTagName("td")[4];
    td5 = tr[i].getElementsByTagName("td")[5];
    td6 = tr[i].getElementsByTagName("td")[6];
    td7 = tr[i].getElementsByTagName("td")[7];
    if (td1) {
      txtValue1 = td1.textContent || td1.innerText;
      txtValue2 = td2.textContent || td2.innerText;
      txtValue3 = td3.textContent || td3.innerText;
      txtValue4 = td4.textContent || td4.innerText;
      txtValue5 = td5.textContent || td5.innerText;
      txtValue6 = td6.textContent || td6.innerText;
      txtValue7 = td7.textContent || td7.innerText;
    
    var main = txtValue1+ txtValue2+txtValue3+txtValue4+txtValue5+txtValue6 + txtValue7;
      if (main.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }       
  }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* <Breadcrumbs title="Entry" breadcrumbItem="Order Details" /> */}
            {this.state.moveToSale ?
            <Redirect to  = "/create-invoice"/> : null}
                    <Row id = "case-list">
                            <Col lg={12}> 
                            <center>
                                <span className="logo-lg">
                                    {/* <img src={logo} alt="" height="60" style = {{backgroundColor: "#2A3042"}} /> */}
                                </span> 
                                <br />    <br />        
                                    <h4><b>Sri Ram Footwear INVOICE</b></h4>
                                    {/* <p>Address</p> */}
                                    {/* <p>GST No</p> */}
                                    {/* <p>Phone No</p> */}
                                    <br />
                                    </center>

                                    <h4>Order Details</h4><br />
                                     <div class = "row">
                                        <div class = "col-md-6" style = {{border: "1px solid black", fontWeight: "bold"}}>
                                            <br />
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h6> Order Id</h6>
                                                </div>
                                                <div class="col-md-8">
                                                    <h6>: {this.state.orderId}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h6> Order Date</h6>
                                                </div>
                                                <div class="col-md-8">
                                                <h6>: {this.state.orderDate}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h6>Total Quantity</h6>
                                                </div>
                                                <div class="col-md-8">
                                                <h6>: {this.state.totalQty}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h6>Number of Items</h6>
                                                </div>
                                                <div class="col-md-8">
                                                    <h6>: {this.state.numberOfItems}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h6>Delivery Charges</h6>
                                                </div>
                                                <div class="col-md-8">
                                                <h6>: {this.state.deliveryCharges}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h6> Order Total</h6>
                                                </div>
                                                <div class="col-md-8">
                                                <h6>: {this.state.subTotal}</h6>
                                                </div>
                                            </div>
                                        </div>
                                              <div class="col-md-6" style = {{border: "1px solid black"}}>
                                                <br />
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h6>Customer Name</h6>
                                                    </div>
                                                    <div class="col-md-8">
                                                    <h6>: {this.state.customerName}</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h6>Customer Number</h6>
                                                    </div>
                                                    <div class="col-md-8">
                                                    <h6>: {this.state.customerNumber}</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h6>Address</h6>
                                                    </div>
                                                    <div class="col-md-8">
                                                    <h6>: {this.state.address}</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h6>GST No</h6>
                                                    </div>
                                                    <div class="col-md-8">
                                                    <h6>: {this.state.gstNo}</h6>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <h6>Area</h6>
                                                    </div>
                                                    <div class="col-md-8">
                                                    <h6>: {this.state.area}</h6>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                            <br />
                                            <h4>Item Details</h4><br />
                                    <div class="table">
                                        <table id="data-table" class="table align-items-center table-flush">
                                          <thead class="thead-light">
                                            <tr>
                                                <th scope="col">Sl.No</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Units</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">MRP Price</th>
                                                <th scope="col">Price</th>
                                                <th scope="col" style = {{textAlign: "right"}}>Total</th>
                                            </tr>
                                          </thead>
                                          <tbody id="datatableItems">
                                            {this.state.firebaseData.map((itemData, index) => (
                                            <tr key = {index}>
                                                <td>{index + 1}</td>
                                                <td>{itemData.Name}</td>
                                                <td>{itemData.Units}</td>
                                                <td>{itemData.Qty}</td>
                                                {itemData.MRPPrice !== undefined ?
                                                <td>{itemData.MRPPrice}</td> : <td>200.00</td> }
                                                <td>{itemData.Price}</td>
                                                <td style = {{textAlign: "right"}}>{itemData.Total}</td>
                                            </tr>
                                            ))}
                                          </tbody>
                                        </table>

                                        <table id="data-table" class="table align-items-center table-flush">
                                            <tbody style = {{fontWeight: "bold"}}>
                                                <tr>
                                                    <td colspan="5">SubTotal</td>
                                                    <td style = {{textAlign: "right"}}><b><span>{this.state.subTotal}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>Delivery Charges</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "deliveryCharges1"> + {this.state.deliveryCharges}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>Discount</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "discount"> - {this.state.discount}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>Less Cash Discout (%)</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "deliveryCharges1"> - {this.state.lessCashDiscount}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>Less Special Discount (%)</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "discount"> - {this.state.lessSpecialDiscount}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>Less Discount Amount</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "discount"> - {this.state.lessDiscountAmount}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>GST</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "discount"> + {this.state.taxes}</span></b></td>
                                                </tr>

                                                <tr>
                                                    <td colSpan = "5"><b>Grand Total</b></td>
                                                    <td style = {{textAlign: "right"}}><b><span id = "gtotal">{this.state.grandTotal}</span></b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br></br>
                                        <div>
                                          <strong>Note:</strong> This is a system generated invoice hence signature is not required.
                                        </div>
                                </div>
                            </Col>
                        </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default Invoice;