import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
            new MetisMenu("#side-menu");

            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (this.props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                this.activateParentDropdown(matchingMenuItem);
            }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        var role = localStorage.getItem("role")
        if(role === null) {
            role = sessionStorage.getItem("role")
        }

        var position = localStorage.getItem("position")
        if(position === null) {
            position = sessionStorage.getItem("position")
        }
        return (
            <React.Fragment>
                {position === "SUPER_ADMIN" ?
                 <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    {/* <li className="menu-title">{this.props.t('Menu') }</li> */}
                     {/* <li>
                         <Link to="/#" className="waves-effect">
                                    <i className="bx bx-home-circle"></i><span className="badge badge-pill badge-info float-right">03</span>
                                    <span>{this.props.t('Dashboards') }</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/dashboard">{this.props.t('Default') }</Link></li>
                                    <li><Link to="/dashboard-saas">{this.props.t('Saas') }</Link></li>
                                    <li><Link to="/dashboard-crypto">{this.props.t('Crypto') }</Link></li>
                                </ul>
                     </li> */}

                     <li>
                        <Link className="waves-effect" to="dashboard">
                            <i className="fas fa-home mr-2"></i>{this.props.t('Dashboard')}
                        </Link>
                    </li>

                    <li>
                        <Link className="waves-effect" to="payments">
                            <i className="fas fa-coins mr-2"></i>{this.props.t('Payments')}
                        </Link>
                    </li>

                    <li>
                        <Link className="waves-effect" to="receipt">
                            <i className="fas fa-receipt mr-2"></i>{this.props.t('Receipt')}
                        </Link>
                    </li>

                    {/* <li>
                        <Link className="waves-effect" to="sales">
                            <i className="fas fa-shopping-basket mr-2"></i>{this.props.t('Sales')}
                        </Link>
                    </li> */}

                    {/* <li>
                        <Link className="waves-effect" to="create-invoice">
                            <i className="fas fa-shopping-basket mr-2"></i>{this.props.t('Create Invoice')}
                        </Link>
                    </li> */}

                    {/* <li>
                        <Link className="waves-effect" to="bulk-upload">
                            <i className="fas fa-upload mr-2"></i>{this.props.t('Bulk Upload')}
                        </Link>
                    </li> */}

                    {/* <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-user"></i>
                            <span>{this.props.t('Customer')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="create-customer">{this.props.t('Create Customer')}</Link></li>
                            <li><Link to="customer-list">{this.props.t('Customer List')}</Link></li>
                        </ul>
                    </li> */}

                    {/* <li>
                        <Link className="waves-effect" to="payments">
                            <i className="fas fa-coins mr-2"></i>{this.props.t('Payments')}
                        </Link>
                    </li> */}


                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-edit"></i>
                            <span>{this.props.t('Masters')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="add-retailer">{this.props.t('Add Retailers')}</Link></li>
                             <li><Link to="add-employee">{this.props.t('Add Employees')}</Link></li>
                             <li><Link to="upload-daily-sales">{this.props.t('Upload Daily Sales')}</Link></li>
                            {/* <li><Link to="pincode">{this.props.t('Area Master')}</Link></li>
                            <li><Link to="sales-agent">{this.props.t('Sales Man')}</Link></li> */}
                            {/* <li><Link to="delivery-partner">{this.props.t('Delivery Partner')}</Link></li> */}
                            {/* <li><Link to="products">{this.props.t('Inventory')}</Link></li>
                            <li><Link to="category">{this.props.t('Group')}</Link></li>
                            <li><Link to="size">{this.props.t('Size')}</Link></li>
                            <li><Link to="bulk-upload">{this.props.t('Account Bulk Upload')}</Link></li>
                            <li><Link to="products-bulk-upload">{this.props.t('Inventory Bulk Upload')}</Link></li> */}


                            {/* <li><Link to="sales-agent">{this.props.t('Sales Agent')}</Link></li>
                            <li><Link to="route">{this.props.t('Route')}</Link></li>
                            <li><Link to="delivery-partner">{this.props.t('Delivery Partner')}</Link></li>
                            <li><Link to="products-bulk-upload">{this.props.t('Products Bulk Upload')}</Link></li>
                            <li><Link to="products-list">{this.props.t('Products List')}</Link></li>
                            <li><Link to="category">{this.props.t('Category')}</Link></li>
                            <li><Link to="promocode">{this.props.t('Promocode')}</Link></li> */}
                        </ul>
                    </li>

                    {/* <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-edit"></i>
                            <span>{this.props.t('Entry')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="/create-invoice">{this.props.t('Sales')}</Link></li>
                            <li><Link to="/sales-return">{this.props.t('Sales Return')}</Link></li>
                            <li><Link to="/create-vendor-invoice">{this.props.t('Purchase')}</Link></li>
                            <li><Link to="/purchase-return">{this.props.t('Purchase Return')}</Link></li>
                            <li><Link to="/receipt">{this.props.t('Recipts')}</Link></li>
                            <li><Link to="payments">{this.props.t('Payment')}</Link></li>
                            <li><Link to="/blank">{this.props.t('Journal Entry')}</Link></li>
                            <li><Link to="/attendance">{this.props.t('Attendance')}</Link></li>


                            <li><Link to="sales-agent">{this.props.t('Sales Agent')}</Link></li>
                            <li><Link to="route">{this.props.t('Route')}</Link></li>
                            <li><Link to="delivery-partner">{this.props.t('Delivery Partner')}</Link></li>
                            <li><Link to="products-bulk-upload">{this.props.t('Products Bulk Upload')}</Link></li>
                            <li><Link to="products-list">{this.props.t('Products List')}</Link></li>
                            <li><Link to="category">{this.props.t('Category')}</Link></li>
                            <li><Link to="promocode">{this.props.t('Promocode')}</Link></li>
                        </ul>
                    </li> */}


                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-chart-pie"></i>
                            <span>{this.props.t('Reports')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="retailer-report">{this.props.t('Retailers Report')}</Link></li>
                            <li><Link to="employee-report">{this.props.t('Employees Report')}</Link></li>
                            <li><Link to="customer-payment-report">{this.props.t('Ledger Report')}</Link></li>
                            {/* <li><Link to="outstanding-report">{this.props.t('Outstanding Report')}</Link></li> */}
                            <li><Link to="attendance">{this.props.t('Attendance Report')}</Link></li>
                            <li><Link to="sales-report">{this.props.t('Sales Report')}</Link></li>
                            {/* <li><Link to="/blank">{this.props.t('OutStanding')}</Link></li> */}
                            {/* <li><Link to="customer-payment-report">{this.props.t('Customer Payment Report')}</Link></li> */}
                        </ul>
                    </li>

                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-user"></i>
                            <span>{this.props.t('Web Users')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="add-users">{this.props.t('Add Users')}</Link></li>
                        </ul>
                    </li>

                    {/* <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="fas fa-user-friends"></i>
                            <span>{this.props.t('User Creation')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li><Link to="user-list">{this.props.t('Web User List')}</Link></li>
                            <li><Link to="add-user">{this.props.t('Add Web User')}</Link></li>
                        </ul>
                    </li> */}
                </ul>
            </div>
            :

            <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">

                <li>
                    <Link className="waves-effect" to="dashboard">
                        <i className="fas fa-home mr-2"></i>{this.props.t('Dashboard')}
                    </Link>
                </li>


                    <li>
                    {role.includes("payments1") ?
                        <Link className="waves-effect" to="payments">
                            <i className="fas fa-coins mr-2"></i>{this.props.t('Payments')}
                        </Link> : null }
                    </li>


                    <li>
                    {role.includes("receipts1") ?
                        <Link className="waves-effect" to="receipt">
                        <i className="fas fa-receipt mr-2"></i>{this.props.t('Receipt')}
                    </Link> : null } 
                    </li>



                    <li>
                    {role.includes("masters") ?
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-edit"></i>
                        <span>{this.props.t('Masters')}</span>
                    </Link> : null }

                    <ul className="sub-menu" aria-expanded="false">
                        {role.includes("masters1") ?
                         <li><Link to="add-retailer">{this.props.t('Add Retailers')}</Link></li> : null }

                        {role.includes("masters2") ?
                          <li><Link to="add-employee">{this.props.t('Add Employees')}</Link></li> : null }

                        {role.includes("masters3") ?
                         <li><Link to="upload-daily-sales">{this.props.t('Upload Daily Sales')}</Link></li> : null }
                    </ul>
                </li>


                <li>
                {role.includes("report") ?
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-chart-pie"></i>
                        <span>{this.props.t('Reports')}</span>
                    </Link> : null }

                    <ul className="sub-menu" aria-expanded="false">
                        {role.includes("report1") ?
                            <li><Link to="retailer-report">{this.props.t('Retailers Report')}</Link></li> : null }

                        {role.includes("report2") ?
                        <li><Link to="employee-report">{this.props.t('Employees Report')}</Link></li> : null }

                        {role.includes("report3") ?
                         <li><Link to="customer-payment-report">{this.props.t('Ledger Report')}</Link></li> : null }

                        {role.includes("report4") ?
                          <li><Link to="attendance">{this.props.t('Attendance Report')}</Link></li> : null }

                        {role.includes("report5") ?
                         <li><Link to="sales-report">{this.props.t('Sales Report')}</Link></li> : null }
                    </ul>
                </li>

                

                <li>
                {role.includes("user") ?
                    <Link to="/#" className="has-arrow waves-effect">
                        <i className="fas fa-user"></i>
                        <span>{this.props.t('User management')}</span>
                    </Link> : null }
                    <ul className="sub-menu" aria-expanded="false">

                    {role.includes("user1") ?
                        <li><Link to="add-users">{this.props.t('Add Users')}</Link></li> : null }
                    </ul>
                </li>
            </ul>
            </div> }
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(SidebarContent));
