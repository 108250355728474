import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label ,Container, Input, Form, Table} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import SweetAlert from "react-bootstrap-sweetalert";
import firebase from '../../firebase';
import { faTrash, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firebaseData: [],
      success_msg: false,
      update_msg: false,
      productName: '',
      categories: [],
      category: 'Select',
      codPrice: '',
      creditPrice: '',
      sizes: [],
      size: 'Select',
      stock: '',
      productImage: '',
      categoryName: '',
      sizeName: '',
      pushId: '',
      mrpPrice: '',
      weights: [{}],
    };
  }

  componentDidMount() {
    firebase.database().ref().child("Masters").child("Category")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                CategoryName: doc.val().CategoryName,
                PushId: doc.val().PushId
            }
            newData.push(dict)
        })
        this.setState({
          categories: newData
        })
      }
    })

    firebase.database().ref().child("Masters").child("Size")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                SizeName: doc.val().SizeName,
                PushId: doc.val().PushId
            }
            newData.push(dict)
        })
        this.setState({
          sizes: newData
        })
      }
    })

    firebase.database().ref().child("Products")
    .on('value',(snapshot) => {
      if(snapshot.exists()) {
        const newData = []
        snapshot.forEach(function(doc) {
            const dict = {
                Name: doc.val().Name,
                Category: doc.val().Category,
                CODPrice: doc.val().CODPrice,
                CreditPrice: doc.val().CreditPrice,
                Size: doc.val().Size,
                Stock: doc.val().Stock,
                Image: doc.val().Image,
                PushId: doc.val().PushId,
                CategoryPushId: doc.val().CategoryPushId,
                SizePushId: doc.val().SizePushId
            }
            newData.push(dict)
        })
        this.setState({
          firebaseData: newData
        })
      } else {
        this.setState({
          firebaseData: []
        })
      }
    })
  }


  handleChange(event) {
    this.setState({
        [event.target.name]: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let data = this.state

    let comp = this


    if(data.productName === '') {
        alert("Enter Product Name");
        document.getElementById("productName").focus();
        return;
    }

    if(data.category === 'Select') {
        alert("Select Category");
        document.getElementById("category").focus();
        return;
    }

    // if(data.productImage === '') {
    //   alert("Select Product Image");
    //   document.getElementById("productImage").focus();
    //   return;
    // }

    var myTab = document.getElementById('datatable');
    if(myTab.rows.length === 0){
        alert("Add Size and Price");
        return;
    }

    var firebaseref = firebase.database().ref().child("Products").push()
    firebaseref.child("Name").set(data.productName)
    firebaseref.child("CategoryPushId").set(data.category)
    firebaseref.child("Category").set(data.categoryName)
    // firebaseref.child("CODPrice").set(data.codPrice)
    // firebaseref.child("CreditPrice").set(data.creditPrice)
    // firebaseref.child("SizePushId").set(data.size)
    // firebaseref.child("Size").set(data.sizeName)
    // firebaseref.child("Stock").set(parseFloat(data.stock))
    firebaseref.child("Image").set(data.productImage)
    firebaseref.child("PushId").set(firebaseref.getKey())
    firebaseref.child("Status").set("Active")


    for (let i = 0; i < myTab.rows.length; i++) {
      var objCells = myTab.rows.item(i).cells;
          var firebaseref1 = firebaseref.child("Sizes").push();
          firebaseref1.child("PushId").set(firebaseref1.getKey());
          firebaseref1.child("SizePushId").set(objCells.item(1).innerHTML)
          firebaseref1.child("Size").set(objCells.item(0).innerHTML)
          firebaseref1.child("MRPPrice").set(objCells.item(2).innerHTML);
          firebaseref1.child("CODPrice").set(objCells.item(3).innerHTML);
          firebaseref1.child("CreditPrice").set(objCells.item(4).innerHTML);
          firebaseref1.child("Stock").set(parseInt(objCells.item(4).innerHTML));
          firebaseref1.child("Status").set("Active");
    }



    comp.setState({
      success_msg: true
    })

    var contacts = [...this.state.weights];
    contacts.splice(1, this.state.weights.length);
    comp.setState({
      weights: contacts
    });


    data.productName = ""
    data.category = "Select"
    data.codPrice = ""
    data.creditPrice = ""
    data.size = "Select"
    data.stock = ""
    data.productImage = ""
    document.getElementById("create-form").reset()
}

categoryOnChange(e) {
  this.setState({
      [e.target.name]: e.target.value
  })

  firebase.database().ref().child("Masters").child("Category").child(e.target.value).child("CategoryName")
  .once("value",(snapshot) => {
      this.setState({
        categoryName:snapshot.val()
    })
  })
}

sizeOnChange(e) {
  this.setState({
      [e.target.name]: e.target.value
  })

  firebase.database().ref().child("Masters").child("Size").child(e.target.value).child("SizeName")
  .once("value",(snapshot) => {
      this.setState({
        sizeName:snapshot.val()
    })
  })
}

productUpload = e =>{
        
  if(e.target.files[0] === 0){
     alert("Add Product Image");
     return;
 }

 const ref = firebase.storage().ref("/Products/");
 const file = e.target.files[0];
 const name = e.target.files[0] + Date();
 const metadata = {
 contentType: file.type
 };
 const task = ref.child(name).put(file, metadata);
 task
 .then(snapshot => snapshot.ref.getDownloadURL())
 .then((url) => {
   this.setState({
     productImage: url
   })
 })
 .catch(console.error);         
}

editRow(key) {
  let data = this.state
  document.getElementById("productRead").style.display = "none"
  document.getElementById("productUpdate").style.display = "block"

  data.productName = key.Name
  data.pushId = key.PushId
  data.category = key.CategoryPushId
  data.productImage = key.Image
  data.categoryName = key.Category

  document.getElementById("productName").value = key.Name
  document.getElementById("category").value = key.CategoryPushId

    // document.getElementById("sizeRead").style.display = "none"
    // document.getElementById("sizeUpdate").style.display = "block"

    // this.setState({
    //     weights: [{}]
    // })


  
    const ref1 = firebase.database().ref().child("Products").child(key.PushId).child("Sizes");
      ref1.on('value',(snapshot) => {
          let newState1 = [];
          snapshot.forEach(childSnapShot => {
                  const items = {
                      CODPrice:childSnapShot.val().CODPrice,
                      CreditPrice: childSnapShot.val().CreditPrice,
                      MRPPrice: childSnapShot.val().MRPPrice,
                      PushId: childSnapShot.val().PushId,
                      Status: childSnapShot.val().Status,
                      Size: childSnapShot.val().Size,
                      SizePushId: childSnapShot.val().SizePushId,
                      Stock: childSnapShot.val().Stock
                  };
                  newState1.push(items)
          });
    
          this.setState({
            weights: newState1
          });
      });
  }

delete(key) {
  if (window.confirm('Are you sure you want to delete the product?')) {
      firebase.database().ref().child('Products').child(key.PushId).remove()
      alert("Successfully Deleted!")
    } else {}
}

updateRow(e) {
  e.preventDefault();


  let data = this.state

  let comp = this

  if(data.productName === '') {
    alert("Enter Product Name");
    document.getElementById("productName").focus();
    return;
  }

  if(data.category === 'Select') {
      alert("Select Category");
      document.getElementById("category").focus();
      return;
  }

  // if(data.productImage === '') {
  //   alert("Select Product Image");
  //   document.getElementById("productImage").focus();
  //   return;
  // }

  var myTab = document.getElementById('weightTable');
  if(myTab.rows.length === 0){
      alert("Add Size and Price");
      return;
  }

  var firebaseref = firebase.database().ref().child("Products").child(data.pushId)
  firebaseref.child("Name").set(data.productName)
  firebaseref.child("CategoryPushId").set(data.category)
  firebaseref.child("Category").set(data.categoryName)
  firebaseref.child("Image").set(data.productImage)

  firebaseref.child("Sizes").remove()

  for (let i = 0; i < myTab.rows.length; i++) {
    var objCells = myTab.rows.item(i).cells;

    
    if(objCells.item(0).innerHTML === '') {
      var firebaseref1 = firebaseref.child("Sizes").push();
      firebaseref1.child("PushId").set(firebaseref1.getKey());
      firebaseref1.child("SizePushId").set(objCells.item(1).innerHTML)
      firebaseref1.child("Size").set(objCells.item(2).innerHTML)
      firebaseref1.child("MRPPrice").set(objCells.item(3).innerHTML);
      firebaseref1.child("CODPrice").set(objCells.item(4).innerHTML);
      firebaseref1.child("CreditPrice").set(objCells.item(5).innerHTML);
      firebaseref1.child("Stock").set(parseInt(objCells.item(6).innerHTML));
      firebaseref1.child("Status").set("Active");
    } else {
      var firebaseref2 = firebaseref.child("Sizes").child(objCells.item(0).innerHTML);
      firebaseref2.child("SizePushId").set(objCells.item(1).innerHTML)
      firebaseref2.child("Size").set(objCells.item(2).innerHTML)
      firebaseref2.child("MRPPrice").set(objCells.item(3).innerHTML);
      firebaseref2.child("CODPrice").set(objCells.item(4).innerHTML);
      firebaseref2.child("CreditPrice").set(objCells.item(5).innerHTML);
      firebaseref2.child("Stock").set(parseInt(objCells.item(6).innerHTML));
      firebaseref2.child("Status").set("Active");
    }
  }

  alert("Updated Successfully!");

//   comp.setState({
//     update_msg: true
//   })

//   data.productName = ""
//   data.category = "Select"
//   data.codPrice = ""
//   data.creditPrice = ""
//   data.size = "Select"
//   data.stock = ""
//   data.productImage = ""
  document.getElementById("create-form").reset()

  var contacts = data.weights
  contacts.splice(1, data.weights.length);
  this.setState({
    weights: contacts,
  });


  document.getElementById("productRead").style.display = "block"
  document.getElementById("productUpdate").style.display = "none"
}

addAddons() {
  let data = this.state;

  if(data.size === 'Select') {
    alert("Select Size");
    document.getElementById("size").focus();
    return;
  }

  if(data.mrpPrice === '') {
    alert("Enter MRP Price");
    document.getElementById("mrpPrice").focus();
    return;
  }

  if(data.codPrice === '') {
    alert("Enter COD Price");
    document.getElementById("codPrice").focus();
    return;
  }

  if(data.creditPrice === '') {
    alert("Enter Credit Price");
    document.getElementById("creditPrice").focus();
    return;
  }

  if(data.stock === '') {
    alert("Enter Stock");
    document.getElementById("stock").focus();
    return;
  }

  const weightUnits = {
    Size: data.sizeName,
    SizePushId:  data.size,
    MRPPrice: data.mrpPrice,
    CODPrice: data.codPrice,
    CreditPrice: data.creditPrice,
    Stock: data.stock
  }

    this.setState({
        weights: [...this.state.weights, weightUnits],
      });

    data.size = "Select"
    data.mrpPrice = ""
    data.codPrice = ""
    data.creditPrice = ""
    data.stock = ""

    document.getElementById("size").value = "Select";
    document.getElementById("mrpPrice").value = "";
    document.getElementById("codPrice").value = "";
    document.getElementById("creditPrice").value = "";
    document.getElementById("stock").value = "";
  }

  deleteRow(e){
    var contacts = this.state.weights;
    contacts.splice(e, 1);
    this.setState({
      weights: contacts
    });
  }

  editWeights(key, index) {

    let data = this.state
  
    document.getElementById("add").style.display = "none"
    document.getElementById("updateWeight").style.display = "block"

  
    data.size = key.SizePushId
    data.sizeName = key.Size
    data.mrpPrice = key.MRPPrice
    data.codPrice = key.CODPrice
    data.creditPrice = key.CreditPrice
    data.stock = key.Stock
  
    document.getElementById("size").value = key.SizePushId;
    document.getElementById("mrpPrice").value = key.MRPPrice;
    document.getElementById("codPrice").value = key.CODPrice;
    document.getElementById("creditPrice").value = key.CreditPrice;
    document.getElementById("stock").value = key.Stock;
  
    this.setState({
      index: index
    })
  }

  updateAddons() {
    let data = this.state;

  
    if(data.size === 'Select') {
      alert("Select Size");
      document.getElementById("size").focus();
      return;
    }
  
    if(data.mrpPrice === '') {
      alert("Enter MRP Price");
      document.getElementById("mrpPrice").focus();
      return;
    }
  
    if(data.codPrice === '') {
      alert("Enter COD Price");
      document.getElementById("codPrice").focus();
      return;
    }
  
    if(data.creditPrice === '') {
      alert("Enter Credit Price");
      document.getElementById("creditPrice").focus();
      return;
    }
  
    if(data.stock === '') {
      alert("Enter Stock");
      document.getElementById("stock").focus();
      return;
    }

  


    var row = document.getElementById('weightTable').rows[parseInt(data.index)].cells;
    row[parseInt(1)].innerHTML= data.size;
    row[parseInt(2)].innerHTML= data.sizeName;
    row[parseInt(3)].innerHTML= data.mrpPrice;
    row[parseInt(4)].innerHTML= data.codPrice;
    row[parseInt(5)].innerHTML= data.creditPrice;
    row[parseInt(6)].innerHTML= data.stock;
  
    data.size = "Select"
    data.mrpPrice = ""
    data.codPrice = ""
    data.creditPrice = ""
    data.stock = ""

    document.getElementById("size").value = "Select";
    document.getElementById("mrpPrice").value = "";
    document.getElementById("codPrice").value = "";
    document.getElementById("creditPrice").value = "";
    document.getElementById("stock").value = "";

      document.getElementById("add").style.display = "block"
      document.getElementById("updateWeight").style.display = "none"
    }

    back(){
        document.getElementById("productRead").style.display = "block";
        document.getElementById("productUpdate").style.display = "none";
    }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Masters" breadcrumbItem="Products List" />
            <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                    <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div className="table-responsive" data-pattern="priority-columns" id = "productRead">
                                            <Table className="table mb-0 table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                            <th>Sl.No</th>
                                                            <th>Product Image</th>
                                                            <th>Product Name</th>
                                                            <th>Category</th>
                                                            <th>Details</th>
                                                            <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.firebaseData.map((rowData, index) => (
                                                    <tr key = {index}>
                                                        <td>{index + 1}</td>
                                                        <td><img src= {rowData.Image} alt = "category" className="img-responsive inline-block" width="100" height="100" /></td>
                                                        <td>{rowData.Name}</td>
                                                        <td>{rowData.Category}</td>
                                                        <td onClick = {this.editRow.bind(this, rowData)}><i style = {{color : "#343a40", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-edit"></i></td>
                                                        <td onClick = {this.delete.bind(this, rowData)}><i style = {{color : "red", fontSize: "15px", padding: "10px", cursor: "pointer"}} className="fas fa-trash"></i></td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>

                                        <div id = "productUpdate" style = {{display: "none"}}>
                                        <Form id = "create-form">
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Product Name<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="productName"
                                                          placeholder="Product Name"
                                                          type="text"
                                                          className="form-control"
                                                          id="productName"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 4, offset:2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Category<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "category" name = "category" onChange = {this.categoryOnChange.bind(this)}>
                                                            <option value = "Select">Select Category</option>
                                                            {this.state.categories.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.PushId}>{city.CategoryName}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 4}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Product Image<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="productImage"
                                                          placeholder="GST Number"
                                                          type="file"
                                                          className="form-control"
                                                          id="productImage"
                                                          onChange = {this.productUpload}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                </Row>
                                                <br />

                                                <div id = "sizeRead">

                                                  <Row>
                                                  <Col md = "2">
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Size<span style = {{color: "red"}}>*</span></Label>
                                                        <Input type="select" id = "size" name = "size" onChange = {this.sizeOnChange.bind(this)}>
                                                            <option value = "Select">Select Size</option>
                                                            {this.state.sizes.map((city, index) => {
                                                                return (
                                                                    <option key = {index} value={city.PushId}>{city.SizeName}</option>
                                                                );
                                                            })};
                                                        </Input>
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">MRP Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="mrpPrice"
                                                          placeholder="MRP Price"
                                                          type="number"
                                                          className="form-control"
                                                          id="mrpPrice"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                <FormGroup>
                                                        <Label htmlFor="validationCustom03">COD Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="codPrice"
                                                          placeholder="COD Price"
                                                          type="number"
                                                          className="form-control"
                                                          id="codPrice"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Credit Price<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="creditPrice"
                                                          placeholder="Credit Price"
                                                          type="text"
                                                          className="form-control"
                                                          id="creditPrice"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col md = {{size: 2}}>
                                                    <FormGroup>
                                                        <Label htmlFor="validationCustom03">Stock<span style = {{color: "red"}}>*</span></Label>
                                                        <Input
                                                          name="stock"
                                                          placeholder="Stock"
                                                          type="number"
                                                          className="form-control"
                                                          id="stock"
                                                          onChange = {this.handleChange.bind(this)}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={2}  id = "add" style = {{margin: "auto"}}>
                                                  <Button color="primary"  style = {{margin: "auto"}} onClick = {this.addAddons.bind(this)}>Add                                                   
                                                  </Button>
                                                </Col>

                                                <Col sm={2}  id = "updateWeight" style = {{margin: "auto", display: "none"}}>
                                                  <Button color="primary" onClick = {this.updateAddons.bind(this)}>Update                                                   
                                                  </Button>
                                                </Col>
                                                </Row>

                                                <Row>

                                                <Col  sm={6}>
                                                    <Table hover>
                                                  <thead>
                                                    <tr>
                                                       <th style = {{display: "none"}}>PushId</th>
                                                      <th style = {{display: "none"}}>SizePushId</th>
                                                      <th>Size</th>
                                                      <th>MRP Price</th>
                                                      <th>COD Price</th>
                                                      <th>Credit Price</th>
                                                      <th>Stock</th>
                                                      <th>Edit</th>
                                                      <th>Delete</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody id = "weightTable">
                                                    {this.state.weights.map((rowData, index) => (
                                                    <tr key = {index}>
                                                      <td style = {{display: "none"}}>{rowData.PushId}</td>
                                                      <td style = {{display: "none"}}>{rowData.SizePushId}</td>
                                                      <td>{rowData.Size}</td>
                                                      <td>{rowData.MRPPrice}</td>
                                                      <td>{rowData.CODPrice}</td>
                                                      <td>{rowData.CreditPrice}</td>
                                                      <td>{rowData.Stock}</td>
                                                      <td onClick = {this.editWeights.bind(this,rowData, index)}><FontAwesomeIcon style = {{color :"Blue", fontSize: "15px", cursor: "pointer"}} icon={faInfo}/></td>
                                                      <td className = "delete-row" onClick = {this.deleteRow.bind(this,index)}><FontAwesomeIcon style = {{color :"Red", fontSize: "15px", cursor: "pointer"}} icon={faTrash}/></td>
                                                      </tr>
                                                  ))}
                                                    </tbody>
                                                  </Table>
                                                  </Col>

                                                  
                                                  </Row>
                                                  </div>

                                             
                                                <Button color="primary" onClick = {this.updateRow.bind(this)}  id = "update">Update</Button>
                                                <Button color="secondary" onClick = {this.back.bind(this)} style = {{marginLeft: "10px"}}>Back</Button>
                                            {this.state.success_msg ? (
                                                  <SweetAlert
                                                    title="Good job!"
                                                    success
                                                    confirmBtnBsStyle="success"
                                                    onConfirm={() => this.setState({ success_msg: false }) }
                                                  >
                                                    Product Successfully Added!
                                                  </SweetAlert>
                                                ) : null}
                                            {this.state.update_msg ? (
                                                <SweetAlert
                                                  title="Great"
                                                  success
                                                  confirmBtnBsStyle="success"
                                                  onConfirm={() => this.setState({ update_msg: false })}
                                                >
                                                  Product Updated Successfully!
                                                </SweetAlert>
                                              ) : null}     
                                                 
                                          </Form>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                                
                                        </CardBody>
                                        </Card>
                                        </Col>
                                        </Row>

     

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default ProductsList;